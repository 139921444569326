<template>
    <div class="photo-grid max-h-screen overflow-y-auto container">
        <div v-for="(photoUrl, index) in photoUrls" :key="index" class="m-1 rounded">
            <div class="animate-pulse bg-gray-300 h-48 w-full" :ref="'placeholder-' + index"></div>
            <img :data-src="photoUrl" alt="Photo" class="photo w-full object-cover lazy-load">
        </div>
    </div>
</template>

<script>


export default {
    name: 'Photos',
    props: ['photoUrls'],
    data() {
        return {
            observer: null,
        };
    },
    mounted() {
        this.observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const img = entry.target;
                    img.src = img.getAttribute('data-src');
                    img.classList.remove('lazy-load');
                    this.observer.unobserve(img); // Stop observing the current target
                }
            });
        }, {
            root: null, // Observes the visibility within the viewport
            threshold: 0.1, // Callback is executed when 10% of the target is visible
        });

        this.observeImages();
    },
    beforeDestroy() {
        if (this.observer) {
            this.observer.disconnect();
        }
    },
    methods: {
        imageLoaded(index) {
            const placeholder = this.$refs['placeholder-' + index][0];
            if (placeholder) {
                placeholder.style.display = 'none';
            }
        },
        observeImages() {
            const images = this.$el.querySelectorAll('.lazy-load');
            images.forEach((img, index) => {
                // Observe the image for intersection with the viewport.
                this.observer.observe(img);

                // Listen for when the image has loaded.
                img.addEventListener('load', () => {
                    // Hide the placeholder.
                    const placeholder = this.$refs['placeholder-' + index][0];
                    if (placeholder) {
                        placeholder.style.display = 'none';
                    }
                });
                img.onload = () => img.classList.remove('lazy-load');
            });
        },
    }
};

</script>


<style scoped>
.container::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}

.container {
    -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;
    /* for Firefox */
}
</style>