import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		user: null,
		activeView: 'Perspective',
		boxes: [],
		selectedBox: null,
		annotateMode: false,
		mode: '',
		transformActive: false,
		transformMode: 'translate',
		logs: [],
		log_index: null,
		modified: false,
		cellMonitoring: null,
		modelType: 'mesh',
		cell: null,
	},
	mutations: {
		ADD_BOX(state, box) {
			state.boxes.push(box)
		},
		DELETE_BOX(state, boxId) {
			const boxIndex = state.boxes.findIndex(box => box.id === boxId);
			if (boxIndex !== -1) {
				state.boxes.splice(boxIndex, 1);
			}
		},
		SET_SELECTED_BOX(state, boxId) {
			state.selectedBox = boxId;
		},
		UPDATE_BOX(state, { id, newSize, newPosition }) {
			const boxIndex = state.boxes.findIndex(box => box.id === id);
			if (boxIndex !== -1) {
				state.boxes[boxIndex].size = newSize;
				state.boxes[boxIndex].position = newPosition;
				let EVI = newSize.height * Math.PI * ((newSize.width + newSize.length) / 4) ** 2;
				state.boxes[boxIndex].evi = EVI;
			}
		},
		UPDATE_QUADRAT_BOX(state, updatedQuadrat) {
			const index = state.boxes.findIndex(box => box.id === updatedQuadrat.id);
			if (index !== -1) {
				state.boxes.splice(index, 1, updatedQuadrat);
				state.modified = true;
			}
		},
		UPDATE_METRICS(state, { metrics }) {
			const boxIndex = state.boxes.findIndex(box => box.id === state.selectedBox);
			if (boxIndex !== -1) {
				state.boxes[boxIndex].metrics = { ...metrics };
				state.boxes.splice(boxIndex, 1, { ...state.boxes[boxIndex] });
			}
		},
		SET_ACTIVE_VIEW(state, view) {
			state.activeView = view;
		},
		TOGGLE_MODE(state, mode) {
			if (mode == state.mode) {
				state.mode = '';
			} else {
				state.mode = mode;
			}
		},
		SET_USER(state, user) {
			state.user = user;
		},
		SET_MODEL_TRANSFORM(state, { translation, rotation, scale }) {
			state.logs[state.log_index].modelTransform = {
				translation: translation,
				rotation: rotation,
				scale: scale
			}
			state.modified = true;
			// clear rulers as they are no longer relevent
			state.logs[state.log_index].rulers = [];
		},
		RESET_MODEL(state) {
			state.boxes = [];
			state.selectedBox = null;
			state.model = null;
			state.modified = false;
			state.transformActive = false;
			state.transformMode = 'translate';
			state.mode = '';
			state.cropBox = null;
			state.cellMonitoring = null;
		},
		SET_TRANSFORM_MODE(state, mode) {
			state.transformMode = mode;
		},
		MODEL_SELECTED(state, payload) {
			state.log_index = payload.index;
			state.modelType = payload.modelType;
		},
		SET_MODIFIED(state, modified) {
			state.modified = modified;
		},
		SET_LOGS(state, logs) {
			state.logs = logs.sort((a, b) => a.timestamp.seconds - b.timestamp.seconds);
		},
		SET_SURVEYS(state, surveys) {
			state.surveys = surveys.sort((a, b) => a.timestamp.seconds - b.timestamp.seconds);
		},
		SET_CELL(state, payload) {
			state.cell = payload;
		},
		UPDATE_ANNOTATIONS(state) {
			state.logs[state.log_index].annotations = state.boxes;
		},
		CLEAR_ANNOTATIONS(state) {
			state.boxes = [];
		},
		TOGGLE_MODEL_ANNOTATED(state) {
			state.logs[state.log_index].modelAnnotated = !state.logs[state.log_index].modelAnnotated;
			state.modified = true;
		},
		SET_CROP_BOX(state, cropBox) {
			state.logs[state.log_index].cropBox = cropBox;
		},
		UPDATE_CELL_MONITORING(state, monitoring) {
			state.cellMonitoring = monitoring;
		},
		SET_MODEL_TYPE(state, modelType) {
			state.modelType = modelType;
		},
		SAVE_RULERS(state, rulers) {
			// filter out the three.js objects
			const filteredRulers = rulers.map(ruler => ({
				id: ruler.id,
				type: ruler.type,
				startPoint: ruler.startPoint.toArray(),
				endPoint: ruler.endPoint.toArray(),
				distance: ruler.distance,
				knownDistance: ruler.knownDistance
			}));

			if (!state.logs[state.log_index].rulers) {
				state.logs[state.log_index].rulers = [];
			}
			state.logs[state.log_index].rulers = filteredRulers;
			state.modified = true;
		}
	},
	actions: {
		addBox({ commit }, box) {
			commit('ADD_BOX', box)
		},
		deleteBox({ commit }, boxId) {
			commit('DELETE_BOX', boxId)
		},
		selectBox({ commit }, boxId) {
			commit('SET_SELECTED_BOX', boxId);
		},
		updateBox({ commit }, { id, newSize, newPosition }) {
			commit('UPDATE_BOX', { id, newSize, newPosition });
		},
		updateQuadratBox({ commit }, updatedQuadrat) {
			commit('UPDATE_QUADRAT_BOX', updatedQuadrat);
		},
		updateMetrics({ commit }, { metrics }) {
			commit('UPDATE_METRICS', { metrics });
		},
		setActiveView({ commit, state }, view) {
			commit('SET_ACTIVE_VIEW', view);
		},
		toggleMode({ commit }, mode) {
			commit('TOGGLE_MODE', mode);
		},
		setUser({ commit }, user) {
			commit('SET_USER', user);
		},
		setModelTransform({ commit }, { translation, rotation, scale }) {
			commit('SET_MODEL_TRANSFORM', { translation, rotation, scale });
		},
		resetModel({ commit }) {
			commit('RESET_MODEL')
		},
		setTransformMode({ commit }, mode) {
			commit('SET_TRANSFORM_MODE', mode);
		},
		modelSelected({ commit }, payload) {
			commit('MODEL_SELECTED', payload);
		},
		setModified({ commit }, modified) {
			commit('SET_MODIFIED', modified);
		},
		updateCellMonitoring({ commit }, monitoring) {
			commit('UPDATE_CELL_MONITORING', monitoring);
		},
		setLogs({ commit }, logs) {
			commit('SET_LOGS', logs);
		},
		setSurveys({ commit }, surveys) {
			commit('SET_SURVEYS', surveys);
		},
		setCell({ commit }, payload) {
			commit('SET_CELL', payload);
		},
		updateAnnotations({ commit }) {
			commit('UPDATE_ANNOTATIONS');
		},
		clearAnnotations({ commit }) {
			commit('CLEAR_ANNOTATIONS');
		},
		toggleModelAnnotated({ commit }) {
			commit('TOGGLE_MODEL_ANNOTATED');
		},
		setCropBox({ commit }, cropBox) {
			commit('SET_CROP_BOX', cropBox);
		},
		updateCellMonitoring({ commit }, monitoring) {
			commit('UPDATE_CELL_MONITORING', monitoring);
		},
		setModelType({ commit }, modelType) {
			commit('SET_MODEL_TYPE', modelType);
		},
		saveRulers({ commit }, rulers) {
			commit('SAVE_RULERS', rulers);
		}
	},
})
