<template>
    <div class="relative inline-block text-left">
        <div @click="toggleDropdown"
            class="cursor-pointer bg-neutral-700 hover:bg-neutral-500 py-1 px-4 rounded flex items-center transition-colors">
            <span class="mdi mdi-download text-lg text-white"></span>
        </div>
        <div v-if="isOpen"
            class="z-50 origin-top-left absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-neutral-800 ring-1 ring-black ring-opacity-5 max-h-400px overflow-y-auto">
            <div class="py-1" role="menu" aria-orientation="vertical">
                <div v-for="item in options" :key="item.label" @click="selectItem(item.value)"
                    class="block px-2 py-2 text-sm text-white hover:bg-neutral-700 cursor-pointer flex items-center">
                    <span :class="item.icon" class="text-lg mr-2"></span>
                    {{ item.label }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        options: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            isOpen: false,
            selected: ''
        };
    },
    methods: {
        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },
        selectItem(value) {
            this.selected = value;
            this.$emit('select', value); // Emit select event for custom handling
            this.isOpen = false;
        }
    }
};
</script>

<style scoped>
.bg-neutral-800 {
    background-color: #2d2d2d;
}

.hover\:bg-neutral-700:hover {
    background-color: #3d3d3d;
}

.max-h-400px {
    max-height: 400px;
}
</style>
