<template>
    <div class="h-full flex items-center" :class="{'pt-10': compareMode}">
        <div class="flex ml-2 mb-3 mr-2">
            <button 
                @click="goToStart" :disabled="$store.state.log_index == 0"
                class="relative flex justify-center items-center rounded m-1 transition-colors button-square"
                :class="{
                        'bg-neutral-800': $store.state.log_index == 0,
                        'bg-neutral-700 hover:bg-neutral-500': $store.state.log_index !== 0
                    }"
                >
                <span :class="{'text-white': $store.state.log_index != 0, 'text-gray-600': $store.state.log_index == 0}" class="mdi mdi-step-backward-2 text-lg"></span>
            </button>
            <button 
                @click="goBackward" :disabled="$store.state.log_index == 0"
                class="relative flex justify-center items-center rounded m-1 transition-colors button-square"
                :class="{
                    'bg-neutral-800': $store.state.log_index == 0,
                    'bg-neutral-700 hover:bg-neutral-500': $store.state.log_index != 0
                    }"
                >
                <span :class="{ 'text-white': $store.state.log_index != 0, 'text-gray-600': $store.state.log_index == 0 }" class="mdi mdi-step-backward text-lg"></span>
            </button>
            <button 
                @click="goForward" :disabled="$store.state.log_index == logs.length - 1"
                class="relative flex justify-center items-center rounded m-1 transition-colors button-square"
                :class="{
                    'bg-neutral-800': $store.state.log_index == logs.length - 1,
                    'bg-neutral-700 hover:bg-neutral-500': $store.state.log_index != logs.length - 1
                    }"
                >
                <span :class="{ 'text-white': $store.state.log_index != logs.length - 1, 'text-gray-600': $store.state.log_index == logs.length - 1 }" class="mdi mdi-step-forward text-lg"></span>
            </button>
            <button 
                @click="goToEnd" :disabled="$store.state.log_index == logs.length - 1"
                class="relative flex justify-center items-center rounded m-1 transition-colors button-square"
                :class="{
                    'bg-neutral-800': $store.state.log_index == logs.length - 1,
                    'bg-neutral-700 hover:bg-neutral-500': $store.state.log_index != logs.length - 1
                    }"
                >
                <span :class="{ 'text-white': $store.state.log_index != logs.length - 1, 'text-gray-600': $store.state.log_index == logs.length - 1 }"  class="mdi mdi-step-forward-2 text-lg"></span>
            </button>
        </div>

        <!-- Timeline Container -->
        <div class="flex-grow relative mx-4 mb-3" :class="{ 'pt-10': compareMode }">
            <div class="absolute top-1/2 transform -translate-y-1/2 w-full h-2 bg-neutral-800"></div>

            <!-- Circles -->
            <div v-for="(log, index) in normalizedLogs" :key="index" class="absolute" :style="{ left: `calc(${log.position * 100}% - 8px)`, top: '50%', transform: 'translateY(-50%)' }">
                <div @click="selectLog(index)"
                    :class="[
                        'rounded-full w-2 h-2 bg-white z-20 hover:cursor-pointer relative',
                        {'active-circle': index == $store.state.log_index }
                    ]"
                ></div>

                <!-- Date Tag -->
                <div :class="['tag', { 'first-log': index == 0, 'last-log': index == normalizedLogs.length - 1 }]" :style="{ left: getDatePosition(index) }">
                    {{ formatDate(log.timestamp) }}
                </div>

            </div>

            <!-- Compare Circles -->
            <div v-if="compareMode" v-for="(log, index) in normalizedLogs" :key="`compare-${index}`" class="absolute" :style="{ left: `calc(${log.position * 100}% - 12px)`, top: 'calc(50% - 30px)', transform: 'translateY(-50%)' }">
                <div 
                    v-if="index == loadingModelIndex"
                    class="spinner">
                </div>
                <div 
                    v-else-if="secondLogIndex == null || index == $store.state.log_index || index == secondLogIndex"
                    @click="selectForCompare(index)" 
                    :class="[
                        'rounded-full w-4 h-4 cursor-pointer',
                        { 'bg-primary border border-primary': index == $store.state.log_index || index == secondLogIndex,
                        'border border-neutral-600': index != $store.state.log_index && index != secondLogIndex }
                    ]">
                </div>
            </div>

            <!-- Compare Slider -->
            <div v-if="showSlider && secondLogIndex !== null" class="absolute" :style="{ bottom: '43px', ...sliderPositionAndWidth }">
                <input type="range" v-model="sliderValue" @input="compareModels" min="0" max="100" class="range-slider">
            </div>

        </div>

        <!-- <div class="flex mb-3 mr-2">
            <button 
                @click="toggleCompareMode" 
                class="relative flex bg-neutral-700 hover:bg-neutral-500 justify-center items-center rounded m-1 transition-colors button-square">
                <span class="mdi mdi-select-compare text-white text-lg"></span>
            </button>
        </div> -->

    </div>
</template>

<script>
import { EventBus } from '@/event-bus.js';
import { loadObjModel } from '@/assets/js/loaders';
import RightSidebar from './RightSidebar.vue';

export default {
    name: 'Timeline',
    props: ['scene', 'bucket'],
    data() {
        return {
            compareMode: false,
            secondLogIndex: null,
            loadingModelIndex: null,
            firstModel: null,
            secondModel: null,
            showSlider: false,
            sliderValue: 0,
            sliderInitValue: 0,
            isLoading: false,
            loadingProgress: 0,
            }
    },
    mounted() {
        EventBus.$on('reset-model', this.reset);
    },
    beforeDestroy() {
        EventBus.$off('reset-model', this.reset);
    },
    computed: {
        logs() {
            return this.$store.state.logs;
        },
        normalizedLogs() {
            // First, make sure there are at least two logs to compare.
            if (this.logs.length <= 1) {
                return this.logs.map(log => ({ ...log, position: 1 })); // Only one log, position it at the end.
            }

            // Get the first and last timestamp in seconds.
            const firstTimestampSeconds = this.logs[0].timestamp.seconds;
            const lastTimestampSeconds = this.logs[this.logs.length - 1].timestamp.seconds;
            const totalRangeSeconds = lastTimestampSeconds - firstTimestampSeconds;

            return this.logs.map(log => {
                // Calculate the position based on the difference in time from the first log.
                const position = (log.timestamp.seconds - firstTimestampSeconds) / totalRangeSeconds;
                return {
                    ...log,
                    position: position
                };
            });
        },
        currentIndex() {
            return this.$store.state.log_index;
        },
        sliderPositionAndWidth() {
            if (this.secondLogIndex == null) return {};

            const firstLogPosition = this.normalizedLogs[this.$store.state.log_index]?.position || 0;
            const secondLogPosition = this.normalizedLogs[this.secondLogIndex]?.position || 0;

            // Calculate left and right positions as percentages
            const startPercentage = Math.min(firstLogPosition, secondLogPosition) * 100;
            const endPercentage = Math.max(firstLogPosition, secondLogPosition) * 100;

            // Calculate width as the difference between start and end positions
            const widthPercentage = endPercentage - startPercentage;

            return {
                left: `calc(${startPercentage}% - 12px)`,
                width: `calc(${widthPercentage}% + 16px)`
            };
        },
    },
    methods: {
        reset() {
            if (this.compareMode) {
                this.toggleCompareMode();
            }
            if (this.firstModel) {
                this.firstModel.material.opacity = 1;
                this.firstModel.material.transparent = false;
                this.firstModel.material.depthWrite = true;
                this.firstModel.material.needsUpdate = true;
                this.firstModel = null;
            }
            if (this.secondModel) {
                this.scene.remove(this.secondModel);
                if (this.secondModel.geometry) {
                    this.secondModel.geometry.dispose();
                }
                if (this.secondModel.material) {
                    if (Array.isArray(this.secondModel.material)) {
                        this.secondModel.material.forEach(material => material.dispose());
                    } else {
                        this.secondModel.material.dispose();
                    }
                }
                this.secondModel = null;
            }
            this.secondLogIndex = null;
            this.showSlider = false;
            this.sliderValue = 0;
            this.sliderInitValue = 0;
            
        },
        compareModels() {
            if (this.sliderInitValue == 0) {
                this.firstModel.material.opacity = 1 - this.sliderValue / 100;
                this.secondModel.material.opacity = this.sliderValue / 100;
            } else {
                this.firstModel.material.opacity = this.sliderValue / 100;
                this.secondModel.material.opacity = 1 - this.sliderValue / 100;
            }
            this.firstModel.material.needsUpdate = true;
            this.secondModel.material.needsUpdate = true;
        },
        toggleCompareMode() {
            this.compareMode = !this.compareMode;
            this.$store.dispatch('toggleCompareMode');
            if (!this.compareMode) {
                this.reset();
            }
        },
        selectForCompare(index) {
            if (!this.firstModel) {
                this.firstModel = this.scene.getObjectByName('model');
                this.firstModel.material.transparent = true;
                this.firstModel.material.opacity = 1;
                this.firstModel.material.needsUpdate = true;
                this.firstModel.material.depthWrite = false;   
            }
            if (this.secondLogIndex == null && index != this.$store.state.log_index) {
                this.loadSecondModel(index);
                this.secondLogIndex = index;
                this.sliderValue = this.$store.state.log_index < this.secondLogIndex ? 0 : 100;
                this.sliderInitValue = this.sliderValue;

            }
        },
        loadSecondModel(index) {
            const url = this.bucket + this.logs[index].photogrammetryPath;
            const model_url = url + '/model_medium.obj';
            const texture_url = url + '/model_medium.jpg';
            this.isLoading = true;
            this.loadingModelIndex = index;
            loadObjModel(model_url, texture_url, (xhr, isTexture) => {
                if (!isTexture) {
                    this.loadingProgress = (xhr.loaded / xhr.total * 100).toFixed(0);
                }
            }).then(({ object, metadata }) => {
                object.userData.type = 'model';
                object.userData.format = 'obj';
                object.userData.metadata = metadata;
                object.name = 'second_model';
                object.material.transparent = true;
                object.material.opacity = 0;
                object.material.depthWrite = false;
                object.material.needsUpdate = true;
                this.scene.add(object);
                this.secondModel = object;
                this.isLoading = false;
                this.loadingProgress = 0;
                this.loadingModelIndex = null;
                this.showSlider = true;
            },
                (xhr) => {
                    this.loadingProgress = (xhr.loaded / xhr.total * 100).toFixed(0);
                },
            ).catch(error => {
                console.error('An error ocurred while loading obj model:', error)
                this.isLoading = false;
                this.loadingModelIndex = null;
            })
        },
        isLogSelectedForCompare(index) {
            return index == this.$store.state.log_index || index == this.secondLogIndex;
        },
        selectLog(index) {
            EventBus.$emit('reset-model');
            this.$store.dispatch('modelSelected', { index: index, modelType: 'mesh' }); // update the model in the store
            EventBus.$emit('model-selected'); // load model in the viewer
        },
        goToEnd() {
            const index = this.logs.length - 1;
            EventBus.$emit('reset-model');
            this.$store.dispatch('modelSelected', { index: index, modelType: 'mesh' }); 
            EventBus.$emit('model-selected'); 
        },
        goToStart() {
            const index = 0;
            EventBus.$emit('reset-model');
            this.$store.dispatch('modelSelected', { index: index, modelType: 'mesh' });
            EventBus.$emit('model-selected');
        },
        goForward() {
            const index = this.currentIndex + 1;
            EventBus.$emit('reset-model');
            this.$store.dispatch('modelSelected', { index: index, modelType: 'mesh' });
            EventBus.$emit('model-selected');
        },
        goBackward() {
            const index = this.currentIndex - 1;
            EventBus.$emit('reset-model');
            this.$store.dispatch('modelSelected', { index: index, modelType: 'mesh' });
            EventBus.$emit('model-selected');
        },
        formatDate(timestamp) {
            const date = new Date(timestamp.seconds * 1000);
            return date.toLocaleDateString(); 
        },
        getDatePosition(index) {
            if (index == 0) {
                return '0%';
            } else if (index == this.normalizedLogs.length - 1) {
                return '100%'; 
            } else {
                return '50%'; 
            }
        },
    }
}

</script>

<style scoped>
.active-circle {
    box-shadow: 0 0 0 0.25rem #27bdf4; 
    z-index: 20;
}
.button-square {
  width: 1.5rem; 
  height: 1.5rem;
}


.tag {
    position: absolute;
    bottom: -1.6rem; 
    transform: translateX(-50%);
    color: white; 
    font-size: .75rem;
    white-space: nowrap;
    /* padding-bottom: 0.25rem; */
    border: 2px solid rgb(38,38,38);
    background-color: rgb(38,38,38);
    border-radius: 0.4rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    z-index: 10;
}

.first-log {
    transform: translateX(0%);
}

.last-log {
    transform: translateX(-100%);
}

.range-slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 2px; /* Slider Track height */
    background: #27BDF4; /* Slider Track Color */
    outline: none;
}

.range-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px; /* Thumb width */
    height: 15px; /* Thumb height */
    border-radius: 50%;
    background: white; /* Thumb Color */
    border: 4px solid #27BDF4; /* Thumb Border */
    cursor: pointer;
}

.range-slider::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #27BDF4;
    cursor: pointer;
}
.spinner {
  width: 15px;
  height: 15px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #27bdf4;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>