<template>
    <div class="relative inline-block text-left w-full">
        <div @click="toggleDropdown"
            class="cursor-pointer p-2 bg-black text-white rounded flex items-center justify-between">
            <span>{{ selectedOption ? formatTime(selectedOption.timestamp.toDate()) : placeholder }}</span>
        </div>
        <div v-if="isOpen"
            class="z-50 origin-top-left absolute left-0 mt-2 w-full rounded-md shadow-lg bg-neutral-800 ring-1 ring-black ring-opacity-5 w-full min-w-max"
            style="max-height: 400px; overflow-y: auto;">
            <div class="py-1" role="menu" aria-orientation="vertical">
                <div v-for="(entry, idx) in options" :key="idx" @click="selectItem(entry.index)"
                    class="block px-4 py-2 text-sm text-white hover:bg-neutral-700 cursor-pointer">
                    {{ formatTime(entry.timestamp.toDate()) }}
                    <span v-if="entry.modelAnnotated">
                        <i class="mdi mdi-check-circle-outline text-green-500 px-1"></i>
                    </span>
                    <span v-else>
                        <i class="mdi mdi-dots-circle px-1"></i>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Number,
            default: null,
        },
        options: {
            type: Array,
            default: () => [],
        },
        placeholder: {
            type: String,
            default: 'Select an option'
        },
    },
    data() {
        return {
            isOpen: false,
            selected: null, // Initialize selected to null
        };
    },
    computed: {
        selectedOption() {
            return this.options.find(option => option.index === this.selected) || null;
        },
    },
    watch: {
        value(newValue) {
            this.selected = newValue;
        },
        options: {
            immediate: true,
            handler(newOptions) {
                // Ensure the selected index is valid when options change
                if (this.selected !== null && newOptions.length > 0 && !newOptions.find(option => option.index === this.selected)) {
                    this.selected = null;
                }
            },
        },
    },
    methods: {
        formatTime(time) {
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            return time.toLocaleDateString('en-US', options);
        },
        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },
        selectItem(index) {
            this.selected = index;
            this.$emit('input', index); // Emit the selected index
            this.isOpen = false;
        },
        handleClick(event) {
            if (!this.$el.contains(event.target)) {
                this.isOpen = false;
            }
        },
    },
    mounted() {
        this.selected = this.value; // Initialize selected with the value prop on mount
        document.addEventListener('click', this.handleClick, true);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClick, true);
    },
};
</script>
