var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-full flex items-center",class:{'pt-10': _vm.compareMode}},[_c('div',{staticClass:"flex ml-2 mb-3 mr-2"},[_c('button',{staticClass:"relative flex justify-center items-center rounded m-1 transition-colors button-square",class:{
                    'bg-neutral-800': _vm.$store.state.log_index == 0,
                    'bg-neutral-700 hover:bg-neutral-500': _vm.$store.state.log_index !== 0
                },attrs:{"disabled":_vm.$store.state.log_index == 0},on:{"click":_vm.goToStart}},[_c('span',{staticClass:"mdi mdi-step-backward-2 text-lg",class:{'text-white': _vm.$store.state.log_index != 0, 'text-gray-600': _vm.$store.state.log_index == 0}})]),_c('button',{staticClass:"relative flex justify-center items-center rounded m-1 transition-colors button-square",class:{
                'bg-neutral-800': _vm.$store.state.log_index == 0,
                'bg-neutral-700 hover:bg-neutral-500': _vm.$store.state.log_index != 0
                },attrs:{"disabled":_vm.$store.state.log_index == 0},on:{"click":_vm.goBackward}},[_c('span',{staticClass:"mdi mdi-step-backward text-lg",class:{ 'text-white': _vm.$store.state.log_index != 0, 'text-gray-600': _vm.$store.state.log_index == 0 }})]),_c('button',{staticClass:"relative flex justify-center items-center rounded m-1 transition-colors button-square",class:{
                'bg-neutral-800': _vm.$store.state.log_index == _vm.logs.length - 1,
                'bg-neutral-700 hover:bg-neutral-500': _vm.$store.state.log_index != _vm.logs.length - 1
                },attrs:{"disabled":_vm.$store.state.log_index == _vm.logs.length - 1},on:{"click":_vm.goForward}},[_c('span',{staticClass:"mdi mdi-step-forward text-lg",class:{ 'text-white': _vm.$store.state.log_index != _vm.logs.length - 1, 'text-gray-600': _vm.$store.state.log_index == _vm.logs.length - 1 }})]),_c('button',{staticClass:"relative flex justify-center items-center rounded m-1 transition-colors button-square",class:{
                'bg-neutral-800': _vm.$store.state.log_index == _vm.logs.length - 1,
                'bg-neutral-700 hover:bg-neutral-500': _vm.$store.state.log_index != _vm.logs.length - 1
                },attrs:{"disabled":_vm.$store.state.log_index == _vm.logs.length - 1},on:{"click":_vm.goToEnd}},[_c('span',{staticClass:"mdi mdi-step-forward-2 text-lg",class:{ 'text-white': _vm.$store.state.log_index != _vm.logs.length - 1, 'text-gray-600': _vm.$store.state.log_index == _vm.logs.length - 1 }})])]),_c('div',{staticClass:"flex-grow relative mx-4 mb-3",class:{ 'pt-10': _vm.compareMode }},[_c('div',{staticClass:"absolute top-1/2 transform -translate-y-1/2 w-full h-2 bg-neutral-800"}),_vm._l((_vm.normalizedLogs),function(log,index){return _c('div',{key:index,staticClass:"absolute",style:({ left: `calc(${log.position * 100}% - 8px)`, top: '50%', transform: 'translateY(-50%)' })},[_c('div',{class:[
                    'rounded-full w-2 h-2 bg-white z-20 hover:cursor-pointer relative',
                    {'active-circle': index == _vm.$store.state.log_index }
                ],on:{"click":function($event){return _vm.selectLog(index)}}}),_c('div',{class:['tag', { 'first-log': index == 0, 'last-log': index == _vm.normalizedLogs.length - 1 }],style:({ left: _vm.getDatePosition(index) })},[_vm._v(" "+_vm._s(_vm.formatDate(log.timestamp))+" ")])])}),_vm._l((_vm.normalizedLogs),function(log,index){return (_vm.compareMode)?_c('div',{key:`compare-${index}`,staticClass:"absolute",style:({ left: `calc(${log.position * 100}% - 12px)`, top: 'calc(50% - 30px)', transform: 'translateY(-50%)' })},[(index == _vm.loadingModelIndex)?_c('div',{staticClass:"spinner"}):(_vm.secondLogIndex == null || index == _vm.$store.state.log_index || index == _vm.secondLogIndex)?_c('div',{class:[
                    'rounded-full w-4 h-4 cursor-pointer',
                    { 'bg-primary border border-primary': index == _vm.$store.state.log_index || index == _vm.secondLogIndex,
                    'border border-neutral-600': index != _vm.$store.state.log_index && index != _vm.secondLogIndex }
                ],on:{"click":function($event){return _vm.selectForCompare(index)}}}):_vm._e()]):_vm._e()}),(_vm.showSlider && _vm.secondLogIndex !== null)?_c('div',{staticClass:"absolute",style:({ bottom: '43px', ..._vm.sliderPositionAndWidth })},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sliderValue),expression:"sliderValue"}],staticClass:"range-slider",attrs:{"type":"range","min":"0","max":"100"},domProps:{"value":(_vm.sliderValue)},on:{"input":_vm.compareModels,"__r":function($event){_vm.sliderValue=$event.target.value}}})]):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }