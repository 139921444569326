<template>
    <div ref="renderContainerCompare" id="compare" class="relative border border-neutral-700">

        <Popup v-if="showPopup" title="Warning"
            message="This will overwrite all existing annotations. Do you want to continue?" :visible="showPopup"
            @confirm="handleConfirm" @cancel="handleCancel" 
        />

        <!-- Loading Bar -->
        <div v-if="isLoading" class="absolute top-0 left-0 w-full z-50">
            <div class="w-full h-1 bg-neutral-700">
                <div :style="{ width: loadingProgress + '%' }"
                    class="h-full bg-primary transition-all ease-out duration-150"></div>
            </div>
        </div>
        <div class="absolute top-2 right-2 bg-neutral-700 text-white text-xs border border-neutral-700 rounded w-48">
            <DropdownCompare :value="selectedIndex" :options="availableLogs" placeholder="Select a log"
                @input="onLogSelected" />
        </div>
        <div v-if="hasAnnotations && !imported" @click="confirmImportAnnotations(selectedIndex)"
            class="absolute top-2 left-2 bg-neutral-700 text-white text-xs px-1 rounded">
            <div class="button-group relative flex justify-center items-center m-1">
                <button class="flex-1 flex justify-center items-center rounded-l bg-neutral-700 transition-colors mr-1">
                    <span class="mdi mdi-import"> Import Corals</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { EventBus } from '@/event-bus';
import { loadObjModel } from '@/assets/js/loaders';
import DropdownCompare from '@/components/utils/DropdownCompare.vue'; 
import Popup from '@/components/utils/Popup.vue';

export default {
    name: 'Compare',
    components: {
        DropdownCompare,
        Popup,
    },
    props: ['bucket'],
    data() {
        return {
            renderer: null,
            camera: null,
            controls: null,
            labelRenderer: null,
            gridHelper: null,
            axisHelper: null,
            compareScene: new THREE.Scene(),  
            resizeObserver: null,
            resizeTimeout: null,
            boxes: [],
            annotationLabels: {},
            selectedIndex: null,
            isLoading: false,
            loadingProgress: 0,
            imported: false,
            showPopup: false,
        }
    },
    mounted() {
        this.initRenderer();
        this.setUpResizeObserver();
        this.compareModelSelectedHandler = (index) => {
            this.loadSecondModel(index);
        };
        this.resetModelHandler = () => {
            if (this.$store.state.mode == 'compare') {
                this.$store.dispatch('toggleCompareMode');
            }
        };
        EventBus.$on('compare-model-selected', this.compareModelSelectedHandler);
        EventBus.$on('reset-model', this.resetModelHandler);
    },
    beforeDestroy() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
        EventBus.$off('compare-model-selected', this.compareModelSelectedHandler);
        EventBus.$off('reset-model', this.resetModelHandler);
        this.reset();
    },
    computed: {
        logs() {
            return this.$store.state.logs;
        },
        availableLogs() {
            return this.logs
                .map((log, index) => ({
                    ...log,
                    index,
                }))
                .filter(log => log.index != this.$store.state.log_index);
        },
        hasAnnotations() {
            return this.boxes.length > 0 ? true : false;
        }
    },
    methods: {
        reset() {
            this.compareScene.remove(this.compareScene.getObjectByName('compare_model'));
            this.boxes.forEach(box => this.compareScene.remove(box));
            this.boxes = [];
            this.annotationLabels = {};
        },
        confirmImportAnnotations(index) {
            this.selectedIndex = index;
            this.showPopup = true;
        },
        handleConfirm() {
            this.showPopup = false;
            this.importAnnotations(this.selectedIndex);
        },
        handleCancel() {
            this.showPopup = false;
            this.selectedIndex = null;
        },
        importAnnotations(index) {
            this.imported = true;
            EventBus.$emit('import-annotations', index);
        },
        loadSecondModel(index) {
            const url = this.bucket + this.logs[index].photogrammetryPath;
            const model_url = url + '/model_medium.obj';
            const texture_url = url + '/model_medium.jpg';
            this.isLoading = true;
            loadObjModel(model_url, texture_url, (xhr, isTexture) => {
                if (!isTexture) {
                    this.loadingProgress = (xhr.loaded / xhr.total * 100).toFixed(0);
                }
            }).then(({ object, metadata }) => {
                object.userData.type = 'model';
                object.userData.format = 'obj';
                object.userData.metadata = metadata;
                object.name = 'compare_model';
                const transform = this.logs[index].modelTransform;
                if (transform) {
                    object.position.set(transform.translation.x, transform.translation.y, transform.translation.z);
                    object.rotation.set(transform.rotation.x, transform.rotation.y, transform.rotation.z);
                    object.scale.set(transform.scale.x, transform.scale.y, transform.scale.z);
                }
                this.compareScene.add(object);
                this.loadAnnotations(index);
                this.isLoading = false;
                this.loadingProgress = 0;

            },
                (xhr) => {
                    this.loadingProgress = (xhr.loaded / xhr.total * 100).toFixed(0);
                },
            ).catch(error => {
                console.error('An error occurred while loading obj model:', error)
                this.isLoading = false;
                this.loadingModelIndex = null;
            })
        },
        loadAnnotations(index) {
            const annotations = this.logs[index].annotations;
            if (annotations) {
                annotations.forEach(annotation => {
                    this.addBox(annotation, false);
                });
            }
        },
        addBox(annotation) {
            const box = this.createBoxMesh(annotation);
            this.boxes.push(box);
            this.compareScene.add(box);
            this.addLabel(box, annotation.id);
        },
        createBoxMesh(annotation) {
            const boxGeometry = new THREE.BoxGeometry(
                annotation.size.width / 100,
                annotation.size.height / 100,
                annotation.size.length / 100
            );
            const boxMaterial = new THREE.MeshBasicMaterial({
                color: 0x27BDF4,
                transparent: true,
                opacity: 0.5,
            });
            const box = new THREE.Mesh(boxGeometry, boxMaterial);
            box.position.set(annotation.position.x, annotation.position.y, annotation.position.z);

            // Add edges to the box for visibility
            const edges = new THREE.EdgesGeometry(boxGeometry);
            const lineMaterial = new THREE.LineBasicMaterial({
                color: 0x27BDF4,
                linewidth: 7,
            });
            const lineSegments = new THREE.LineSegments(edges, lineMaterial);
            box.add(lineSegments);

            // Less transparent back face
            const zOffset = 0.001;
            const backFaceGeometry = new THREE.PlaneGeometry(annotation.size.width / 100, annotation.size.height / 100);
            const backFaceMaterial = new THREE.MeshBasicMaterial({
                color: 0x27BDF4,
                side: THREE.DoubleSide,
                transparent: true,
                opacity: 0.8 // More transparent than the box
            });
            const backFace = new THREE.Mesh(backFaceGeometry, backFaceMaterial);
            backFace.position.z = -annotation.size.length / 100 / 2 - zOffset;; // Position it at the center of the -Z face
            backFace.rotateY(Math.PI);
            box.add(backFace);

            return box;
        },
        addLabel(box, id) {
            const div = document.createElement('div');
            div.className = 'annotationLabel';
            div.innerText = `${id}`;
            const label = new CSS2DObject(div);

            // Position the label at the center of the box
            const center = new THREE.Vector3();
            box.geometry.computeBoundingBox();
            box.geometry.boundingBox.getCenter(center);
            label.position.copy(center);

            box.add(label);
            this.annotationLabels[id] = label;
        },
        onLogSelected(selectedIndex) {
            this.selectedIndex = selectedIndex;
            this.loadSecondModel(selectedIndex);
        },
        initRenderer() {
            // Initialize the camera
            const aspect = this.$refs.renderContainerCompare.clientWidth / this.$refs.renderContainerCompare.clientHeight;
            this.camera = new THREE.PerspectiveCamera(50, aspect, 0.1, 1000);
            this.camera.position.set(3, 3, 3);
            this.camera.lookAt(0, 0, 0);

            // Initialize the renderer
            this.renderer = new THREE.WebGLRenderer({ antialias: true });
            this.renderer.setSize(this.$refs.renderContainerCompare.clientWidth, this.$refs.renderContainerCompare.clientHeight);
            this.renderer.setPixelRatio(window.devicePixelRatio);
            this.renderer.autoClear = true;
            this.$refs.renderContainerCompare.appendChild(this.renderer.domElement);

            // Initialize the label renderer
            this.labelRenderer = new CSS2DRenderer();
            this.labelRenderer.setSize(this.$refs.renderContainerCompare.clientWidth, this.$refs.renderContainerCompare.clientHeight);
            this.labelRenderer.domElement.style.position = 'absolute';
            this.labelRenderer.domElement.style.top = '0px';
            this.labelRenderer.domElement.style.pointerEvents = 'none';
            this.$refs.renderContainerCompare.appendChild(this.labelRenderer.domElement);

            // Add lights to the scene
            const ambient = new THREE.AmbientLight(0xffffff, 2);
            this.compareScene.add(ambient);
            const directional = new THREE.DirectionalLight(0xffffff, 1);
            directional.position.set(10, 10, 10);
            directional.castShadow = true;
            this.compareScene.add(directional);

            // Initialize the controls
            this.controls = new OrbitControls(this.camera, this.renderer.domElement);
            this.controls.zoomSpeed = 7;
            this.controls.enableDamping = true;
            this.controls.dampingFactor = 0.25;
            // this.controls.screenSpacePanning = false;
            // this.controls.maxPolarAngle = Math.PI / 2;

            // Add grid and axis helpers
            this.gridHelper = new THREE.GridHelper(10, 10);
            this.compareScene.add(this.gridHelper);
            this.axisHelper = new THREE.AxesHelper(5);
            this.compareScene.add(this.axisHelper);

            this.animate();
        },
        render() {
            this.renderer.render(this.compareScene, this.camera);
            this.labelRenderer.render(this.compareScene, this.camera);
        },
        animate() {
            requestAnimationFrame(() => this.animate());
            this.controls.update();
            this.render();
        },
        setUpResizeObserver() {
            if ('ResizeObserver' in window) {
                this.resizeObserver = new ResizeObserver(entries => {
                    for (let entry of entries) {
                        this.onWindowResize();
                    }
                });
                this.resizeObserver.observe(this.$refs.renderContainerCompare);
            }
        },
        onWindowResize() {
            if (this.resizeTimeout) clearTimeout(this.resizeTimeout);

            this.resizeTimeout = setTimeout(() => {
                const container = this.$refs.renderContainerCompare;
                const width = container.clientWidth;
                const height = container.clientHeight;

                this.camera.aspect = width / height;
                this.camera.updateProjectionMatrix();
                this.renderer.setSize(width, height);
                this.labelRenderer.setSize(width, height);
            }, 10);
        }
    }
}
</script>

<style>
.annotationLabel {
    padding: 2px 4px;
    border-radius: 2px;
    background-color: black;
    color: white;
    font-size: 8px;
    font-family: Arial, sans-serif;
    text-align: center;
    white-space: nowrap;
}
</style>
