<template>
    <div class="right-sidebar w-72 flex-none bg-black border-l border-gray-700 flex flex-col overflow-y-auto">

        <Annotate v-if="$store.state.modelType === 'mesh'" :scene="scene" />
        <Crop v-if="$store.state.mode == 'crop'" :scene="scene" />
        <Measure v-if="$store.state.mode == 'measure'" :scene="scene" />
        <Survey v-if="$store.state.mode == 'survey'" :scene="scene"/>
    </div>
</template>

<script>
import store from '@/store';
import Annotate from './Annotate.vue'
import Crop from './Crop.vue'
import Measure from './Measure.vue'
import Survey from './Survey.vue'
import { EventBus } from '@/event-bus.js'

export default {
    name: 'RightSidebar',
    props: ['scene'],
    components: {
        Annotate,
        Crop,
        Measure,
        Survey
    },
    data() {
        return {
         
        }
    },
    methods: {
        
    }
}
</script>

<style scoped>
.right-sidebar::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.right-sidebar {
  -ms-overflow-style: none;  /* for Internet Explorer, Edge */
  scrollbar-width: none;  /* for Firefox */
}
</style>
