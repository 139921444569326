<template>
    <div ref="renderContainer" :id="viewName" class="relative h-full">
        <div :style="borderPosition === 'top' ? { borderTop: `2px solid ${viewColor}` } : { borderBottom: `2px solid ${viewColor}` }"
            class="absolute top-2 right-2 bg-neutral-700 text-white text-xs px-3 py-1 rounded cursor-pointer"
            @click="flipCamera">
            {{ viewName }}
        </div>
        <div v-if="viewName === 'Perspective'"
            class="absolute top-2 left-2 bg-neutral-700 text-white text-xs px-1 rounded">
            <div v-if="model" class="button-group relative flex justify-center items-center m-1">
                <!-- Mesh Button -->
                <button @click="model.material.wireframe = false"
                    class="flex-1 flex justify-center items-center rounded-l bg-neutral-700 transition-colors mr-1">
                    <span
                        :class="{ 'mdi mdi-cube': true, 'text-white': !model.material.wireframe, 'text-gray-500': model.material.wireframe }"></span>
                </button>
                <!-- Wireframe Button -->
                <button @click="model.material.wireframe = true"
                    class="flex-1 flex justify-center items-center rounded-r bg-neutral-700 transition-colors ml-1">
                    <span
                        :class="{'mdi mdi-artboard': true, 'text-white': model.material.wireframe, 'text-gray-500': !model.material.wireframe }"></span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import { ViewHelper } from 'three/addons/helpers/ViewHelper.js';
import { EventBus } from '@/event-bus.js';
import RendererService from '@/assets/js/RendererService.js';

export default {
    name: 'Viewport',
    props: ['scene','viewName', 'viewColor', 'position'],
    data() {
        return {
            model: null,
            renderer: null,
            camera: null,
            controls: null,
            raycaster: new THREE.Raycaster(),
            mouse: new THREE.Vector2(),
            boxControls: null,
            cropControls: null,
            cropAttached: false,
            modelControls: null,
            modelControlsAttached: false,
            helper: null,
            clock: null,
            resizeObserver: null,
            resizeTimout: null,
            gridHelper: null,
            axisHelper: null,
            borderPosition: 'top',
        }
    },
    mounted() {
        this.initRenderer()
        // window.addEventListener('resize', this.onWindowResize, false);
        this.renderer.domElement.addEventListener('click', this.onClick, false);
        this.renderer.domElement.addEventListener('contextmenu', (event) => {
            event.preventDefault();
            if (this.$store.state.activeView !== this.viewName) {
                this.$store.dispatch('setActiveView', this.viewName);
            }
        });
        this.onKeyDown = (event) => {
            if (event.key === 'm') {
                this.handleTransformMode('translate');
            } else if (event.key === 'r') {
                // this.handleTransformMode('rotate');
            } else if (event.key === 's') {
                this.handleTransformMode('scale');
            }
        };
        window.addEventListener('keydown', this.onKeyDown);
        EventBus.$on('model-loaded', this.initScene);
        EventBus.$on('transform-mode', this.handleTransformMode);
        EventBus.$on('box-added', this.onBoxAdded);
        EventBus.$on('reset-viewport', this.resetViewport);
        this.setUpResizeObserver();
    },
    beforeDestroy() {
        // Remove event listeners
        window.removeEventListener('resize', this.onResize, false);
        this.renderer.domElement.removeEventListener('click', this.onClick, false);
        this.renderer.domElement.removeEventListener('contextmenu', this.onContextMenu);
        window.removeEventListener('keydown', this.onKeyDown);

        // Remove EventBus listeners
        EventBus.$off('model-loaded', this.initScene);
        EventBus.$off('transform-mode', this.handleTransformMode);
        EventBus.$off('reset-model', () => this.cropBox = null);
        EventBus.$off('box-added', this.onBoxAdded);
        EventBus.$off('reset-viewport', this.resetViewport);

        // Disconnect resize observer
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }

        if (this.renderer) {
            this.renderer.dispose();
        }

        this.cleanUp();

    },
    computed: {
        selectedBox() {
            return this.$store.state.selectedBox;
        },
        activeView() {
            return this.$store.state.activeView;
        },
        annotateMode() {
            return this.$store.state.mode == 'annotate';
        },
        cropMode() {
            return this.$store.state.mode == 'crop';
        },
        modelMode() {
            return this.$store.state.mode == 'model'
        },
        compareMode() {
            return this.$store.state.mode == 'compare';
        },
    },
    watch: {
        activeView(newView, oldView) {
            // logic for when the view changes
            if (newView === this.viewName) {
                if (this.selectedBox !== null) {
                    this.boxControls.visible = true;
                }
                if (this.cropMode) {
                    this.cropControls.visible = true;
                }
                if (this.modelMode) {
                    this.modelControls.visible = true;
                }
            } else {
                this.boxControls.visible = false;
                this.cropControls.visible = false;
                this.modelControls.visible = false;
            }
        },
        selectedBox(newId, oldId) {
            if (newId === null) {
                this.boxControls.visible = false;
                this.boxControls.detach();
            } else if (newId !== oldId) {
                this.attachBoxControls(newId);
                this.boxControls.setMode('translate');
            }
        },
        modelMode() {
            if (this.modelMode && !this.modelControlsAttached) {
                this.modelControls.attach(this.model);
                this.modelControlsAttached = true;
                this.$store.state.transformActive = true;
            } else if (!this.modelMode && this.modelControlsAttached) {
                this.modelControls.detach();
                this.modelControlsAttached = false;
                this.$store.state.transformActive = false;
            }
            this.modelControls.visible = this.modelMode && this.activeView === this.viewName;
            this.gridHelper.visible = this.modelMode || this.compareMode;
            if (!this.compareMode) {
                this.axisHelper.visible = false;
            }
        },
        cropMode() {
            // attach cropControls
            this.$nextTick(() => {
                if (this.cropMode) {
                    const cropBox = this.scene.getObjectByName('crop');
                    this.cropControls.attach(cropBox);
                    this.$store.state.transformActive = true;
                } else {
                    this.cropControls.detach();
                    this.$store.state.transformActive = false;
                }
                // only show cropControls if this view is active
                if (this.cropMode && this.activeView === this.viewName) {
                    this.cropControls.visible = true;
                } else {
                    this.cropControls.visible = false;
                }
            });
        },
        compareMode() {
            this.gridHelper.visible = this.modelMode || this.compareMode;
            this.axisHelper.visible = this.compareMode;
        }
    },
    methods: {
        initRenderer() {
            this.renderer = new THREE.WebGLRenderer({ antialias: true })
            this.renderer.setSize(this.$refs.renderContainer.clientWidth, this.$refs.renderContainer.clientHeight)
            this.renderer.setPixelRatio(window.devicePixelRatio);
            this.renderer.autoClear = false;
            this.renderer.localClippingEnabled = true;
            this.$refs.renderContainer.appendChild(this.renderer.domElement)

            if (this.viewName === 'Perspective') {
                this.camera = new THREE.PerspectiveCamera(50, this.$refs.renderContainer.clientWidth / this.$refs.renderContainer.clientHeight, 0.1, 1000)
                this.camera.name = 'main'
                RendererService.setRenderer(this.renderer);
                RendererService.setCamera(this.camera);
            } else {
                const aspect = this.$refs.renderContainer.clientWidth / this.$refs.renderContainer.clientHeight
                this.camera = new THREE.OrthographicCamera(-aspect, aspect, 1, -1, 0.1, 1000)
                this.camera.name = this.viewName
            }
            
        },
        render() {
            this.renderer.render(this.scene, this.camera);
        },
        initScene() {
            if (this.viewName === 'Perspective') {
                const radius = 3; // Distance from the center of the scene
                const angle = Math.PI / 4; // 45 degrees in radians
                this.camera.position.x = radius * Math.cos(angle); // Assuming model centered at origin
                this.camera.position.y = radius * Math.sin(angle);
                this.camera.position.z = radius * Math.cos(angle);
                this.camera.lookAt(this.scene.position);
            } else {
                this.camera.position.set(...this.position)
                this.camera.lookAt(0, 0, 0)
            }
            this.scene.add(this.camera)
            this.controls = new OrbitControls(this.camera, this.renderer.domElement)
            this.controls.zoomSpeed = 7;
            this.viewName === 'Perspective' ? this.controls.enableRotate = true : this.controls.enableRotate = false
            
            
            // add modelControls to the scene
            this.model = this.scene.getObjectByName('model');
            this.modelControls = new TransformControls(this.camera, this.renderer.domElement);
            this.modelControls.setSpace('world');
            this.modelControls.setSize(1);
            this.modelControls.addEventListener('dragging-changed', this.onDraggingChanged);
            this.modelControls.addEventListener('objectChange', this.onModelObjectChange);
            this.scene.add(this.modelControls);
            this.modelControls.visible = false;
            this.modelControlsAttached = false;
            
            
            
            // add boxControls to the scene
            this.boxControls = new TransformControls(this.camera, this.renderer.domElement);
            this.boxControls.setSpace('local');
            this.boxControls.setSize(0.75);
            this.boxControls.visible = false;
            this.boxControls.addEventListener('dragging-changed', this.onDraggingChanged);
            
            // update vuex store when box is resized
            this.boxControls.addEventListener('objectChange', this.onBoxObjectChange);
            this.scene.add(this.boxControls);

            // add cropControls to the scene
            this.cropControls = new TransformControls(this.camera, this.renderer.domElement);
            this.cropControls.setSpace('local');
            this.cropControls.setSize(1);
            this.cropControls.addEventListener('dragging-changed', this.onDraggingChanged);
            this.scene.add(this.cropControls);
            this.cropControls.visible = false;
            this.cropControls.addEventListener('objectChange', this.onCropObjectChange);

            // add view helper to perspective view
            if (this.viewName === 'Perspective') {
                this.clock = new THREE.Clock();
                this.helper = new ViewHelper(this.camera, this.renderer.domElement);
                this.helper.controls = this.controls;
                this.helper.controls.center = this.controls.target;

                const div = document.createElement('div');
                div.id = 'viewHelper';
                div.style.position = 'absolute';
                div.style.right = 0;
                div.style.bottom = 0;
                div.style.height = '128px';
                div.style.width = '128px';

                document.getElementById(this.viewName).appendChild(div);
                div.addEventListener('pointerup', (event) => {
                    this.helper.handleClick(event);
                    this.controls.target.copy(this.scene.position);
                    this.controls.update();
                });
            }

            // add helpers
            this.gridHelper = new THREE.GridHelper(10, 10);
            this.scene.add(this.gridHelper);
            this.gridHelper.visible = false;
            this.axisHelper = new THREE.AxesHelper(5);
            this.scene.add(this.axisHelper);
            this.axisHelper.visible = false;

            const animate = () => {
                requestAnimationFrame(animate)
                this.renderer.clear();
                // only perspective view has helper
                if (this.helper) {
                    const delta = this.clock.getDelta();
                    if (this.helper.animating) this.helper.update(delta);
                    this.helper.render(this.renderer);
                }
                this.renderer.render(this.scene, this.camera)
            }
            animate()
        },
        flipCamera() {
            if (this.camera.isOrthographicCamera) {
                const currentPosition = this.camera.position;
                if (this.viewName === 'Side') {
                    this.camera.position.set(-currentPosition.x, currentPosition.y, currentPosition.z);
                    this.camera.lookAt(0, 0, 0);
                    this.controls.update();
                    this.render();
                    this.borderPosition = this.borderPosition === 'top' ? 'bottom' : 'top';
                } else if (this.viewName === 'Front') {
                    this.camera.position.set(currentPosition.x, currentPosition.y, -currentPosition.z);
                    this.camera.lookAt(0, 0, 0);
                    this.controls.update();
                    this.render();
                    this.borderPosition = this.borderPosition === 'top' ? 'bottom' : 'top';
                }
                
            }
        },
        onBoxAdded(center) {
            if (this.viewName === 'Perspective') return;
            let viewCenter;
            if (this.viewName === 'Top') {
                viewCenter = [center.x, 5, center.z];
            } else if (this.viewName === 'Side') {
                viewCenter = [5, center.y, center.z];
            } else if (this.viewName === 'Front') {
                viewCenter = [center.x, center.y, 5];
            }
            this.camera.position.set(...viewCenter);
            this.controls.target.set(...center);
            this.controls.update();
        },
        attachBoxControls(boxId) {
            const box = this.scene.children.find(child =>
                child.userData.id === boxId && child.userData.type === 'annotation')
            if (box) {
                this.boxControls.attach(box);
                // only show box controls if this view is active
                (this.$store.state.activeView ===this.viewName) ? this.boxControls.visible = true : this.boxControls.visible = false;
                this.render();
            }
        },
        handleTransformMode(mode) {
            this.$store.dispatch('setTransformMode', mode);
            if (this.cropControls) {
                this.cropControls.setMode(mode);
            }
            if (this.modelControls) {
                this.modelControls.setMode(mode);
            }
            if (this.boxControls) {
                this.boxControls.setMode(mode);
            }
        },
        onDraggingChanged(event) {
            this.controls.enabled = !event.value;
            // console.log('Dragging changed:', event.value, 'Controls enabled:', this.controls.enabled);
        },
        onBoxObjectChange() {
            this.$store.dispatch('setModified', true);
            const selectedBox = this.$store.state.selectedBox;
            const box = this.scene.children.find(child => child.userData.id === selectedBox && child.userData.type === 'annotation');
            if (box) {
                box.geometry.computeBoundingBox();
                const boundingBox = box.geometry.boundingBox;
                const newWidth = (boundingBox.max.x - boundingBox.min.x) * box.scale.x;
                const newHeight = (boundingBox.max.y - boundingBox.min.y) * box.scale.y;
                const newLength = (boundingBox.max.z - boundingBox.min.z) * box.scale.z;

                if (newWidth <= 0 || newHeight <= 0 || newLength <= 0) {
                    box.scale.set(
                        Math.max(box.scale.x, 0.01),
                        Math.max(box.scale.y, 0.01),
                        Math.max(box.scale.z, 0.01)
                    );
                    return;
                }

                const newSizeInCentimeters = {
                    width: newWidth * 100,
                    height: newHeight * 100,
                    length: newLength * 100
                };
                const newPosition = {
                    x: box.position.x,
                    y: box.position.y,
                    z: box.position.z
                };
                this.$store.dispatch('updateBox', {
                    id: selectedBox,
                    newSize: newSizeInCentimeters,
                    newPosition: newPosition,
                });
            }
        },
        onCropObjectChange() {
            EventBus.$emit('crop');
        },
        onModelObjectChange() {
            const translation = {
                x: this.model.position.x,
                y: this.model.position.y,
                z: this.model.position.z
            };
            const rotation = {
                x: this.model.rotation.x,
                y: this.model.rotation.y,
                z: this.model.rotation.z
            };
            const scale = {
                x: this.model.scale.x,
                y: this.model.scale.y,
                z: this.model.scale.z
            };
            this.$store.dispatch('setModelTransform', { translation, rotation, scale });
        },
        onClick(event) {
            if (this.$store.state.activeView !== this.viewName) {
                this.$store.dispatch('setActiveView', this.viewName);
            }
            if (!this.$store.state.mode == 'annotate') return; // Only allow box selection in annotate mode

            const rect = this.$refs.renderContainer.getBoundingClientRect();
            this.mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
            this.mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
            this.raycaster.setFromCamera(this.mouse, this.camera);
            const boxes = this.scene.children.filter(child => child.userData.type === 'annotation');
            const intersects = this.raycaster.intersectObjects(boxes, true);
            if (intersects.length > 0) {
                const intersectedBox = intersects.find(intersect => intersect.object.userData.type === 'annotation');
                if (intersectedBox) {
                    if (!intersectedBox.object.visible) return;
                    this.$store.dispatch('selectBox', intersectedBox.object.userData.id);
                    EventBus.$emit('box-selected', intersectedBox.object.userData.id);
                } else {
                    // TODO: only deselect if not rotating view
                    // this.$store.dispatch('selectBox', null);
                }
            } 
        },
        resetViewport() {
            if (this.viewName === 'Perspective') {
                const camera = this.camera;
                const controls = this.controls;

                // Compute the bounding box of the model
                const model = this.scene.getObjectByName('model');
                const box = new THREE.Box3().setFromObject(model);
                const center = box.getCenter(new THREE.Vector3());
                const size = box.getSize(new THREE.Vector3());

                // Calculate the maximum dimension and required distance
                const maxDim = Math.max(size.x, size.y, size.z);
                const fov = camera.fov * (Math.PI / 180); // Convert FOV to radians
                const aspect = this.$refs.renderContainer.clientWidth / this.$refs.renderContainer.clientHeight;

                // Calculate the distance needed to fit the entire model in view
                const distance = maxDim / (2 * Math.tan(fov / 2));

                // Use the initial angle to position the camera
                const angle = Math.PI / 4;

                // Adjust the camera's distance based on the model's size
                camera.position.x = center.x + distance * Math.cos(angle);
                camera.position.y = center.y + distance * Math.sin(angle);
                camera.position.z = center.z + distance * Math.cos(angle);
                camera.lookAt(center);

                // Update controls target to the center of the model
                controls.target.copy(center);
                controls.update();
            }
        },
        onWindowResize() {
            if (this.resizeTimeout) clearTimeout(this.resizeTimeout);

            this.resizeTimeout = setTimeout(() => {
                const container = this.$refs.renderContainer;
                const width = container.clientWidth;
                const height = container.clientHeight;

                if (this.camera.isPerspectiveCamera) {
                    this.camera.aspect = width / height;
                } else if (this.camera.isOrthographicCamera) {
                    const aspect = width / height;
                    const frustumHeight = this.camera.top - this.camera.bottom;
                    const frustumWidth = frustumHeight * aspect;
                    this.camera.left = frustumWidth / -2;
                    this.camera.right = frustumWidth / 2;
                    this.camera.top = frustumHeight / 2;
                    this.camera.bottom = frustumHeight / -2;
                }

                this.camera.updateProjectionMatrix();
                this.renderer.setSize(width, height);

            }, 10);
            
            
        },
        setUpResizeObserver() {
            if ('ResizeObserver' in window) {
                this.resizeObserver = new ResizeObserver(entries => {
                    for (let entry of entries) {
                        this.onWindowResize();
                    }
                });
                this.resizeObserver.observe(this.$refs.renderContainer);
            }
        },
        cleanUp() {
            // Cleanup controls and their event listeners
            if (this.boxControls) {
                this.boxControls.removeEventListener('dragging-changed', this.onDraggingChanged);
                this.boxControls.removeEventListener('objectChange', this.onBoxObjectChange);
                this.boxControls.dispose();
            }

            if (this.cropControls) {
                this.cropControls.removeEventListener('dragging-changed', this.onDraggingChanged);
                this.cropControls.removeEventListener('objectChange', this.onCropObjectChange);
                this.cropControls.dispose();
            }

            if (this.modelControls) {
                this.modelControls.removeEventListener('dragging-changed', this.onDraggingChanged);
                this.modelControls.removeEventListener('objectChange', this.onModelObjectChange);
                this.modelControls.dispose();
            }

        },
    }
}
</script>