<template>
    <div>
        <div v-if="transectLine && quadrats.length <= 0"
            class="bg-neutral-900 text-white text-sm p-3 m-1 rounded-sm mt-4">
            <div class="flex justify-between items-center pb-2 mb-2 border-b border-gray-600">
                <div class="font-bold uppercase">Transect</div>
            </div>
            <div class="flex justify-between items-center mb-4">
                <div class="flex-1">
                    <span class="mr-2">Quadrats</span>
                </div>
                <div class="flex items-center">
                    <IncrementSurvey v-model="numQuadrats" :min="1" :max="50" />
                </div>
            </div>
            <div class="flex justify-end">
                <button @click="addQuadrats" class="bg-primary text-white py-1 px-3 rounded">Add Quadrats</button>
            </div>
        </div>
        <div v-if="quadrats.length > 0" class="bg-neutral-900 text-white text-sm p-3 m-1 rounded-sm">
            <div class="flex justify-between items-center pb-2 mb-2 border-b border-gray-600">
                <div class="font-bold uppercase">Quadrats</div>
            </div>
            <div v-for="quadrat in quadrats" :key="quadrat.id" class="p-2 rounded hover:bg-neutral-800 cursor-pointer">
                <div class="flex justify-between items-center" @click="toggleQuadratDetails(quadrat.id)">
                    <div>
                        <span class="text-xs text-gray-400 mr-2">ID</span>
                        <span class="font-semibold">{{ quadrat.id }}</span>
                    </div>
                    <div class="flex items-center">
                        <!-- <button class="text-lg cursor-pointer hover:bg-neutral-600 px-1 rounded ml-2"
                            @click.stop="deleteQuadrat(quadrat.id)">
                            <span class="mdi mdi-delete text-white text-lg"></span>
                        </button> -->
                        <span :class="expandedQuadratId === quadrat.id ? 'mdi-chevron-down' : 'mdi-chevron-left'"
                            class="mdi text-white text-lg"></span>
                    </div>
                </div>
                <div v-if="expandedQuadratId === quadrat.id" class="border-t border-gray-600 mt-1 pt-2">
                    <div>
                        <div class="font-bold text-xs uppercase mb-2">Cover</div>
                        <div class="flex justify-between items-center py-1">
                            <span>Live Coral</span>
                            <span class="text-white">{{ quadrat.data.cover.liveCoralCover }}%</span>
                        </div>
                        <div>
                            <input type="range" v-model="quadrat.data.cover.liveCoralCover" min="0" max="100"
                                class="range-slider">
                        </div>
                        <div class="flex justify-between items-center py-1">
                            <span>Dead Coral</span>
                            <span class="text-white">{{ quadrat.data.cover.deadCoralCover }}%</span>
                        </div>
                        <div>
                            <input type="range" v-model="quadrat.data.cover.deadCoralCover" min="0" max="100"
                                class="range-slider">
                        </div>
                        <div class="flex justify-between items-center py-1">
                            <span>Algae</span>
                            <span class="text-white">{{ quadrat.data.cover.algaeCover }}%</span>
                        </div>
                        <div>
                            <input type="range" v-model="quadrat.data.cover.algaeCover" min="0" max="100"
                                class="range-slider">
                        </div>
                        <div class="flex justify-between items-center py-1">
                            <span>Sand</span>
                            <span class="text-white">{{ quadrat.data.cover.sandCover }}%</span>
                        </div>
                        <div>
                            <input type="range" v-model="quadrat.data.cover.sandCover" min="0" max="100"
                                class="range-slider">
                        </div>
                        <div class="flex justify-between items-center py-1">
                            <span>Rock</span>
                            <span class="text-white">{{ quadrat.data.cover.rockCover }}%</span>
                        </div>
                        <div>
                            <input type="range" v-model="quadrat.data.cover.rockCover" min="0" max="100"
                                class="range-slider">
                        </div>
                        <div class="flex justify-between items-center py-1">
                            <span>Rubble</span>
                            <span class="text-white">{{ quadrat.data.cover.rubbleCover }}%</span>
                        </div>
                        <div>
                            <input type="range" v-model="quadrat.data.cover.rubbleCover" min="0" max="100"
                                class="range-slider">
                        </div>
                    </div>
                    <div class="mt-4">
                        <div class="font-bold text-xs uppercase mb-2">Coral</div>
                        <div class="flex justify-between items-center py-1">
                            <span>Live</span>
                            <div class="flex items-center">
                                <IncrementSurvey v-model="quadrat.data.coral.live" :min="0" :max="100" />
                            </div>
                        </div>
                        <div class="flex justify-between items-center py-1">
                            <span>Dead</span>
                            <div class="flex items-center">
                                <IncrementSurvey v-model="quadrat.data.coral.dead" :min="0" :max="100" />
                            </div>
                        </div>
                        <div class="flex justify-between items-center py-1">
                            <span>Recruits</span>
                            <div class="flex items-center">
                                <IncrementSurvey v-model="quadrat.data.coral.recruits" :min="0" :max="100" />
                            </div>
                        </div>
                    </div>
                    <div class="mt-4">
                        <div class="font-bold text-xs uppercase mb-2">Invertebrates</div>
                        <div v-for="(count, type) in quadrat.data.invertebrates" :key="type"
                            class="flex justify-between items-center py-1">
                            <span class="capitalize">{{ formatTypeName(type) }}</span>
                            <div class="flex items-center">
                                <IncrementSurvey v-model="quadrat.data.invertebrates[type]" :min="0" :max="100" />
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-end space-x-2 mt-4">
                        <button @click="cancelQuadratEdit"
                            class="bg-neutral-700 hover:bg-neutral-600 text-white py-1 px-3 rounded">Cancel</button>
                        <button @click="saveQuadratEdit" class="bg-primary text-white py-1 px-3 rounded">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as THREE from 'three';
import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { LineSegmentsGeometry } from 'three/examples/jsm/lines/LineSegmentsGeometry.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';
import { LineSegments2 } from 'three/examples/jsm/lines/LineSegments2.js';
import RendererService from '@/assets/js/RendererService';
import IncrementSurvey from '@/components/utils/IncrementSurvey.vue';
import { EventBus } from '@/event-bus';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';

export default {
    name: 'Survey',
    props: ['scene'],
    components: {
        IncrementSurvey,
    },
    data() {
        return {
            quadrats: [],
            quadratSize: 1.0,
            expandedQuadratId: null,
            originalQuadratData: null,
            renderer: null,
            labelRenderer: null,
            renderContainer: null,
            camera: null,
            animationFrameId: null,
            transectPoints: [],
            transectLine: null,
            tempTransectLine: null,
            transectLabel: null,
            numQuadrats: 10,
            isTransectMode: false,
            raycaster: new THREE.Raycaster(),
            mouse: new THREE.Vector2(),
            objLoader: new OBJLoader(),
            mtlLoader: new MTLLoader(),
            quadratData: {
                cover: {
                    liveCoralCover: 0,
                    deadCoralCover: 0,
                    algaeCover: 0,
                    sandCover: 0,
                    rockCover: 0,
                    rubbleCover: 0,
                },
                coral: {
                    live: 0,
                    dead: 0,
                    recruits: 0,
                },
                invertebrates: {
                    urchin: 0,
                    starfish: 0,
                    giantClam: 0,
                    seaCucumber: 0
                }
            },
        };
    },
    mounted() {
        this.initLabelRenderer();
        this.setupLighting();
        window.addEventListener('resize', this.onWindowResize);
        EventBus.$on('create-transect', this.createTransect);
        EventBus.$on('clear-transect', this.clearTransect);
        this.loadSavedAnnotations();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onWindowResize);
        EventBus.$off('create-transect', this.createTransect);
        EventBus.$off('clear-transect', this.clearTransect);
        this.cleanUp();
    },
    methods: {
        loadSavedAnnotations() {
            const savedAnnotations = this.$store.state.logs[this.$store.state.log_index]?.annotations || [];
            if (savedAnnotations.length <= 0) return;
            savedAnnotations.forEach(annotation => {
                const quadrat = {
                    id: annotation.id,
                    position: annotation.position,
                    data: annotation.data,
                    outline: this.addOutlineToScene(annotation.id, annotation.position),
                    label: this.addLabelToScene(annotation.id, annotation.position)
                };
                this.quadrats.push(quadrat);

                const box = {
                    id: annotation.id,
                    position: annotation.position,
                    data: annotation.data
                }
                this.$store.dispatch('addBox', box);
            });
        },
        formatTypeName(type) {
            return type.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, str => str.toUpperCase());
        },
        toggleQuadratDetails(id) {
            if (this.expandedQuadratId !== id) {
                this.expandedQuadratId = id;
                const quadrat = this.quadrats.find(q => q.id === id);
                this.originalQuadratData = JSON.parse(JSON.stringify(quadrat.data));
                EventBus.$emit('center-on-quadrat', quadrat);
            } else {
                this.expandedQuadratId = null;
            }
        },
        initLabelRenderer() {
            this.renderer = RendererService.getRenderer();
            this.camera = RendererService.getCamera();
            this.renderContainer = this.renderer.domElement.parentNode;
            this.labelRenderer = new CSS2DRenderer();
            this.labelRenderer.setSize(this.renderContainer.clientWidth, this.renderContainer.clientHeight);
            this.labelRenderer.domElement.style.position = 'absolute';
            this.labelRenderer.domElement.style.top = '0px';
            this.labelRenderer.domElement.style.pointerEvents = 'none';
            this.renderContainer.appendChild(this.labelRenderer.domElement);

            const animate = () => {
                this.animationFrameId = requestAnimationFrame(animate);
                this.labelRenderer.render(this.scene, this.camera);
            };
            animate();
        },
        setupLighting() {
            const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
            directionalLight.position.set(1, 1, 2);
            directionalLight.castShadow = true;
            directionalLight.shadow.mapSize.width = 2048;
            directionalLight.shadow.mapSize.height = 2048;
            directionalLight.shadow.camera.near = 0.5;
            directionalLight.shadow.camera.far = 500;
            this.scene.add(directionalLight);
        },
        addLabelToScene(id, position = { x: 0, y: 0, z: 0 }) {
            const div = document.createElement('div');
            div.className = 'measurementLabel';
            div.innerText = `${id}`;
            const label = new CSS2DObject(div);
            label.position.set(position.x - this.quadratSize / 2, position.y + this.quadratSize / 2, 0.01); // Adjust the position above the quadrat
            label.name = `label-${id}`;
            this.scene.add(label);
            return label;
        },
        saveQuadratEdit() {
            const quadrat = this.quadrats.find(q => q.id === this.expandedQuadratId);
            const box = {
                id: this.expandedQuadratId,
                data: quadrat.data
            }
            this.$store.dispatch('updateQuadratBox', box);
            this.expandedQuadratId = null;
        },
        cancelQuadratEdit() {
            if (this.expandedQuadratId !== null && this.originalQuadratData) {
                const quadrat = this.quadrats.find(q => q.id === this.expandedQuadratId);
                // Revert the data back to the original data
                quadrat.data = JSON.parse(JSON.stringify(this.originalQuadratData));
                this.expandedQuadratId = null; 
            }
        },
        createTransect() {
            this.isTransectMode = true;
            this.transectPoints = [];
            this.renderContainer.addEventListener('click', this.onTransectClick);
            this.renderContainer.addEventListener('mousemove', this.onMouseMove);
            this.renderContainer.addEventListener('contextmenu', this.onRightClick);
            this.renderer.domElement.style.cursor = 'crosshair';
        },
        onTransectClick(event) {
            if (!this.isTransectMode) return;
            const point = this.getMouseIntersection(event);

            if (point) {
                this.transectPoints.push(point);
                if (this.transectPoints.length > 1) {
                    this.drawTransectLine();
                }
            }
        },
        onRightClick(event) {
            event.preventDefault();
            if (!this.isTransectMode) return;
            if (this.transectPoints.length > 1) {
                this.isTransectMode = false;
                this.renderContainer.removeEventListener('click', this.onTransectClick);
                this.renderContainer.removeEventListener('mousemove', this.onMouseMove);
                this.renderContainer.removeEventListener('contextmenu', this.onRightClick);
                this.renderer.domElement.style.cursor = 'default';
                this.drawTransectLine(null, true); // Finalize the line
            }
        },
        onMouseMove(event) {
            if (!this.isTransectMode || this.transectPoints.length === 0) return;
            const point = this.getMouseIntersection(event);

            if (point) {
                this.drawTransectLine(point);
            }
        },
        getMouseIntersection(event) {
            const rect = this.renderContainer.getBoundingClientRect();
            this.mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
            this.mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

            const camera = this.scene.getObjectByName('main');
            const model = this.scene.children.filter(child => child.userData.type === 'model' || child.userData.type === 'ortho');
            this.raycaster.setFromCamera(this.mouse, camera);
            const intersects = this.raycaster.intersectObjects(model, false);

            if (intersects.length > 0) {
                return intersects[0].point;
            }
            return null;
        },
        drawTransectLine(mousePoint = null, finalize = false) {
            // Remove the old transect line if it exists
            if (this.transectLine) {
                this.scene.remove(this.transectLine);
            }
            if (this.tempTransectLine) {
                this.scene.remove(this.tempTransectLine);
            }

            const points = [...this.transectPoints];
            if (mousePoint && !finalize) {
                points.push(mousePoint);
            }

            if (points.length < 2) return;

            const positions = [];
            for (let i = 0; i < points.length - 1; i++) {
                positions.push(points[i].x, points[i].y, points[i].z);
                positions.push(points[i + 1].x, points[i + 1].y, points[i + 1].z);
            }

            const geometry = new LineSegmentsGeometry();
            geometry.setPositions(new Float32Array(positions));

            const material = new LineMaterial({
                color: 0xffffff,
                linewidth: 2,
                resolution: new THREE.Vector2(window.innerWidth, window.innerHeight)
            });

            const line = new LineSegments2(geometry, material);
            line.computeLineDistances();
            this.updateLabel(points);

            if (finalize) {
                this.transectLine = line;
                this.rotateModelForTransect();
            } else {
                this.tempTransectLine = line;
            }

            this.scene.add(line);
        },
        updateLabel(points) {
            const totalLength = points.reduce((sum, point, index) => {
                if (index === 0) return 0;
                return sum + points[index - 1].distanceTo(point);
            }, 0).toFixed(2);

            const midIndex = Math.floor(points.length / 2);
            const midPoint = points.length % 2 === 0
                ? points[midIndex - 1].clone().lerp(points[midIndex], 0.5)
                : points[midIndex];

            if (!this.transectLabel) {
                const div = document.createElement('div');
                div.className = 'measurementLabel';
                const label = new CSS2DObject(div);
                this.transectLabel = label;
                this.scene.add(this.transectLabel);
            }
            this.transectLabel.element.innerText = `${totalLength}m`;
            this.transectLabel.position.set(midPoint.x, midPoint.y, midPoint.z);
        },
        rotateModelForTransect() {
            const model = this.scene.getObjectByName('model');
            if (model && this.transectPoints.length > 1) {
                const start = this.transectPoints[0];
                const end = this.transectPoints[this.transectPoints.length - 1];
                const deltaX = end.x - start.x;
                const deltaY = end.y - start.y;

                let angle = Math.atan2(deltaY, deltaX);
                angle = Math.PI / 2 - angle;

                // Rotate model
                model.rotation.z += angle;
                model.updateMatrix();
                model.updateMatrixWorld();

                this.$store.dispatch('setModelTransform', {
                    translation: {
                        x: model.position.x,
                        y: model.position.y,
                        z: model.position.z
                    },
                    rotation: {
                        x: model.rotation.x,
                        y: model.rotation.y,
                        z: model.rotation.z
                    },
                    scale: {
                        x: model.scale.x,
                        y: model.scale.y,
                        z: model.scale.z
                    }
                });

                // Rotate transect line
                if (this.transectLine) {
                    this.transectLine.rotation.z = angle;
                    this.transectLine.updateMatrix();
                    this.transectLine.updateMatrixWorld();
                }

                // Rotate transect points
                const rotationMatrix = new THREE.Matrix4().makeRotationZ(angle);
                this.transectPoints.forEach(point => {
                    point.applyMatrix4(rotationMatrix);
                });
            }
        },
        addQuadrats() {
            if (this.transectPoints.length < 2) return;
            this.scene.remove(this.transectLine);
            this.scene.remove(this.tempTransectLine);
            this.scene.remove(this.transectLabel);
            this.transectLine = null;
            this.tempTransectLine = null;
            this.transectLabel = null;

            const totalLength = this.transectPoints.reduce((sum, point, index) => {
                if (index === 0) return 0;
                return sum + this.transectPoints[index - 1].distanceTo(point);
            }, 0);

            const step = totalLength / (this.numQuadrats - 1);
            let currentLength = 0;
            let currentSegmentIndex = 0;

            for (let i = 0; i < this.numQuadrats; i++) {
                while (currentSegmentIndex < this.transectPoints.length - 1) {
                    const segmentLength = this.transectPoints[currentSegmentIndex].distanceTo(this.transectPoints[currentSegmentIndex + 1]);
                    if (currentLength + segmentLength >= step * i) {
                        const t = (step * i - currentLength) / segmentLength;
                        const position = new THREE.Vector3().lerpVectors(this.transectPoints[currentSegmentIndex], this.transectPoints[currentSegmentIndex + 1], t);
                        const id = this.quadrats.length + 1;

                        const quadrat = {
                            id,
                            position: { x: position.x, y: position.y, z: position.z },
                            data: JSON.parse(JSON.stringify(this.quadratData)), // Create a deep copy of the data object
                            outline: this.addOutlineToScene(id, position),
                            label: this.addLabelToScene(id, position)
                        };
                        this.quadrats.push(quadrat);

                        const box = {
                            id,
                            position: { x: position.x, y: position.y, z: position.z },
                            data: quadrat.data
                        }
                        this.$store.dispatch('addBox', box);
                        break;
                    } else {
                        currentLength += segmentLength;
                        currentSegmentIndex++;
                    }
                }
            }
        },
        addOutlineToScene(id, position = { x: 0, y: 0, z: 0 }) {
            const quadratGroup = new THREE.Group();
            quadratGroup.name = `quadrat-${id}`;
            this.mtlLoader.load('/models/quadrat.mtl', (materials) => {
                materials.preload();
                this.objLoader.setMaterials(materials);
                this.objLoader.load(
                    '/models/quadrat.obj',
                    (obj) => {
                        obj.scale.set(this.quadratSize, this.quadratSize, this.quadratSize);
                        obj.position.set(position.x, position.y, position.z + 0.1);
                        obj.rotation.set(Math.PI / 2, 0, 0);

                        obj.traverse((child) => {
                            if (child.isMesh) {
                                child.castShadow = true;
                                child.receiveShadow = true;
                            }
                        });
                        quadratGroup.add(obj);
                        this.scene.add(quadratGroup);
                    },
                    (xhr) => {
                        // console.log((xhr.loaded / xhr.total * 100) + '% loaded');
                    },
                    (error) => {
                        console.error('An error happened', error);
                    }
                );
            });
            return quadratGroup;
        },
        clearTransect() {
            if (this.transectLine) {
                this.scene.remove(this.transectLine);
                this.transectLine = null;
            }
            if (this.tempTransectLine) {
                this.scene.remove(this.tempTransectLine);
                this.tempTransectLine = null;
            }
            if (this.transectLabel) {
                this.scene.remove(this.transectLabel);
                this.transectLabel = null;
            }
            this.quadrats.forEach(quadrat => {
                this.scene.remove(quadrat.outline);
                this.scene.remove(quadrat.label);
            });
            this.quadrats = [];
            this.transectPoints = [];
            this.$store.dispatch('clearAnnotations');
        },
        cleanUp() {
            cancelAnimationFrame(this.animationFrameId);
            if (this.labelRenderer) {
                this.labelRenderer.domElement.remove();
                this.labelRenderer = null;
            }
            this.quadrats.forEach(quadrat => {
                this.scene.remove(quadrat.outline);
                this.scene.remove(quadrat.label);
            });
        },
        onWindowResize() {
            if (this.labelRenderer) {
                this.labelRenderer.setSize(this.renderContainer.clientWidth, this.renderContainer.clientHeight);
            }
        }
    }
};
</script>

<style scoped>
.measurementLabel {
    padding: 4px 8px;
    border-radius: 5px;
    background-color: black;
    color: white;
    font-size: 12px;
    font-family: Arial, sans-serif;
    text-align: center;
    white-space: nowrap;
    z-index: 50 !important;
}

.range-slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 2px;
    background: #27BDF4;
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
}

.range-slider:hover {
    opacity: 1;
}

.range-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #27BDF4;
    cursor: pointer;
}

.range-slider::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #27BDF4;
    cursor: pointer;
}
</style>
