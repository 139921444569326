<template>
    <div class="flex items-center border border-gray-600 rounded overflow-hidden bg-neutral-800 w-full">
        <button @click="decrement" :disabled="internalValue <= min"
            class="px-2 py-1 bg-neutral-800 text-white disabled:opacity-50 focus:outline-none hover:bg-neutral-700">
            -
        </button>
        <input type="text" :value="internalValue.toString()" readonly
            class="w-12 text-center bg-neutral-800 text-white font-bold focus:outline-none" />
        <button @click="increment" :disabled="internalValue >= max"
            class="px-2 py-1 bg-neutral-800 text-white disabled:opacity-50 focus:outline-none hover:bg-neutral-700">
            +
        </button>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: [Number, String],
            default: 0,
        },
        min: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: 100
        }
    },
    computed: {
        internalValue: {
            get() {
                // Ensure that the prop's value is treated as a number
                return Number(this.value);
            },
            set(val) {
                // Emit the input event with the new value to support v-model in the parent
                this.$emit('input', val);
            }
        }
    },
    methods: {
        increment() {
            if (this.internalValue < this.max) {
                this.internalValue += 1;
            }
        },
        decrement() {
            if (this.internalValue > this.min) {
                this.internalValue -= 1;
            }
        }
    }
};
</script>
