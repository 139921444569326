<template>
    <div class="relative inline-block text-left w-full">
        <div @click="toggleDropdown"
            class="cursor-pointer p-2 bg-black text-white rounded flex items-center justify-between">
            <span>{{ selected ? selected.name : placeholder }}</span>
        </div>
        <div v-if="isOpen"
            class="z-50 origin-top-left absolute left-0 mt-2 w-full min-w-max rounded-md shadow-lg bg-neutral-800 ring-1 ring-black ring-opacity-5 max-h-400px overflow-y-auto">
            <div class="py-1" role="menu" aria-orientation="vertical">
                <div v-for="item in options" :key="item[optionValue]" @click="selectItem(item)"
                    class="block px-2 py-2 text-sm text-white hover:bg-neutral-700 cursor-pointer flex items-center">
                    <span v-if="item[typeField] === 'outplant'" class="mdi mdi-cube-outline text-lg mr-2"></span>
                    <span v-if="item[typeField] === 'survey'" class="mdi mdi-map-search text-lg mr-2"></span>
                    {{ item[optionLabel] }}
                    <span v-if="item.referenceSiteName"
                        class="ml-3 bg-gray-200 text-gray-800 text-xs font-semibold px-1 py-0.5 rounded flex items-center">
                        <span class="mdi mdi-cube-outline mr-1"></span>
                        {{ item.referenceSiteName }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: [Number, String, Object],
            default: ''
        },
        options: {
            type: Array,
            default: () => []
        },
        placeholder: {
            type: String,
            default: 'Select an option'
        },
        optionLabel: {
            type: String,
            required: true
        },
        optionValue: {
            type: String,
            required: true
        },
        typeField: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            isOpen: false,
            selected: null
        };
    },
    watch: {
        value(newValue) {
            this.updateSelected(newValue);
        },
        options: {
            immediate: true,
            handler(newOptions) {
                this.updateSelected(this.value);
            }
        }
    },
    mounted() {
        document.addEventListener('click', this.handleClick, true);
        this.updateSelected(this.value);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClick, true);
    },
    methods: {
        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },
        selectItem(item) {
            this.selected = item;
            this.$emit('input', item[this.optionValue]);
            this.isOpen = false;
        },
        handleClick(event) {
            if (!this.$el.contains(event.target)) {
                this.isOpen = false;
            }
        },
        updateSelected(value) {
            this.selected = this.options.find(option => option[this.optionValue] === value) || null;
        }
    }
};
</script>
