<template>
    <div class="flex w-full">
        <Popup v-if="showPopup" title="Warning" message="You will lose all unsaved changes. Do you want to continue?"
            :visible="showPopup" @confirm="handleConfirm" @cancel="handleCancel" />
        <div class="p-3 bg-black flex w-full items-center justify-between border-b border-gray-500 text-sm">
            <!-- Left Side -->
            <div class="flex items-center">
                <!-- Company Logo -->
                <img src="@/assets/images/cg.png" alt="Company Logo" class="h-8 w-8 mr-2 ml-1">

                <div class="inline-flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white mx-1" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                    </svg>
                </div>

                <!-- Branches Dropdown -->
                <div class="inline-flex items-center cursor-pointer">
                    <DropdownHeader v-model="selectedBranch" :options="branches" placeholder="Select a Branch"
                        @input="confirmChange('branch', false)" option-label="name" option-value="id" />
                </div>
                <svg v-if="selectedBranch" xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white mx-1"
                    fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                </svg>

                <!-- Sites Dropdown, shown only if a branch is selected -->
                <div v-if="selectedBranch" class="inline-flex items-center">
                    <DropdownHeaderSites v-model="selectedSite" :options="sites" placeholder="Site"
                        @input="confirmChange('site', false)" option-label="name" option-value="id" type-field="type" />
                </div>
                <svg v-if="selectedSite" xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white mx-1" fill="none"
                    viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                </svg>

                <!-- Conditional Dropdown for OutplantCells or SurveyCells -->
                <div v-if="selectedSite" class="inline-flex items-center">
                    <DropdownHeaderCells v-model="selectedCell" :options="cells" placeholder="Cell"
                        @input="confirmChange('cell', false)" option-label="name" option-value="id" />
                </div>
                <svg v-if="selectedCell" xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-white mx-1" fill="none"
                    viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                </svg>

                <!-- Logs Dropdown -->
                <div v-if="selectedCell" class="inline-flex items-center">
                    <DropdownHeaderLogs v-model="selectedLog" :options="logs" placeholder="Log"
                        @input="confirmChange('log', false)" />
                </div>

                <!-- Model Type Dropdown -->
                <!-- <div v-if="selectedLog != null || selectedTransect != null" class="inline-flex items-center ml-4 w-28">
                    <DropdownModel v-model="selectedModelType" :options="modelTypes" placeholder="Model Type"
                        @input="selectModelType" option-label="text" option-value="value" />
                </div> -->
            </div>

            <!-- Right Section -->
            <div class="flex justify-end space-x-2 text-white text-sm items-center">
                <div v-if="userLogsLoaded" class="relative inline-flex items-center cursor-pointer">
                    <DropdownUserLogs :options="unannotatedLogs" @input="handleUserLogSelection" ref="userLogsDropdown">
                        <template #icon>
                            <span class="mdi mdi-bell text-lg"></span>
                        </template>
                        <template #badge>
                            <div v-if="unannotatedLogs.length > 0"
                                class="absolute bottom-2 right-0 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
                                {{ unannotatedLogs.length }}
                            </div>
                        </template>
                    </DropdownUserLogs>
                </div>

                <!-- Download Dropdown -->
                <div class="relative flex items-center">
                    <DropdownDownload :options="downloadOptions" @select="triggerDownload"
                        class="bg-neutral-700 hover:bg-neutral-500 rounded transition-colors" />
                </div>

                <div class="relative flex items-center">
                    <button v-if="$store.state.log_index != null" @click="toggleModelAnnotated"
                        class=" bg-neutral-700 hover:bg-neutral-500 py-1 px-4 rounded transition-colors">
                        <span class="mdi text-lg"
                            :class="{ 'mdi-minus-circle-outline': !$store.state.logs[$store.state.log_index].modelAnnotated, 'mdi-check-circle-outline text-green-500': $store.state.logs[$store.state.log_index].modelAnnotated }">
                        </span>
                        <span class="tooltip">Mark as completed</span>
                    </button>
                </div>
                <!-- Save Button -->
                <button class="w-24 py-1 px-4 rounded items-center flex transition-colors" @click="saveAnnotations"
                    :class="{
                        'bg-primary': $store.state.modified,
                        'bg-neutral-700 hover:bg-neutral-500': !$store.state.modified
                    }" :disabled="!$store.state.modified">
                    <span :class="{
                        'text-white text-lg': true,
                        'mdi mdi-cloud-check-outline': !$store.state.modified,
                        'mdi mdi-cloud-upload': $store.state.modified
                    }">
                    </span>
                    <span v-if="$store.state.modified" class="text-white ml-2">Save</span>
                    <span v-else class="text-white ml-2">Saved</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { doc, getDoc, getDocs, collection } from "firebase/firestore";
import { db } from '@/main.js'
import { EventBus } from '@/event-bus.js';
import Dropdown from '@/components/utils/Dropdown.vue';
import DropdownHeader from '@/components/utils/DropdownHeader.vue';
import DropdownHeaderSites from '@/components/utils/DropdownHeaderSites.vue';
import DropdownHeaderCells from '@/components/utils/DropdownHeaderCells.vue';
import DropdownHeaderLogs from '@/components/utils/DropdownHeaderLogs.vue';
import DropdownUserLogs from '@/components/utils/DropdownUserLogs.vue';
import DropdownModel from '@/components/utils/DropdownModel.vue';
import DropdownDownload from '@/components/utils/DropdownDownload.vue';
import Popup from "@/components/utils/Popup.vue";

export default {
    name: 'Header',
    components: {
        Dropdown,
        DropdownHeader,
        DropdownHeaderSites,
        DropdownHeaderLogs,
        DropdownHeaderCells,
        DropdownUserLogs,
        DropdownModel,
        DropdownDownload,
        Popup,
    },
    props: {
        branchId: String,
        siteType: String,
        siteId: String,
        cellId: String,
        logIndex: String
    },
    data() {
        return {
            branches: [],
            sites: [],
            cells: [],
            logs: [],
            userLogs: [],
            userLogsLoaded: false,
            userBranchId: '',
            userSiteId: '',
            userCellId: '',
            userLogIndex: '',
            selectedBranch: '',
            selectedSite: '',
            selectedSiteType: '',
            selectedCell: '',
            selectedLog: '',
            id: '',
            name: '',
            showPopup: false,
            changeType: '',
            initial: false,
            previousSelection: {
                branch: '',
                site: '',
                cell: '',
                log: '',
            },
            modelTypes: [
                { value: 'mesh', text: '3D Model' },
                { value: 'ortho', text: 'Orthomosaic' },
                { value: 'point', text: 'Point Cloud' },
                { value: 'dem', text: 'DEM' }
            ],
            selectedModelType: 'mesh',
            downloadOptions: [
                { label: 'Model and Texture', value: 'model', icon: 'mdi mdi-cube-outline' },
                { label: 'Point Cloud', value: 'pointCloud', icon: 'mdi mdi-dots-hexagon' },
                { label: 'Annotations', value: 'annotations', icon: 'mdi mdi-file-document' }
            ],
        };
    },
    mounted() {
        this.fetchBranches().then(() => {
            if (this.branchId) {
                this.selectedBranch = this.branchId;
                this.onBranchChange(true);
            }
        });
        this.fetchUserLogs();
    },
    computed: {
        log_index() {
            return this.$store.state.log_index;
        },
        unannotatedLogs() {
            return this.userLogs.filter(log => !log.logData.modelAnnotated);
        },
    },
    watch: {
        log_index() {
            this.selectedLog = this.log_index;
        },
    },
    methods: {
        selectModelType() {
            if (this.selectedModelType !== this.$store.state.modelType) {
                this.$store.dispatch('setModelType', this.selectedModelType);
                EventBus.$emit('reset-model');
                EventBus.$emit('model-selected');
            }
        },
        toggleModelAnnotated() {
            this.$store.dispatch('toggleModelAnnotated');
        },
        saveAnnotations() {
            EventBus.$emit('save-annotations');
            this.fetchUserLogs();
        },
        async fetchUserLogs() {
            try {
                const userID = this.$store.state.user.uid;
                const branchesRef = collection(db, "Orgs", "coral-gardeners", "Branches");
                const branchesSnapshot = await getDocs(branchesRef);
                this.userLogs = [];

                for (const branchDoc of branchesSnapshot.docs) {
                    const branchId = branchDoc.id;
                    const branchName = branchDoc.data().name; // Get branch name
                    const sitesRef = collection(db, "Orgs", "coral-gardeners", "Branches", branchId, "OutplantSites");
                    const sitesSnapshot = await getDocs(sitesRef);

                    for (const siteDoc of sitesSnapshot.docs) {
                        const siteId = siteDoc.id;
                        const siteName = siteDoc.data().name; // Get site name
                        const cellsRef = collection(db, "Orgs", "coral-gardeners", "Branches", branchId, "OutplantSites", siteId, "OutplantCells");
                        const cellsSnapshot = await getDocs(cellsRef);

                        for (const cellDoc of cellsSnapshot.docs) {
                            const cellId = cellDoc.id;
                            const cellName = cellDoc.data().name; // Get cell name
                            const cellData = cellDoc.data();

                            if (cellData.healths) {
                                for (const [index, log] of cellData.healths.entries()) {
                                    if (log.userID === userID) {
                                        this.userLogs.push({
                                            branchId: branchId,
                                            branchName: branchName,
                                            siteId: siteId,
                                            siteName: siteName,
                                            cellId: cellId,
                                            cellName: cellName,
                                            log: index,
                                            logTimestamp: log.timestamp.toDate().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }),
                                            logData: log
                                        });
                                    }
                                }
                            }
                        }
                    }
                }
                this.userLogsLoaded = true;
            } catch (error) {
                console.error('Error fetching user logs:', error);
            }
        },
        async handleUserLogSelection(log) {
            this.userBranchId = log.branchId;
            this.userSiteId = log.siteId;
            this.userCellId = log.cellId;
            this.userLogIndex = log.log;
            this.selectedBranch = log.branchId;
            this.onBranchChange(true);
        },
        toggleUserLogsDropdown(event) {
            this.$refs.userLogsDropdown.toggleDropdown();
        },
        async fetchBranches() {
            this.branches = [];
            this.sites = [];
            this.cells = [];
            this.logs = [];
            this.selectedBranch = '';
            this.selectedSite = '';
            this.selectedCell = '';
            this.selectedLog = '';
            const querySnapshot = await getDocs(collection(db, "Orgs", "coral-gardeners", "Branches"));
            this.branches = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        },
        async fetchSites(branchId) {
            this.sites = [];
            this.cells = [];
            this.logs = [];
            this.selectedSite = '';
            this.selectedCell = '';
            this.selectedLog = '';

            // Fetch OutplantSites and SurveySites
            const outplantSitesRef = collection(db, "Orgs", "coral-gardeners", "Branches", branchId, "OutplantSites");
            const surveySitesRef = collection(db, "Orgs", "coral-gardeners", "Branches", branchId, "SurveySites");

            const [outplantSitesSnapshot, surveySitesSnapshot] = await Promise.all([
                getDocs(outplantSitesRef),
                getDocs(surveySitesRef)
            ]);

            const outplantSites = outplantSitesSnapshot.docs.map(outplantDoc => ({
                id: outplantDoc.id,
                ...outplantDoc.data(),
                type: 'outplant'
            }));

            const surveySites = await Promise.all(surveySitesSnapshot.docs.map(async surveyDoc => {
                const siteData = surveyDoc.data();
                let referenceSiteName = '';

                if (siteData.referenceSite) {
                    const referenceSiteDoc = await getDoc(doc(db, "Orgs", "coral-gardeners", "Branches", branchId, "OutplantSites", siteData.referenceSite));
                    if (referenceSiteDoc.exists()) {
                        referenceSiteName = referenceSiteDoc.data().name;
                    }
                }

                return {
                    id: surveyDoc.id,
                    ...siteData,
                    type: 'survey',
                    referenceSiteName: referenceSiteName
                };
            }));

            this.sites = [...outplantSites, ...surveySites];
        },
        async fetchCells(branchId, siteId, siteType) {
            this.cells = [];
            this.logs = [];
            this.selectedCell = '';
            this.selectedLog = '';
            const cellsRef = collection(db, "Orgs", "coral-gardeners", "Branches", branchId, `${siteType === 'outplant' ? 'OutplantSites' : 'SurveySites'}`, siteId, `${siteType === 'outplant' ? 'OutplantCells' : 'SurveyCells'}`);
            const querySnapshot = await getDocs(cellsRef);

            this.cells = await Promise.all(querySnapshot.docs
                .filter(doc => doc.data().healths !== undefined)
                .map(async doc => {
                    const cellData = doc.data();
                    const healths = cellData.healths;
                    let hasUnannotated = false;

                    if (healths && healths.some(log => log.modelAnnotated !== true)) {
                        hasUnannotated = true;
                    }

                    return { id: doc.id, ...cellData, hasUnannotated };
                })
            );
            // Sort cells by name
            this.cells.sort((a, b) => {
                const aName = a.name;
                const bName = b.name;
                const aNum = parseInt(aName, 10);
                const bNum = parseInt(bName, 10);

                if (!isNaN(aNum) && !isNaN(bNum)) {
                    return aNum - bNum;
                } else {
                    return aName.localeCompare(bName);
                }
            });
        },
        async fetchLogs(branchId, siteId, cellId, siteType) {
            this.logs = [];
            this.selectedLog = '';
            const logsRef = doc(db, "Orgs", "coral-gardeners", "Branches", branchId, `${siteType === 'outplant' ? 'OutplantSites' : 'SurveySites'}`, siteId, `${siteType === 'outplant' ? 'OutplantCells' : 'SurveyCells'}`, cellId);
            const docSnapshot = await getDoc(logsRef);
            if (docSnapshot.exists()) {
                const healths = docSnapshot.data().healths;
                this.logs = healths.map((value, index) => ({
                    ...value,
                    index: index,
                    modelAnnotated: value.modelAnnotated || false,
                    geoLocation: docSnapshot.data().geoLocation,
                }));
            }
            const payload = {
                branch: this.selectedBranch,
                site: this.selectedSite,
                cell: this.selectedCell,
                name: docSnapshot.data().name,
                type: docSnapshot.data().type,
                loc: docSnapshot.data().geoLocation,
                createdAt: docSnapshot.data().created,
                siteType: siteType,
            }
            this.$store.dispatch('setCell', payload);
            this.$store.dispatch('setLogs', this.logs);
        },
        confirmChange(type, initial) {
            if (this.$store.state.modified) {
                this.showPopup = true;
                this.changeType = type;
                this.initial = initial;
            } else {
                this.handleChange(type, initial);
            }
        },
        handleChange(type, initial) {
            switch (type) {
                case 'branch':
                    this.onBranchChange(initial);
                    break;
                case 'site':
                    this.onSiteChange(initial);
                    break;
                case 'cell':
                    this.onCellChange(initial);
                    break;
                case 'log':
                    this.onLogChange(initial);
                    break;
            }
        },
        handleConfirm() {
            this.showPopup = false;
            this.handleChange(this.changeType, this.initial);
        },
        handleCancel() {
            this.showPopup = false;
            this.selectedBranch = this.previousSelection.branch;
            this.selectedSite = this.previousSelection.site;
            this.selectedCell = this.previousSelection.cell;
            this.selectedLog = this.previousSelection.log;
        },
        async onBranchChange(initial) {
            EventBus.$emit('reset-model');
            await this.fetchSites(this.selectedBranch);
            if (initial && (this.siteId || this.userSiteId)) {
                this.userSiteId ? this.selectedSite = this.userSiteId : this.selectedSite = this.siteId;
                this.selectedSiteType = this.siteType;
                this.onSiteChange(true);
            }
            if (!initial) {
                this.$router.replace({ path: `/${this.selectedBranch}` });
            }
            this.previousSelection.branch = this.selectedBranch;
        },
        async onSiteChange(initial) {
            EventBus.$emit('reset-model');
            this.selectedSiteType = this.sites.find(site => site.id === this.selectedSite).type;
            await this.fetchCells(this.selectedBranch, this.selectedSite, this.selectedSiteType);
            if (initial && (this.cellId || this.userCellId)) {
                this.userCellId ? this.selectedCell = this.userCellId : this.selectedCell = this.cellId;
                this.onCellChange(true);
            }
            if (!initial) {
                this.$router.replace({ path: `/${this.selectedBranch}/${this.selectedSiteType}/${this.selectedSite}` });
            }
            this.previousSelection.site = this.selectedSite;
        },
        async onCellChange(initial) {
            EventBus.$emit('reset-model');
            await this.fetchLogs(this.selectedBranch, this.selectedSite, this.selectedCell, this.selectedSiteType);
            if (initial && (this.logIndex || this.userLogIndex !== '')) {
                this.userLogIndex ? this.selectedLog = this.userLogIndex : this.selectedLog = this.logIndex;
                this.onLogChange(true);
            }
            if (!initial) {
                this.$router.replace({ path: `/${this.selectedBranch}/${this.selectedSiteType}/${this.selectedSite}/${this.selectedCell}` });
            }
            this.previousSelection.cell = this.selectedCell;
        },
        onLogChange(initial) {
            if (initial && this.userLogIndex !== '') {
                this.selectedLog = this.userLogIndex;
            }
            const newPath = `/${this.selectedBranch}/${this.selectedSiteType}/${this.selectedSite}/${this.selectedCell}/${this.selectedLog}`;
            if (this.$route.path !== newPath) {
                this.$router.replace({ path: newPath });
            }
            this.$store.dispatch('modelSelected', { index: this.selectedLog, modelType: this.selectedSiteType === 'outplant' ? 'mesh' : 'ortho' });
            EventBus.$emit('model-selected');
            this.previousSelection.log = this.selectedLog;
        },
        triggerDownload(type) {
            EventBus.$emit('download', type);
        },
    },
}
</script>

<style scoped>
.tooltip {
    position: absolute;
    top: 110%;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(57, 65, 80, 1.0);
    color: white;
    text-align: center;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 0.75rem;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease, visibility 0s linear 1s;
    z-index: 10;
}

.relative:hover .tooltip {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
}
</style>
