<template>
    <div class="flex h-full">
        <div class="flex">
            <!-- Content area -->
            <div ref="contentArea" class="flex-1 overflow-hidden"
                :class="{ 'order-1 border border-l-1 border-gray-600': isExpanded, 'w-64': isExpanded, 'w-0': !isExpanded }"
                transition="expand">
                <div v-if="activeTab === 'photos'">
                    <Photos :photoUrls="photoUrls" />
                </div>
                <div v-else-if="activeTab === 'info'">
                    <Info :metadata="metadata" :activeTab="activeTab" />
                </div>
                <div v-else-if="activeTab === 'fragments'">
                    <Fragments :fragments="fragments" />
                </div>
            </div>
            <!-- Tabs container, moved after the content area with dynamic order -->
            <div class="flex flex-col justify-start bg-black"
                :class="{ 'order-2': !isExpanded, 'order-1': isExpanded }">
                <!-- Tab for Photos -->
                <button @click="setActiveTab('photos')"
                    class="p-2 focus:outline-none flex flex-col items-center justify-center w-8 pb-3"
                    :class="{ 'bg-[#0C508D]': activeTab === 'photos', 'hover:bg-neutral-600': activeTab !== 'photos' }">
                    <span class="mdi mdi-image-outline text-white rotate-90"></span>
                    <span class="text-xs text-white mt-1">Photos
                        <span
                            :class="{ 'bg-[#073975]': activeTab === 'photos', 'bg-neutral-700': activeTab !== 'photos' }"
                            class="p-1 rounded-full">{{ photoUrls.length }}
                        </span>
                    </span>
                </button>
                <!-- Tab for Fragments -->
                <button v-if="this.$store.state.cell && this.$store.state.cell.siteType == 'outplant'"
                    @click="setActiveTab('fragments')"
                    class="p-2 focus:outline-none flex flex-col items-center justify-center w-8 pb-3"
                    :class="{ 'bg-[#0C508D]': activeTab === 'fragments', 'hover:bg-neutral-600': activeTab !== 'fragments' }">
                    <span class="mdi mdi-tree-outline text-white rotate-90"></span>
                    <span class="text-xs text-white mt-1">Fragments
                        <span
                            :class="{ 'bg-[#073975]': activeTab === 'fragments', 'bg-neutral-700': activeTab !== 'fragments' }"
                            class="p-1 rounded-full">{{ fragments.length || 0 }}
                        </span>
                    </span>
                </button>
                <!-- Tab for Info -->
                <button @click="setActiveTab('info')"
                    class="p-2 focus:outline-none flex flex-col items-center justify-center w-8 pb-3"
                    :class="{ 'bg-[#0C508D]': activeTab === 'info', 'hover:bg-neutral-600': activeTab !== 'info' }">
                    <span class="mdi mdi-information-outline text-white rotate-90"></span>
                    <span class="text-xs text-white mt-1">Info</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Photos from '@/components/Photos.vue';
import Info from '@/components/Info.vue';
import Fragments from '@/components/Fragments.vue';
import { db } from '@/main.js';
import { getDocs, collection } from "firebase/firestore";
import { EventBus } from '@/event-bus';

export default {
    name: 'ContextBar',
    components: {
        Photos,
        Info,
        Fragments,
    },
    data() {
        return {
            isExpanded: false,
            activeTab: '',
            photoUrls: [],
            metadata: {},
            fragments: {},
        };
    },
    mounted() {
        EventBus.$on('model-selected', this.fetch);
        EventBus.$on('reset-model', this.cleanUp)
    },
    beforeDestroy() {
        EventBus.$off('model-selected', this.fetch);
        EventBus.$off('reset-model', this.cleanUp)
    },
    computed: {
        path() {
            return this.$store.state.logs[this.$store.state.log_index].photogrammetryPath;
        },
    },
    watch: {
        '$store.state.logs': function () {
            this.photoUrls = [];
            this.metadata = {};
            this.fragments = {};
        },
    },
    methods: {
        fetch() {
            this.fetchPhotos();
            // this.fetchMetadata();
            this.fetchFragments();
        },
        fetchPhotos() {
            const functionUrl = `https://us-central1-restoration-ios.cloudfunctions.net/getPublicUrls?folderPath=${this.path}`;

            fetch(functionUrl)
                .then(response => {
                    if (!response.ok) {
                        console.error('Network response was not ok:', response)
                    }
                    return response.json();
                })
                .then(data => {
                    this.photoUrls = data;
                })
                .catch(error => {
                    console.error('Error fetching photo URLs:', error);
                });
        },
        fetchMetadata() {
            const metadataUrl = `https://storage.googleapis.com/restoration-ios.appspot.com/coral-gardeners/${this.path}/model_medium.json`;

            fetch(metadataUrl)
                .then(response => {
                    if (!response.ok) {
                        console.error('Network response was not ok:', response)
                    }
                    return response.json();
                })
                .then(data => {
                    this.metadata = data;
                })
                .catch(error => {
                    console.error('Error fetching metadata:', error);
                });

        },
        fetchFragments() {
            const branchId = this.$store.state.cell.branch;
            const outplantCellId = this.$store.state.cell.cell;
            const fragmentsRef = collection(db, "Orgs", "coral-gardeners", "Branches", branchId, "Fragments");
            getDocs(fragmentsRef)
                .then(querySnapshot => {
                    // Further filter documents by 'outplantInfo.outplantCellID'
                    this.fragments = querySnapshot.docs
                        .filter(doc => {
                            const data = doc.data();
                            return data.outplantInfo !== undefined && data.outplantInfo.outplantCellID === outplantCellId;
                        })
                        .map(doc => ({ id: doc.id, ...doc.data() }));
                })
                .catch(error => {
                    console.error('Error fetching fragments:', error);
                });
        },
        setActiveTab(tab) {
            if (this.activeTab === tab) {
                this.isExpanded = !this.isExpanded;
                // If the sidebar is being closed, reset the activeTab to null
                if (!this.isExpanded) {
                    this.activeTab = null;
                }
            } else {
                // If a different tab is clicked, make it active and expand the sidebar
                this.activeTab = tab;
                this.isExpanded = true;
            }
        },
        cleanUp() {
            this.photoUrls = [];
            this.metadata = {};
            this.fragments = {};
            this.isExpanded = false;
        },
    },
};
</script>

<style scoped>
/* Add transition effects and ensure vertical text alignment */
[transition="expand"] {
    transition: width .3s ease;
}

.text-xs {
    writing-mode: vertical-lr;
    text-orientation: mixed;
}
</style>
