<template>
    <div v-if="!editMode">
        {{ displayValue }}
    </div>
    <div v-else class="radio-buttons flex">
        <label class="radio-label flex items-center">
            <input type="radio" :value="true" v-model="internalValue" class="hidden-radio">
            <span class="custom-radio"></span> Yes
        </label>
        <label class="radio-label flex items-center">
            <input type="radio" :value="false" v-model="internalValue" class="hidden-radio">
            <span class="custom-radio"></span> No
        </label>
    </div>
</template>

<script>
export default {
    props: ['value', 'editMode'],
    computed: {
        displayValue() {
            if (this.value === null) return '-';
            return this.value ? 'Yes' : 'No';
        },
        internalValue: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit('update:value', newValue);
            },
        },
    },
};
</script>

<style scoped>
.radio-buttons {
    display: flex;
    gap: 10px;
}

.radio-label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.hidden-radio {
    display: none;
}

.custom-radio {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: black;
    border: 1px solid #27BDF4;
    margin-right: 4px;
    position: relative;
}

.hidden-radio:checked+.custom-radio {
    background-color: #27BDF4;
}

.custom-radio::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: black;
    transition: transform 0.3s ease;
}

.hidden-radio:checked+.custom-radio::after {
    transform: translate(-50%, -50%) scale(1);
}</style>
