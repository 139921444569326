<template>
    <header class="p-3 bg-black flex items-center justify-between">
        <!-- Left Side -->
        <div class="flex items-center">
            <!-- Company Logo -->
            <img src="@/assets/images/cg.png" alt="Company Logo" class="h-6 w-6 mr-1 ml-1">
            <span class="text-white">Reef<span class="font-bold">OS</span></span>
            <span class="text-primary font-bold ml-2">PHOTOGRAMMETRY</span>
        </div>

        <!-- Right Side -->
        <div class="relative">
            <button @click="toggleDropdown" class="text-white focus:outline-none">
                <span class="mdi mdi-dots-horizontal-circle-outline h-6 w-6 text-2xl"></span>
            </button>
            <div v-if="dropdownOpen"
                class="absolute top-full origin-top-right right-0 mt-2 w-48 border rounded shadow-lg z-20">
                <!-- Branches Dropdown -->
                <div class="border-b">
                    <DropdownHeader v-model="selectedBranch" :options="branches" placeholder="Select a Branch"
                        @input="confirmChange('branch', false)" option-label="name" option-value="id" />
                </div>
                <!-- OutplantSites Dropdown -->
                <div v-if="selectedBranch" class="border-b">
                    <DropdownHeaderSites v-model="selectedSite" :options="sites" placeholder="Site"
                        @input="confirmChange('site', false)" option-label="name" option-value="id" />
                </div>
                <!-- OutplantCells Dropdown -->
                <div v-if="selectedSite" class="border-b">
                    <DropdownHeaderCells v-model="selectedCell" :options="cells" placeholder="Cell"
                        @input="confirmChange('cell', false)" option-label="name" option-value="id" />
                </div>
                <!-- Logs Dropdown -->
                <div v-if="selectedCell">
                    <DropdownHeaderLogs v-model="selectedLog" :options="logs" placeholder="Log"
                        @input="confirmChange('log', false)" />
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { doc, getDoc, getDocs, collection } from "firebase/firestore";
import { db } from '@/main.js';
import { EventBus } from '@/event-bus.js';
import DropdownHeader from '@/components/utils/DropdownHeader.vue';
import DropdownHeaderSites from '@/components/utils/DropdownHeaderSites.vue';
import DropdownHeaderCells from '@/components/utils/DropdownHeaderCells.vue';
import DropdownHeaderLogs from '@/components/utils/DropdownHeaderLogs.vue';

export default {
    name: 'HeaderMobile',
    components: {
        DropdownHeader,
        DropdownHeaderSites,
        DropdownHeaderCells,
        DropdownHeaderLogs,
    },
    props: {
        branchId: String,
        siteId: String,
        cellId: String,
        logIndex: String
    },
    data() {
        return {
            branches: [],
            sites: [],
            cells: [],
            logs: [],
            selectedBranch: '',
            selectedSite: '',
            selectedCell: '',
            selectedLog: '',
            dropdownOpen: false,
            previousSelection: {
                branch: '',
                site: '',
                cell: '',
                log: '',
            },
        };
    },
    mounted() {
        this.fetchBranches().then(() => {
            if (this.branchId) {
                this.selectedBranch = this.branchId;
                this.onBranchChange(true);
            }
        });
    },
    computed: {
        log_index() {
            return this.$store.state.log_index;
        },
    },
    watch: {
        log_index() {
            this.selectedLog = this.log_index;
        },
    },
    methods: {
        toggleDropdown() {
            this.dropdownOpen = !this.dropdownOpen;
        },
        async fetchBranches() {
            this.branches = [];
            this.sites = [];
            this.cells = [];
            this.logs = [];
            this.selectedBranch = '';
            this.selectedSite = '';
            this.selectedCell = '';
            this.selectedLog = '';
            const querySnapshot = await getDocs(collection(db, "Orgs", "coral-gardeners", "Branches"));
            this.branches = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        },
        async fetchSites(branchId) {
            this.sites = [];
            this.cells = [];
            this.logs = [];
            this.selectedSite = '';
            this.selectedCell = '';
            this.selectedLog = '';

            const outplantSitesRef = collection(db, "Orgs", "coral-gardeners", "Branches", branchId, "OutplantSites");
            const surveySitesRef = collection(db, "Orgs", "coral-gardeners", "Branches", branchId, "SurveySites");

            const [outplantSitesSnapshot, surveySitesSnapshot] = await Promise.all([
                getDocs(outplantSitesRef),
                getDocs(surveySitesRef)
            ]);

            const outplantSites = outplantSitesSnapshot.docs.map(outplantDoc => ({
                id: outplantDoc.id,
                ...outplantDoc.data(),
                type: 'outplant'
            }));

            const surveySites = await Promise.all(surveySitesSnapshot.docs.map(async surveyDoc => {
                const siteData = surveyDoc.data();
                let referenceSiteName = '';

                if (siteData.referenceSite) {
                    const referenceSiteDoc = await getDoc(doc(db, "Orgs", "coral-gardeners", "Branches", branchId, "OutplantSites", siteData.referenceSite));
                    if (referenceSiteDoc.exists()) {
                        referenceSiteName = referenceSiteDoc.data().name;
                    }
                }

                return {
                    id: surveyDoc.id,
                    ...siteData,
                    type: 'survey',
                    referenceSiteName: referenceSiteName
                };
            }));

            this.sites = [...outplantSites, ...surveySites];
        },
        async fetchCells(branchId, siteId, siteType) {
            this.cells = [];
            this.logs = [];
            this.selectedCell = '';
            this.selectedLog = '';
            const cellsRef = collection(db, "Orgs", "coral-gardeners", "Branches", branchId, `${siteType === 'outplant' ? 'OutplantSites' : 'SurveySites'}`, siteId, `${siteType === 'outplant' ? 'OutplantCells' : 'SurveyCells'}`);
            const querySnapshot = await getDocs(cellsRef);

            this.cells = await Promise.all(querySnapshot.docs
                .filter(doc => doc.data().healths !== undefined)
                .map(async doc => {
                    const cellData = doc.data();
                    const healths = cellData.healths;
                    let hasUnannotated = false;

                    if (healths && healths.some(log => log.modelAnnotated !== true)) {
                        hasUnannotated = true;
                    }

                    return { id: doc.id, ...cellData, hasUnannotated };
                })
            );
            // Sort cells by name
            this.cells.sort((a, b) => {
                const aName = a.name;
                const bName = b.name;
                const aNum = parseInt(aName, 10);
                const bNum = parseInt(bName, 10);

                if (!isNaN(aNum) && !isNaN(bNum)) {
                    return aNum - bNum;
                } else {
                    return aName.localeCompare(bName);
                }
            });
        },
        async fetchLogs(branchId, siteId, cellId, siteType) {
            this.logs = [];
            this.selectedLog = '';
            const logsRef = doc(db, "Orgs", "coral-gardeners", "Branches", branchId, `${siteType === 'outplant' ? 'OutplantSites' : 'SurveySites'}`, siteId, `${siteType === 'outplant' ? 'OutplantCells' : 'SurveyCells'}`, cellId);
            const docSnapshot = await getDoc(logsRef);
            if (docSnapshot.exists()) {
                const healths = docSnapshot.data().healths;
                this.logs = healths.map((value, index) => ({
                    ...value,
                    index: index,
                    modelAnnotated: value.modelAnnotated || false,
                    geoLocation: docSnapshot.data().geoLocation,
                }));
            }
            const payload = {
                branch: this.selectedBranch,
                site: this.selectedSite,
                cell: this.selectedCell,
                name: docSnapshot.data().name,
                type: docSnapshot.data().type,
                loc: docSnapshot.data().geoLocation,
                createdAt: docSnapshot.data().created,
                siteType: siteType,
            }
            this.$store.dispatch('setCell', payload);
            this.$store.dispatch('setLogs', this.logs);
        },
        confirmChange(type, initial) {
            if (this.$store.state.modified) {
                this.$emit('showPopup', true);
                this.changeType = type;
                this.initial = initial;
            } else {
                this.handleChange(type, initial);
            }
        },
        handleChange(type, initial) {
            switch (type) {
                case 'branch':
                    this.onBranchChange(initial);
                    break;
                case 'site':
                    this.onSiteChange(initial);
                    break;
                case 'cell':
                    this.onCellChange(initial);
                    break;
                case 'log':
                    this.onLogChange(initial);
                    break;
            }
        },
        handleConfirm() {
            this.$emit('showPopup', false);
            this.handleChange(this.changeType, this.initial);
        },
        handleCancel() {
            this.$emit('showPopup', false);
            this.selectedBranch = this.previousSelection.branch;
            this.selectedSite = this.previousSelection.site;
            this.selectedCell = this.previousSelection.cell;
            this.selectedLog = this.previousSelection.log;
        },
        async onBranchChange(initial) {
            EventBus.$emit('reset-model');
            await this.fetchSites(this.selectedBranch);
            if (initial && (this.siteId || this.userSiteId)) {
                this.userSiteId ? this.selectedSite = this.userSiteId : this.selectedSite = this.siteId;
                this.selectedSiteType = this.siteType;
                this.onSiteChange(true);
            }
            if (!initial) {
                this.$router.replace({ path: `/${this.selectedBranch}` });
            }
            this.previousSelection.branch = this.selectedBranch;
        },
        async onSiteChange(initial) {
            EventBus.$emit('reset-model');
            this.selectedSiteType = this.sites.find(site => site.id === this.selectedSite).type;
            await this.fetchCells(this.selectedBranch, this.selectedSite, this.selectedSiteType);
            if (initial && (this.cellId || this.userCellId)) {
                this.userCellId ? this.selectedCell = this.userCellId : this.selectedCell = this.cellId;
                this.onCellChange(true);
            }
            if (!initial) {
                this.$router.replace({ path: `/${this.selectedBranch}/${this.selectedSiteType}/${this.selectedSite}` });
            }
            this.previousSelection.site = this.selectedSite;
        },
        async onCellChange(initial) {
            EventBus.$emit('reset-model');
            await this.fetchLogs(this.selectedBranch, this.selectedSite, this.selectedCell, this.selectedSiteType);
            if (initial && this.logIndex) {
                this.selectedLog =  this.logIndex;
                this.onLogChange(true);
            }
            if (!initial) {
                this.$router.replace({ path: `/${this.selectedBranch}/${this.selectedSiteType}/${this.selectedSite}/${this.selectedCell}` });
            }
            this.previousSelection.cell = this.selectedCell;
        },
        onLogChange(initial) {
            const newPath = `/${this.selectedBranch}/${this.selectedSiteType}/${this.selectedSite}/${this.selectedCell}/${this.selectedLog}`;
            if (this.$route.path !== newPath) {
                this.$router.replace({ path: newPath });
            }
            this.$store.dispatch('modelSelected', { index: this.selectedLog, modelType: this.selectedSiteType === 'outplant' ? 'mesh' : 'ortho' });
            EventBus.$emit('model-selected');
            this.previousSelection.log = this.selectedLog;
            this.dropdownOpen = false;
        },
    },
}
</script>

<style scoped>
/* Add styles for the mobile header if necessary */
</style>
