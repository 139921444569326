<template>
    <div>
        <div v-if="$store.state.cell">
            <div class="bg-neutral-900 text-white text-sm p-3 m-1 rounded-sm">
                <div class="flex justify-between items-center pb-1 mb-4 border-b border-gray-600">
                    <div class="font-bold uppercase">{{ $store.state.cell.type}}</div>
                    <div class="flex items-center">
                        <div class="text-white px-2 text-sm flex items-center">
                            <span class="text-xs text-gray-400 mr-2">id</span><span class="mr-2">{{
                                $store.state.cell.name }}</span>
                            <div class="border-r border-gray-600 h-6 mx-2"></div>
                        </div>
                        <div @click="editModeCell = true"
                            class="text-lg cursor-pointer hover:bg-neutral-600 px-1 rounded">
                            <span class="mdi mdi-pencil-box-outline text-white text-lg"></span>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="font-bold text-xs uppercase mb-2">Monitoring</div>
                    <!-- Editable Fields -->
                    <template v-if="editModeCell">
                        <div>
                            <div class="flex justify-between items-center py-1">
                                <span>Coral Cover</span>
                                <span class="text-white">{{ tempCellMonitoring.cover }}%</span>
                            </div>
                            <div>
                                <input type="range" v-model="tempCellMonitoring.cover" min="0" max="100"
                                    class="range-slider">
                            </div>
                        </div>
                        <div>
                            <div class="flex justify-between items-center py-1">
                                <span>Survival</span>
                                <span class="text-white">{{ tempCellMonitoring.survival }}%</span>
                            </div>
                            <div>
                                <input type="range" v-model="tempCellMonitoring.survival" min="0" max="100"
                                    class="range-slider">
                            </div>
                        </div>
                        <div>
                            <div class="flex justify-between items-center py-1">
                                <span>Bleaching</span>
                                <span class="text-white">{{ tempCellMonitoring.bleach }}</span>
                            </div>
                            <div class="relative">
                                <input type="range" v-model="tempCellMonitoring.bleach" min="1" max="6" step="1"
                                    class="range-slider w-full">
                                <div class="flex justify-between px-1 mt-1">
                                    <span v-for="n in 6" :key="n" class="text-xs text-gray-400">{{ n }}</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="flex justify-between items-center py-1">
                                <span>Health</span>
                                <span class="text-white">{{ tempCellMonitoring.health }}</span>
                            </div>
                            <div class="relative">
                                <input type="range" v-model="tempCellMonitoring.health" min="1" max="6" step="1"
                                    class="range-slider w-full">
                                <div class="flex justify-between px-1 mt-1">
                                    <span v-for="n in 6" :key="n" class="text-xs text-gray-400">{{ n }}</span>
                                </div>
                            </div>
                        </div>
                        <!-- Cancel and Save Buttons -->
                        <div class="flex justify-end space-x-2 mt-4">
                            <button @click="cancelCellEdit"
                                class="bg-neutral-700 hover:bg-neutral-600 text-white py-1 px-3 rounded">Cancel</button>
                            <button @click="saveCellEdit" class="bg-primary text-white py-1 px-3 rounded">Save</button>
                        </div>
                    </template>
                    <!-- Display Fields  -->
                    <template v-else>
                        <div class="flex justify-between">
                            <span>Coral Cover</span><span>{{ cellMonitoring.cover == null ? '-' : cellMonitoring.cover
                                }}%</span>
                        </div>
                        <div class="flex justify-between">
                            <span>Survival</span><span>{{ cellMonitoring.survival == null ? '-' :
                                cellMonitoring.survival }}%</span>
                        </div>
                        <div class="flex justify-between">
                            <span>Bleaching</span><span>{{ cellMonitoring.bleach == null ? '-' : cellMonitoring.bleach }}</span>
                        </div>
                        <div class="flex justify-between">
                            <span>Health</span><span>{{ cellMonitoring.health == null ? '-' : cellMonitoring.health }}</span>
                        </div>
                    </template>
                </div>
            </div>
        </div>

        <!-- Coral Details -->
        <div v-if="$store.state.boxes.length > 0 " class="bg-neutral-900 text-white text-sm p-3 m-1 rounded-sm">
            <div class="flex justify-between items-center pb-2 mb-2 border-b border-gray-600">
                <div class="font-bold uppercase">Corals
                    <span class="bg-neutral-700 p-1 rounded-full font-normal ml-1">{{ $store.state.boxes.length }}</span>
                </div>
                <div v-if="annotateMode" @click="toggleLabels"
                    class="text-lg cursor-pointer hover:bg-neutral-600 px-1 rounded ml-auto">
                    <span :class="{'mdi mdi-label': showLabels, 'mdi mdi-label-off': !showLabels}"
                        class="text-white text-lg">
                    </span>
                </div>
                <div @click="toggleCoralsSection" class="text-lg cursor-pointer hover:bg-neutral-600 px-1 rounded">
                    <span
                        :class="{ 'mdi mdi-chevron-down': isCoralsExpanded, 'mdi mdi-chevron-left': !isCoralsExpanded }"
                        class="text-white text-lg"></span>
                </div>
            </div>
            <div v-if="isCoralsExpanded">
                <div v-for="(box, index) in $store.state.boxes" :key="box.id"
                    class="p-2 rounded hover:bg-neutral-800 cursor-pointer">
                    <div class="flex justify-between" @click.stop="selectBox(box.id)" @mouseover="highlightBox(box.id)"
                        @mouseleave="unhighlightBox(box.id)">
                        <div @click.stop="toggleCoralDetails(box.id)" class="flex items-center">
                            <span class="mdi mdi-chevron-right"
                                :class="{ 'rotate-90': expandedCoralId === box.id }"></span>
                            <div class="ml-2">
                                <span class="text-xs text-gray-400 mr-2">id</span>
                                <span class="font-semibold">{{ box.id }}</span>
                            </div>
                        </div>
                        <div>
                            <div v-if="expandedCoralId === box.id" class="flex items-center">
                                <div v-if="$store.state.mode == 'annotate'" class="border-r border-gray-600 h-6 mx-2">
                                </div>
                                <div v-if="$store.state.mode == 'annotate'" @click.stop="hideCoral(index)"
                                    class="text-lg cursor-pointer hover:bg-neutral-600 px-1 rounded">
                                    <span v-if="boxes[index].visible" class="mdi mdi-eye text-white text-lg"></span>
                                    <span v-else class="mdi mdi-eye-off text-white text-lg"></span>
                                </div>
                                <div v-if="$store.state.mode == 'annotate'" @click.stop="deleteBox"
                                    class="text-lg cursor-pointer hover:bg-neutral-600 px-1 rounded">
                                    <span class="mdi mdi-delete text-white text-lg"></span>
                                </div>
                                <div v-if="$store.state.mode == 'annotate'" @click.stop="editCoral(index)"
                                    class="text-lg cursor-pointer hover:bg-neutral-600 px-1 rounded">
                                    <span class="mdi mdi-pencil-box-outline text-white text-lg"></span>
                                </div>
                            </div>
                            <div v-else>
                                <span class="mr-1 capitalize">{{ box.genus || 'Genus' }}</span>
                                <span>{{ box.species || 'sp.' }}</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="expandedCoralId === box.id" class="border-t border-b pb-2 border-gray-600 mt-1">
                        <!-- Display Fields -->
                        <template v-if="editIndex === null">
                            <div class="mt-2">
                                <span class="mr-1 capitalize">{{ box.genus || 'Genus' }}</span>
                                <span>{{ box.species || 'sp.' }}</span>
                            </div>
                            <div class="font-bold text-xs uppercase mb-2 mt-2">Monitoring</div>
                            <div class="flex mt-1 justify-between">
                                <span>Health</span><span class="font-semibold">{{ box.health == null ? '-' : box.health
                                    }}</span>
                            </div>
                            <div class="flex mt-1 justify-between">
                                <span>Bleaching</span><span class="font-semibold">{{ box.bleaching == null ? '-' :
                                    box.bleaching }}</span>
                            </div>
                            <div class="flex mt-1 justify-between">
                                <span>Predation</span><span class="font-semibold">{{ box.predation == null ? '-' :
                                    (box.predation ? 'Yes' : 'No') }}</span>
                            </div>
                            <div class="flex mt-1 justify-between">
                                <span>Disease</span><span class="font-semibold">{{ box.disease == null ? '-' :
                                    (box.disease
                                    ? 'Yes' : 'No') }}</span>
                            </div>
                        </template>

                        <!-- Editable Fields -->
                        <template v-else>
                            <!-- Species Dropdown When Editing -->
                            <div class="flex justify-between items-center py-1 w-full">
                                <span>Genus</span>
                                <div class="flex justify-end w-32">
                                    <DropdownFilter v-model="tempCoralMonitoring.genus" :options="genusList"
                                        icon="mdi-fish" class="capitalize" />
                                </div>
                            </div>
                            <div class="flex justify-between items-center py-1">
                                <span v-if="tempCoralMonitoring.genus === 'Add new'">Add:</span>
                                <input v-if="tempCoralMonitoring.genus === 'Add new'" type="text" v-model="customGenus"
                                    placeholder="Add Genus"
                                    class="bg-neutral-800 text-white rounded border border-gray-600 p-1">
                            </div>
                            <div class="flex justify-between items-center py-1 w-full">
                                <span>Species</span>
                                <div class="flex justify-end w-32">
                                    <DropdownFilter v-model="tempCoralMonitoring.species" :options="filteredSpeciesList"
                                        icon="mdi-fish" />
                                </div>
                            </div>
                            <div class="flex justify-between items-center py-1 w-full">
                                <span v-if="tempCoralMonitoring.species === 'Add new'">Add:</span>
                                <input v-if="tempCoralMonitoring.species === 'Add new'" type="text"
                                    v-model="customSpecies" placeholder="Add species"
                                    class="bg-neutral-800 text-white rounded border border-gray-600 p-1">
                            </div>
                            <div class="flex justify-between items-center py-1 w-full">
                                <span>Health</span>
                                <div class="flex justify-end w-24">
                                    <Increment v-model="tempCoralMonitoring.health" :min="1" :max="6" />
                                </div>
                            </div>
                            <div class="flex justify-between items-center py-1">
                                <span>Bleaching</span>
                                <div class="flex justify-end w-24">
                                    <Increment v-model="tempCoralMonitoring.bleaching" :min="1" :max="6" />
                                </div>
                            </div>
                            <div class="flex justify-between items-center py-1 w-full">
                                <span>Predation</span>
                                <div class="flex justify-end w-24">
                                    <YesNo :value="tempCoralMonitoring.predation" :editMode="editIndex !== null"
                                        @update:value="value => tempCoralMonitoring.predation = value" />
                                </div>
                            </div>
                            <div class="flex justify-between items-center py-1 w-full">
                                <span>Disease</span>
                                <div class="flex justify-end w-24">
                                    <YesNo :value="tempCoralMonitoring.disease" :editMode="editIndex !== null"
                                        @update:value="value => tempCoralMonitoring.disease = value" />
                                </div>
                            </div>
                        </template>

                        <!-- Metrics -->
                        <div class="font-bold text-xs uppercase mb-2 mt-2">Metrics</div>
                        <div class="bg-neutral-700 rounded p-2">
                            <div class="flex mt-1 justify-between">
                                <span>L &times; W &times; H</span><span class="font-semibold">{{
                                    parseFloat(box.size.length).toFixed(1) }} x {{ parseFloat(box.size.width).toFixed(1)
                                    }}
                                    x {{ parseFloat(box.size.height).toFixed(1) }} cm</span>
                            </div>
                            <div class="flex mt-1 justify-between">
                                <span>EVI</span><span class="font-semibold">{{ parseFloat(box.evi).toFixed(1) }}
                                    cm<sup>3</sup></span>
                            </div>
                            <div v-if="Object.keys(box.metrics).length > 0">
                                <div class="flex mt-1 justify-between">
                                    <span>Rugosity</span><span class="font-semibold">{{
                                        parseFloat(box.metrics.rugosity).toFixed(2) }}</span>
                                </div>
                                <div class="flex mt-1 justify-between">
                                    <span>Surface Area</span><span class="font-semibold">{{
                                        parseFloat(box.metrics.coral_surface_area).toFixed(1) }} cm<sup>2</sup></span>
                                </div>
                            </div>
                        </div>
                        <!-- Cancel and Save Buttons -->
                        <div v-if="editIndex !== null" class="flex justify-end space-x-2 mt-4">
                            <button @click="cancelCoralEdit"
                                class="bg-neutral-700 hover:bg-neutral-600 text-white py-1 px-3 rounded">Cancel</button>
                            <button @click="saveCoralEdit" :disabled="!canSave" class="py-1 px-3 rounded"
                                :class="{'bg-primary text-white': canSave, 'bg-neutral-600 text-gray-400': !canSave}">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as THREE from 'three'
import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { ConvexGeometry } from 'three/examples/jsm/geometries/ConvexGeometry.js';
import { mergeVertices } from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import { EventBus } from '@/event-bus.js';
import { db } from '@/main.js'
import { doc, getDoc, getDocs, updateDoc, collection } from "firebase/firestore";
import Checkbox from '@/components/utils/Checkbox.vue';
import Increment from '@/components/utils/Increment.vue';
import Dropdown from '@/components/utils/Dropdown.vue';
import DropdownFilter from '@/components/utils/DropdownFilter.vue';
import YesNo from '@/components/utils/YesNo.vue';
import RendererService from '@/assets/js/RendererService';

export default {
    name: 'Annotate',
    props: ['scene'],
    components: {
        Checkbox,
        Increment,
        Dropdown,
        DropdownFilter,
        YesNo,
    },
    data() {
        return {
            boxes: [], // Three.js mesh objects for each annotation
            addBoxMode: false,
            previewBox: null,
            raycaster: new THREE.Raycaster(),
            mouse: new THREE.Vector2(),
            isCoralsExpanded: true,
            editModeCell: false,
            cellMonitoring: {
                cover: null,
                survival: null,
                bleach: null,
                health: null, // Added health property
            },
            tempCellMonitoring: {
                cover: null,
                survival: null,
                bleach: null,
                health: null, // Added health property
            },
            expandedCoralId: null,
            editIndex: null,
            tempCoralMonitoring: {
                    health: null,
                    bleaching: null,
                    predation: null,
                    disease: null,
                    genus: '',
                    species: '',
            },
            genusList: ['Add new'],
            speciesList: {},
            customGenus: null,
            customSpecies: null,
            showLabels: true,
            showPopup: false,
            animationFrameId: null,
        }
    },
    mounted() {
        window.addEventListener('mousemove', this.onMouseMove);
        window.addEventListener('click', this.onMouseClick);
        window.addEventListener('resize', this.onResize, false);
        EventBus.$on('model-loaded', this.loadAnnotations);
        EventBus.$on('reset-model', this.resetModel);
        EventBus.$on('analyze-coral', this.extract);
        EventBus.$on('delete-box', this.deleteBox);
        EventBus.$on('toggle-addbox-mode', this.toggleAddBoxMode);
        
        this.boxSelectedHandler = (id) => {
            this.expandedCoralId = id;
        };
        EventBus.$on('box-selected', this.boxSelectedHandler);
        this.importAnnotationsHandler = (index) => {
            this.importAnnotations(index);
        };
        EventBus.$on('import-annotations', this.importAnnotationsHandler);

        this.initAnnotate();
    },
    beforeDestroy() {
        window.removeEventListener('mousemove', this.onMouseMove);
        window.removeEventListener('click', this.onMouseClick);
        window.removeEventListener('resize', this.onResize, false);
        EventBus.$off('model-loaded', this.loadAnnotations);
        EventBus.$off('reset-model', this.resetModel);
        EventBus.$off('analyze-coral', this.extract);
        EventBus.$off('delete-box', this.deleteBox);
        EventBus.$off('toggle-addbox-mode', this.toggleAddBoxMode);
        EventBus.$off('box-selected', this.boxSelectedHandler);
        EventBus.$off('import-annotations', this.importAnnotationsHandler);
    },
    computed: {
        annotateMode() {
            return this.$store.state.mode == 'annotate';
        },
        canSave() {
            for (const [key, value] of Object.entries(this.tempCoralMonitoring)) {
                if (key === 'metrics') continue;
                if (value === null || value === undefined || value === '') {
                    return false;
                }
            }
            return true;
        },
        canShowLabels() {
            return this.annotateMode && this.showLabels;
        },
    },
    watch: {
        annotateMode() {
            if (!this.annotateMode) {
                this.$store.dispatch('selectBox', null);
                this.expandedCoralId = null;
            }
            this.boxes.forEach(box => box.visible = this.$store.state.mode == 'annotate');
            const hulls = this.scene.children.filter(child => child.userData.type === 'hull');
            hulls.forEach(hull => hull.visible = this.$store.state.mode == 'annotate');
            const corals = this.scene.children.filter(child => child.userData.type === 'coral');
            corals.forEach(coral => coral.visible = this.$store.state.mode == 'annotate');
            this.updateLabelVisibility();
            this.isCoralsExpanded = this.annotateMode;
            this.$store.state.transformActive = this.annotateMode;
        },
        'tempCoralMonitoring.genus'(newGenus) {
            this.filteredSpeciesList = this.speciesList[newGenus] || ['Add new'];
        },
    },
    methods: {
        initAnnotate() {
            this.getGenusList();
            this.updateLabelVisibility();
        },
        async getGenusList() {
            try {
                const generaSnapshot = await getDocs(collection(db, "CoralGenera"));
                const genusList = ['Add new'];
                const speciesList = {};

                generaSnapshot.forEach(doc => {
                    const data = doc.data();
                    const genusName = data.name;
                    const speciesArray = ['Add new', ...data.species].sort();

                    genusList.push(genusName);
                    speciesList[genusName] = speciesArray;
                });

                this.genusList = genusList.sort();
                this.speciesList = speciesList;
            } catch (error) {
                console.error('Error fetching data from Firestore:', error);
            }
        },
        async loadAnnotations() {

            // Init label renderer
            this.renderer = RendererService.getRenderer();
            this.camera = RendererService.getCamera();
            this.renderContainer = this.renderer.domElement.parentNode;
            this.labelRenderer = new CSS2DRenderer();
            this.labelRenderer.setSize(this.renderContainer.clientWidth, this.renderContainer.clientHeight);
            this.labelRenderer.domElement.style.position = 'absolute';
            this.labelRenderer.domElement.style.top = '0px';
            this.labelRenderer.domElement.style.left = '0px';
            this.labelRenderer.domElement.style.pointerEvents = 'none';
            this.renderContainer.appendChild(this.labelRenderer.domElement);


            const animate = () => {
                this.animationFrameId = requestAnimationFrame(animate);
                this.labelRenderer.render(this.scene, this.camera);
            };
            animate();

            this.boxes = []; // Clear existing annotations
            const annotations = this.$store.state.logs[this.$store.state.log_index].annotations;
            if (annotations) {
                annotations.forEach(annotation => {
                    this.addBox(annotation, false)
                });
            }
            this.cellMonitoring = { ...this.$store.state.logs[this.$store.state.log_index].cellMonitoring };
            this.tempCellMonitoring = this.cellMonitoring;
        },
        importAnnotations(index) {
            // Clear existing annotations
            this.$store.dispatch('setModified', true);
            this.scene.children.forEach(child => {
                if (child.userData && (child.userData.type === 'annotation' || child.userData.type === 'hull' || child.userData.type === 'coral')) {
                    this.scene.remove(child)
                }
            });
            this.boxes.forEach(box => {
                const label = box.children.find(child => child instanceof CSS2DObject);
                if (label) {
                    box.remove(label);
                }
                this.scene.remove(box);
            });
            this.boxes = [];
            this.$store.dispatch('clearAnnotations');

            // Import annotations from selected index
            const annotations = this.$store.state.logs[index].annotations;
            if (annotations) {
                annotations.forEach(annotation => {
                    this.addBox(annotation, false)
                });
            }
            if (!this.annotateMode) this.$store.dispatch('toggleMode', 'annotate');
        },
        toggleCoralsSection() {
            this.isCoralsExpanded = !this.isCoralsExpanded;
        },
        hideCoral(index) {
            this.boxes[index].visible = !this.boxes[index].visible;
            const hullMesh = this.scene.children.find(child =>
                child.userData.id === this.boxes[index].userData.id && child.userData.type === 'hull')
            if (hullMesh) {
                hullMesh.visible = this.boxes[index].visible;
            }
            const coralMesh = this.scene.children.find(child =>
                child.userData.id === this.boxes[index].userData.id && child.userData.type === 'coral')
            if (coralMesh) {
                coralMesh.visible = this.boxes[index].visible;
            }
            const ellipsoid = this.scene.children.find(child =>
                child.userData.id === this.boxes[index].userData.id && child.userData.type === 'ellipsoid')
            if (ellipsoid) {
                ellipsoid.visible = this.boxes[index].visible;
            }
            this.$store.dispatch('selectBox', null);
        },
        editCoral(index) {
            this.editIndex = index;
            this.tempCoralMonitoring = { ...this.$store.state.boxes[index] };
        },
        saveCoralEdit() {
            if (!this.canSave) return;

            this.$store.dispatch('setModified', true);
            if (this.customGenus) {
                if (!this.genusList.includes(this.customGenus)) {
                    this.genusList.push(this.customGenus);
                    this.speciesList[this.customGenus] = ['Add new'];
                }
                this.tempCoralMonitoring.genus = this.customGenus;
                this.customGenus = null;
            }

            if (this.customSpecies) {
                const speciesList = this.speciesList[this.tempCoralMonitoring.genus];
                if (!speciesList.includes(this.customSpecies)) {
                    speciesList.push(this.customSpecies);
                }
                this.tempCoralMonitoring.species = this.customSpecies;
                this.customSpecies = null;
            }
            this.$store.state.boxes[this.editIndex] = { ...this.tempCoralMonitoring };
            this.editIndex = null;
            this.tempCoralMonitoring.genus = '';
            this.tempCoralMonitoring.species = '';
            this.$store.dispatch('selectBox', null);
        },
        cancelCoralEdit() { 
            this.editIndex = null;
        },
        saveCellEdit() {
            this.$store.dispatch('setModified', true);
            this.cellMonitoring = { ...this.tempCellMonitoring };
            this.$store.dispatch('updateCellMonitoring', this.cellMonitoring);
            this.editModeCell = false;
        },
        cancelCellEdit() {
            this.editModeCell = false;
        },
        toggleCoralDetails(id) {
            this.expandedCoralId = this.expandedCoralId === id ? null : id;
            if (this.$store.state.mode != 'annotate') return;
            if (!this.expandedCoralId) {
                this.$store.dispatch('selectBox', null);
            }
        },
        resetModel() {
            const objects = []
            this.scene.children.forEach(child => {
                if (child.userData && (child.userData.type === 'annotation' || child.userData.type === 'hull' || child.userData.type === 'coral')) {
                    objects.push(child);
                }
            });
            objects.forEach(object => this.scene.remove(object));
            
            this.boxes.forEach(box => {
                const label = box.children.find(child => child instanceof CSS2DObject);
                if (label) {
                    box.remove(label);
                }
                this.scene.remove(box);
            });
            this.boxes = [];
            this.cellMonitoring = {
                cover: null,
                survival: null,
                bleach: null,
                health: null, // Added health property
            };
            this.tempCellMonitoring = {
                cover: null,
                survival: null,
                bleach: null,
                health: null, // Added health property
            };
            this.expandedCoralId = null;
            this.editIndex = null;
            this.tempCoralMonitoring = {
                health: null,
                bleaching: null,
                predation: null,
                disease: null,
                genus: null,
                species: null,
            };
            this.customSpecies = null;
            this.customGenus = null;
        },
        toggleAddBoxMode() {
            this.addBoxMode = !this.addBoxMode;
            if (this.addBoxMode) {
                this.createPreviewBox();
            } else {
                this.removePreviewBox();
            }
        },
        onMouseMove(event) {
            // Calculate mouse position in normalized device coordinates
            // (-1 to +1) for both components
            const div = document.getElementById('Perspective');
            const rect = div.getBoundingClientRect();
            this.mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
            this.mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

            if (this.addBoxMode && this.previewBox) {
                this.updatePreviewBox();
            }
        },
        onMouseClick() {
            if (this.addBoxMode && this.previewBox) {
                let maxId = 0;
                // Check IDs in boxes
                this.$store.state.boxes.forEach(box => {
                    if (box.id > maxId) {
                        maxId = box.id;
                    }
                });
                // Check IDs in logs
                this.$store.state.logs.forEach(log => {
                    if (log.annotations && log.annotations.length > 0) {
                        log.annotations.forEach(annotation => {
                            if (annotation.id > maxId) {
                                maxId = annotation.id;
                            }
                        });
                    }
                });
                const newId = maxId + 1;
                const annotation = {
                    position: this.previewBox.position,
                    size: { width: 15, height: 15, length: 15},
                    id: newId,
                }
                this.addBox(annotation)
                EventBus.$emit('box-added', this.previewBox.position); // for Viewport.vue to re-center on newly addded box
                this.toggleAddBoxMode();
                
            }
        },
        createPreviewBox() {
            const boxSize = 0.15; 
            const boxGeometry = new THREE.BoxGeometry(boxSize, boxSize, boxSize);
            const boxMaterial = new THREE.MeshBasicMaterial({
                color: 0x27BDF4, 
                transparent: true,
                opacity: 0.3,
            });
            this.previewBox = new THREE.Mesh(boxGeometry, boxMaterial);
            this.previewBox.position.set(0, 0.5, 0);
            const edges = new THREE.EdgesGeometry(this.previewBox.geometry);
            const lineMaterial = new THREE.LineBasicMaterial({
                color: 0x27BDF4,
                linewidth: 7,
            });
            const lineSegments = new THREE.LineSegments(edges, lineMaterial);
            this.previewBox.add(lineSegments);
            this.scene.add(this.previewBox);
        },
        removePreviewBox() {
            if (this.previewBox) {
                this.scene.remove(this.previewBox);
                this.previewBox = null;
            }
        },
        updatePreviewBox() {
            const perspectiveCamera = this.scene.getObjectByName('main');
            const model = this.scene.getObjectByName('model');
            this.raycaster.setFromCamera(this.mouse, perspectiveCamera);
            const intersects = this.raycaster.intersectObjects([model], true);
            if (intersects.length > 0) {
                const intersect = intersects[0];
                this.previewBox.position.copy(intersect.point)
            }
        },
        selectBox(id) {
            if (this.$store.state.mode != 'annotate') return;
            this.$store.dispatch('selectBox', id);
        },
        createBoxMesh(annotation) {
            const boxGeometry = new THREE.BoxGeometry(
                annotation.size.width / 100,
                annotation.size.height / 100,
                annotation.size.length / 100
            );
            const boxMaterial = new THREE.MeshBasicMaterial({
                color: 0x27BDF4, 
                transparent: true,
                opacity: 0.3,
            });
            const box = new THREE.Mesh(boxGeometry, boxMaterial);
            box.position.set(annotation.position.x, annotation.position.y, annotation.position.z);

            // Add edges to the box for visibility
            const edges = new THREE.EdgesGeometry(boxGeometry);
            const lineMaterial = new THREE.LineBasicMaterial({
                color: 0x27BDF4,
                linewidth: 7,
            });
            const lineSegments = new THREE.LineSegments(edges, lineMaterial);
            box.add(lineSegments);

            // Less transparent back face
            const zOffset = 0.001;
            const backFaceGeometry = new THREE.PlaneGeometry(annotation.size.width/100, annotation.size.height/100);
            const backFaceMaterial = new THREE.MeshBasicMaterial({
                color: 0x27BDF4,
                side: THREE.DoubleSide,
                transparent: true,
                opacity: 0.5 // More transparent than the box
            });
            const backFace = new THREE.Mesh(backFaceGeometry, backFaceMaterial);
            backFace.position.z = -annotation.size.length/100/2 - zOffset; // Position it at the center of the -Z face
            backFace.rotateY(Math.PI);
            box.add(backFace);

            // Assign metadata for later reference
            box.userData = {
                type: 'annotation',
                id: annotation.id,
                size: annotation.size,
            };

            return box;
        },
        addBox(annotation, selectAfterAdding = true) {
            const box = this.createBoxMesh(annotation);
            this.boxes.push(box);
            this.scene.add(box);
            box.visible = this.$store.state.mode == 'annotate';
            const evi = annotation.size.height * Math.PI * ((annotation.size.width + annotation.size.length) / 4) ** 2;
            
            this.$store.dispatch('addBox', {
                id: annotation.id,
                size: annotation.size,
                evi: annotation.evi || evi,
                position: {
                    x: box.position.x,
                    y: box.position.y,
                    z: box.position.z,
                },
                metrics: annotation.metrics || {},
                health: annotation.health,
                bleaching: annotation.bleaching,
                predation: annotation.predation,
                disease: annotation.disease,
                genus: annotation.genus,
                species: annotation.species,
            });

            this.addLabel(box, annotation.id);

            if (selectAfterAdding) {
                this.$store.dispatch('selectBox', annotation.id);
                this.expandedCoralId = annotation.id;
            }
        },
        addLabel(box, id) {
            const div = document.createElement('div');
            div.className = 'annotationLabel';
            div.innerText = `${id}`;
            const label = new CSS2DObject(div);

            // Position the label at the center of the box
            const center = new THREE.Vector3();
            box.geometry.computeBoundingBox();
            box.geometry.boundingBox.getCenter(center);
            label.position.copy(center);

            label.visible = this.canShowLabels;  // Set visibility based on showLabels state
            box.add(label);
        },
        toggleLabels() {
            this.showLabels = !this.showLabels;
            this.updateLabelVisibility();
        },
        updateLabelVisibility() {
            this.boxes.forEach(box => {
                const label = box.children.find(child => child instanceof CSS2DObject);
                if (label) {
                    label.visible = this.canShowLabels;
                }
            });
        },
        deleteBox() {
            this.$store.dispatch('setModified', true);
            const box = this.scene.children.find(child =>
                child.userData.id === this.$store.state.selectedBox && child.userData.type === 'annotation')
            const hullMesh = this.scene.children.find(child =>
                child.userData.id === this.$store.state.selectedBox && child.userData.type === 'hull')    
            const coralMesh = this.scene.children.find(child =>
                child.userData.id === this.$store.state.selectedBox && child.userData.type === 'coral')
            const ellipsoid = this.scene.children.find(child =>
                child.userData.id === this.$store.state.selectedBox && child.userData.type === 'ellipsoid')
            
            if (box) {
                const label = box.children.find(child => child instanceof CSS2DObject);
                if (label) {
                    box.remove(label);
                }
                this.boxes = this.boxes.filter(box => box.userData.id !== this.$store.state.selectedBox);
                this.$store.dispatch('selectBox', null);
                this.$store.dispatch('deleteBox', box.userData.id);
                this.scene.remove(box); 
            }
            if (hullMesh) {
                this.scene.remove(hullMesh);
            }
            if (coralMesh) {
                this.scene.remove(coralMesh);
            }
            if (ellipsoid) {
                this.scene.remove(ellipsoid);
            }
        },
        highlightBox(id) {
            const box = this.scene.children.find(child =>
                child.userData.id === id && child.userData.type === 'annotation')
            if (box) {
                box.material.opacity = 0.8;
            }
        },
        unhighlightBox(id) {
            const box = this.scene.children.find(child =>
                child.userData.id === id && child.userData.type === 'annotation')
            if (box) {
                box.material.opacity = 0.5;
            }
        },
        removePrevious() {
            // If there is already a convex hull, remove it
            const convexHull = this.scene.children.find(child =>
                child.userData.id === this.$store.state.selectedBox && child.userData.type === 'hull')
            if (convexHull) {
                this.scene.remove(convexHull);
            }
            const coralMesh = this.scene.children.find(child =>
                child.userData.id === this.$store.state.selectedBox && child.userData.type === 'coral')
            if (coralMesh) {
                this.scene.remove(coralMesh);
            }
            const ellipsoid = this.scene.children.find(child =>
                child.userData.id === this.$store.state.selectedBox && child.userData.type === 'ellipsoid')
            if (ellipsoid) {
                this.scene.remove(ellipsoid);
            }
        },
        extract() {
            this.$store.dispatch('setModified', true);
            this.removePrevious();

            const box = this.scene.children.find(child =>
                child.userData.id === this.$store.state.selectedBox && child.userData.type === 'annotation');
            box.material.opacity = 0.2;

            const mesh = this.scene.children.find(child => child.userData.type === 'model'); 
                
            if (box && mesh) {
                const boundingBox = new THREE.Box3().setFromObject(box);
                this.analyze(mesh, boundingBox);
            }
        },
        analyze(mesh, boundingBox) {
            // find vertices within bounding box
            const geometry = mesh.geometry;
            const positionAttribute = geometry.attributes.position;
            const vertices = [];
            let coralArea = 0;

            for (let i = 0; i < positionAttribute.count; i += 3) { // Assuming non-indexed BufferGeometry
                const v0 = new THREE.Vector3().fromBufferAttribute(positionAttribute, i);
                const v1 = new THREE.Vector3().fromBufferAttribute(positionAttribute, i + 1);
                const v2 = new THREE.Vector3().fromBufferAttribute(positionAttribute, i + 2);

                // Transform vertices to world coordinates
                v0.applyMatrix4(mesh.matrixWorld);
                v1.applyMatrix4(mesh.matrixWorld);
                v2.applyMatrix4(mesh.matrixWorld);

                if (boundingBox.containsPoint(v0) && boundingBox.containsPoint(v1) && boundingBox.containsPoint(v2)) {
                    vertices.push(v0, v1, v2);
                    coralArea += this.triangleArea(v0, v1, v2);
                }
            }
            coralArea = coralArea * Math.pow(100, 2); // Convert to cm²
            
            // create coral mesh, find boundary vertices, create convex hull with boundary faces removed
            const { coralMesh, newHullMesh } = this.createObjects(vertices);
            // create triaxial ellipsoid for ecological volume
            const { ellipsoidVolume, ellipsoidSA } = this.createEllipsoid(boundingBox);

            // Calculate metrics
            const { volume, surfaceArea } = this.analyzeHull(newHullMesh);
            const rugosity = coralArea / surfaceArea || 1;
            const convex_ratio = surfaceArea / volume || 1;

            this.$store.dispatch('updateMetrics', {
                metrics: {
                    convex_volume: Math.abs(volume),
                    convex_surface_area: surfaceArea,
                    convex_ratio: convex_ratio,
                    coral_surface_area: coralArea,
                    rugosity: rugosity,
                    ellipsoid_surface_area: ellipsoidSA,
                    ellipsoid_volume: ellipsoidVolume,
                    ellipsoid_ratio: ellipsoidSA / ellipsoidVolume,
                }
            });

            this.$store.dispatch('selectBox', null)
    
        },
        analyzeHull(hullMesh) {
            let volume = 0;
            let surfaceArea = 0;
            const geometry = hullMesh.geometry;
            const positionAttribute = geometry.attributes.position;
            const vertex = new THREE.Vector3();
            const centroid = new THREE.Vector3();

            // Compute the centroid of the hull
            for (let i = 0; i < positionAttribute.count; i++) {
                vertex.fromBufferAttribute(positionAttribute, i);
                centroid.add(vertex);
            }
            centroid.divideScalar(positionAttribute.count);

            // Iterate over each set of three vertices (triangle)
            for (let i = 0; i < positionAttribute.count; i += 3) {
                const v0 = new THREE.Vector3().fromBufferAttribute(positionAttribute, i);
                const v1 = new THREE.Vector3().fromBufferAttribute(positionAttribute, i + 1);
                const v2 = new THREE.Vector3().fromBufferAttribute(positionAttribute, i + 2);

                // Calculate surface area
                const area = this.triangleArea(v0, v1, v2);
                surfaceArea += area;

                // Calculate volume contribution
                volume += this.tetrahedronVolume(v0, v1, v2, centroid);
            }
            volume = Math.abs(volume) * Math.pow(100, 3);
            surfaceArea = surfaceArea * Math.pow(100, 2);

            return { volume, surfaceArea };
        },
        createEllipsoid(boundingBox) {
            const L = boundingBox.max.x - boundingBox.min.x; 
            const W = boundingBox.max.y - boundingBox.min.y;
            const H = boundingBox.max.z - boundingBox.min.z; 

            const geometry = new THREE.SphereGeometry(1, 32, 32);
            geometry.scale(L / 2, W / 2, H / 2);
            const material = new THREE.MeshBasicMaterial({
                color: 'red',
                transparent: true,
                opacity: 0.1,
                wireframe: true
            });
            const ellipsoid = new THREE.Mesh(geometry, material);
            ellipsoid.position.set(
                boundingBox.min.x + L / 2,
                boundingBox.min.y + W / 2,
                boundingBox.min.z + H / 2
            );
            // this.scene.add(ellipsoid);
            ellipsoid.userData.id = this.$store.state.selectedBox;
            ellipsoid.userData.type = 'ellipsoid';

            const a = L / 2;
            const b = W / 2;
            const c = H / 2;
            let ellipsoidVolume = (4 / 3) * Math.PI * a * b * c;
            ellipsoidVolume = ellipsoidVolume * Math.pow(100, 3);
            const p = 1.6075;
            let ellipsoidSA = 4 * Math.PI * Math.pow((Math.pow(a * b, p) + Math.pow(a * c, p) + Math.pow(b * c, p)) / 3, 1 / p);
            ellipsoidSA = ellipsoidSA * Math.pow(100, 2);

            return { ellipsoidVolume, ellipsoidSA };
        },
        createObjects(vertices) {
            // Create a coral mesh
            const coralGeometry = new THREE.BufferGeometry().setFromPoints(vertices);
            const coralMaterial = new THREE.MeshBasicMaterial({
                color: 'white',
                transparent: true,
                opacity: 0.5,
                wireframe: false,
            });
            const coralMesh = new THREE.Mesh(coralGeometry, coralMaterial);
            const coralEdges = new THREE.EdgesGeometry(coralGeometry);
            const coralLineMaterial = new THREE.LineBasicMaterial({
                color: 'white',
                linewidth: 10,
            });
            const coralLineSegments = new THREE.LineSegments(coralEdges, coralLineMaterial);
            coralMesh.add(coralLineSegments);
            coralMesh.userData.id = this.$store.state.selectedBox;
            coralMesh.userData.type = 'coral';
            this.scene.add(coralMesh);

            // Create a convex hull from all vertices
            const hullGeometry = new ConvexGeometry(vertices);
            const hullMaterial = new THREE.MeshBasicMaterial({
                color: 0x27BDF4,
                transparent: true,
                opacity: 0.3,
                wireframe: false,
            });
            const hullMesh = new THREE.Mesh(hullGeometry, hullMaterial);

            // create a new mesh from convex hull with boundary faces removed
            const boundaryVertices = this.findBoundary(coralGeometry, coralMesh);
            const { newHullGeometry, newHullMesh } = this.removeBoundaryFacesFromHull(hullMesh, boundaryVertices);

            // visualize and add to scene
            const hullEdges = new THREE.EdgesGeometry(newHullGeometry);
            const hullLineMaterial = new THREE.LineBasicMaterial({
                color: 0x27BDF4,
                linewidth: 2,
            });
            const hullLineSegments = new THREE.LineSegments(hullEdges, hullLineMaterial);
            newHullMesh.add(hullLineSegments);
            newHullMesh.userData.id = this.$store.state.selectedBox;
            newHullMesh.userData.type = 'hull';
            this.scene.add(newHullMesh);

            return { coralMesh, newHullMesh };

        },
        findBoundary(geometry, mesh) {
            const indexedGeometry = mergeVertices(geometry);
            const edgeMap = new Map();

            const indexAttribute = indexedGeometry.index;
            const positionAttribute = indexedGeometry.attributes.position;

            for (let i = 0; i < indexAttribute.count; i += 3) {
                const indices = [indexAttribute.getX(i), indexAttribute.getX(i + 1), indexAttribute.getX(i + 2)];

                // Iterate over each edge in the triangle
                for (let j = 0; j < 3; j++) {
                    const edgeKey = `${Math.min(indices[j], indices[(j + 1) % 3])}-${Math.max(indices[j], indices[(j + 1) % 3])}`;
                    if (!edgeMap.has(edgeKey)) edgeMap.set(edgeKey, []);
                    edgeMap.get(edgeKey).push(i / 3); // Add the triangle index to the edge
                }
            }

            // Edges with only one face are on the boundary
            const boundaryEdges = Array.from(edgeMap.entries()).filter(([key, val]) => val.length === 1);
            
            const boundaryVertices = [];
            boundaryEdges.forEach(([key, val]) => {
                const [startIdx, endIdx] = key.split('-').map(Number);
                const startVertex = new THREE.Vector3().fromBufferAttribute(positionAttribute, startIdx);
                const endVertex = new THREE.Vector3().fromBufferAttribute(positionAttribute, endIdx);

                boundaryVertices.push(startVertex, endVertex);
            });

            return boundaryVertices
        },
        removeBoundaryFacesFromHull(hullMesh, boundaryVertices) {
            const threshold = 0.01;
            const newVertices = [];
            const positionAttribute = hullMesh.geometry.attributes.position;

            // Iterate over every vertex in the convex hull, considering 3 consecutive vertices as one face
            for (let i = 0; i < positionAttribute.count; i += 3) {
                let faceVertices = [];

                // Collect vertices for the current face
                for (let j = 0; j < 3; j++) {
                    const vertexIndex = i + j;
                    const vertex = new THREE.Vector3(
                        positionAttribute.getX(vertexIndex),
                        positionAttribute.getY(vertexIndex),
                        positionAttribute.getZ(vertexIndex)
                    );
                    faceVertices.push(vertex);
                }

                // Check if any vertex of the face is close to a boundary vertex
                let isBoundaryFace = faceVertices.every(vertex =>
                    boundaryVertices.some(boundaryVertex => vertex.distanceTo(boundaryVertex) < threshold)
                );

                // If the face is not a boundary face, add its vertices to the newVertices array
                if (!isBoundaryFace) {
                    faceVertices.forEach(vertex => {
                        newVertices.push(vertex.x, vertex.y, vertex.z);
                    });
                }
            }

            // Create new geometry and mesh from the filtered vertices
            const newHullGeometry = new THREE.BufferGeometry();
            newHullGeometry.setAttribute('position', new THREE.Float32BufferAttribute(newVertices, 3));
            const newHullMesh = new THREE.Mesh(newHullGeometry, hullMesh.material.clone());

            return { newHullGeometry, newHullMesh };
        },
        tetrahedronVolume(v0, v1, v2, v3) {
            const a = v0.clone().sub(v3);
            const b = v1.clone().sub(v3);
            const c = v2.clone().sub(v3);
            return a.dot(b.cross(c)) / 6;
        },
        triangleArea(v0, v1, v2) {
            const side1 = v1.clone().sub(v0);
            const side2 = v2.clone().sub(v0);
            return side1.cross(side2).length() / 2;
        },
        filterIsolatedPoints(points) {
            const factor = 1.4;
            //Find centroid
            const centroid = new THREE.Vector3();
            points.forEach(point => centroid.add(point));
            centroid.divideScalar(points.length);

            let totalDistance = 0;
            points.forEach(point => totalDistance += point.distanceTo(centroid));
            const averageDistance = totalDistance / points.length;
            const thresholdDistance = averageDistance * factor;

            return points.filter(point => point.distanceTo(centroid) < thresholdDistance);
        },
        onResize() {
            this.labelRenderer.setSize(this.renderContainer.clientWidth, this.renderContainer.clientHeight);
        },
    },
}
</script>

<style>
.range-slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 2px; /* Slider Track height */
    background: #27BDF4; /* Background Color */
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
}

.range-slider:hover {
    opacity: 1;
}

.range-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px; /* Thumb width */
    height: 15px; /* Thumb height */
    border-radius: 50%;
    background: #27BDF4; /* Thumb Color */
    cursor: pointer;
}

.range-slider::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #27BDF4;
    cursor: pointer;
}
.annotationLabel {
    padding: 2px 4px;
    border-radius: 2px;
    background-color: black;
    color: white;
    font-size: 8px;
    font-family: Arial, sans-serif;
    text-align: center;
    white-space: nowrap;
}
</style>