<template>
    <div class="relative inline-block text-left">
        <div @click.stop="toggleDropdown"
            class="cursor-pointer pr-2 bg-black text-white rounded flex items-center justify-between relative">
            <slot name="icon"></slot>
            <slot name="badge"></slot> <!-- Add this line to include the badge slot -->
        </div>
        <div v-if="isOpen"
            class="z-50 origin-top-left absolute right-0 mt-2 w-72 rounded-md shadow-lg bg-neutral-800 ring-1 ring-black ring-opacity-5"
            style="max-height: 400px; overflow-y: auto;">
            <div class="py-1" role="menu" aria-orientation="vertical">
                <div v-if="options.length > 0">
                    <div v-for="item in options" :key="`${item.branchId}-${item.siteId}-${item.cellId}-${item.log}`"
                        @click="selectItem(item)"
                        class="block px-4 py-2 text-sm text-white hover:bg-neutral-700 cursor-pointer">
                        {{ item.branchName }} - {{ item.siteName }} - {{ item.cellName }} - {{ item.logTimestamp }}
                    </div>
                </div>
                <div v-else class="block px-4 py-2 text-sm text-white">
                    You have no unannotated logs
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: [Number, String, Object],
            default: ''
        },
        options: {
            type: Array,
            default: () => []
        },
        placeholder: {
            type: String,
            default: 'Select a log'
        }
    },
    data() {
        return {
            isOpen: false,
            selected: this.options.find(option => option.log === this.value) || null
        };
    },
    watch: {
        value(newValue) {
            this.selected = this.options.find(option => option.log === newValue) || null;
        }
    },
    methods: {
        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },
        selectItem(item) {
            this.selected = item;
            this.$emit('input', {
                branchId: item.branchId,
                siteId: item.siteId,
                cellId: item.cellId,
                log: item.log
            });
            this.isOpen = false; // Close the dropdown when an item is selected
        },
        handleClick(event) {
            if (!this.$el.contains(event.target)) {
                this.isOpen = false;
            }
        }
    },
    mounted() {
        document.addEventListener('click', this.handleClick, true);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClick, true);
    }
};
</script>

<style scoped>
/* Add any necessary styles here */
</style>
