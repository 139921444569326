<template>
    <div class="relative inline-block text-left w-full">
        <div @click="toggleDropdown"
            class="cursor-pointer p-1 bg-neutral-900 text-white rounded border border-gray-600 flex items-center justify-between">
            <span>{{ selectedLabel }}</span>
            <span :class="`mdi ${icon} text-white`"></span>
        </div>
        <div v-if="isOpen"
            class="z-50 origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-black ring-1 ring-black ring-opacity-5"
            style="max-height: 400px; overflow-y: auto;">
            <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <div v-for="item in options" :key="item[optionValue]" @click="selectItem(item)"
                    class="block px-4 py-2 text-sm text-white hover:bg-neutral-700 cursor-pointer z-10">
                    {{ item[optionLabel] }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: [Number, String],
            default: ''
        },
        options: {
            type: Array,
            default: () => []
        },
        icon: {
            type: String,
            default: 'mdi-chevron-down' // default dropdown icon
        },
        optionLabel: {
            type: String,
            default: 'label' // default key for option label
        },
        optionValue: {
            type: String,
            default: 'value' // default key for option value
        }
    },
    data() {
        return {
            isOpen: false,
            selected: this.value
        };
    },
    computed: {
        selectedLabel() {
            const selectedItem = this.options.find(item => item[this.optionValue] === this.selected);
            return selectedItem ? selectedItem[this.optionLabel] : '';
        }
    },
    methods: {
        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },
        selectItem(item) {
            this.selected = item[this.optionValue];
            this.$emit('input', this.selected); // Use 'input' to be consistent with v-model
            this.isOpen = false;
        }
    },
    watch: {
        value(value) {
            this.selected = value;
        }
    }
};
</script>
