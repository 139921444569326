<template>
    <div class="flex items-center border border-gray-600 rounded overflow-hidden bg-neutral-800 w-full">
        <button @click="selectNo" :class="noButtonClasses">
            No
        </button>
        <div class="border-l border-gray-600"></div> <!-- Gray line between buttons -->
         <button @click="selectYes" :class="yesButtonClasses">
            Yes
        </button>
    </div>
</template>

<script>
export default {
    props: ['value'], // Expect a Boolean value or null for undecided
    computed: {
        yesButtonClasses() {
            return [
                'px-2', 'py-1', 'w-1/2', 'text-white', 'focus:outline-none',
                { 'bg-primary': this.value === true, 'bg-neutral-800 hover:bg-neutral-700': this.value !== true }
            ];
        },
        noButtonClasses() {
            return [
                'px-2', 'py-1', 'w-1/2', 'text-white', 'focus:outline-none',
                { 'bg-primary': this.value === false, 'bg-neutral-800 hover:bg-neutral-700': this.value !== false }
            ];
        }
    },
    methods: {
        selectYes() {
            this.updateValue(true);
        },
        selectNo() {
            this.updateValue(false);
        },
        updateValue(newValue) {
            this.$emit('update:value', newValue);
        }
    }
};
</script>

<style scoped>
.bg-primary {
    background-color: #27BDF4;
}
</style>
