<template>
    <div v-if="visible" class="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
        <div class="bg-neutral-900 text-white p-3 rounded shadow-lg w-80">
            <h3 class="text-lg font-bold mb-4">{{ title }}</h3>
            <p class="mb-6">{{ message }}</p>
            <div class="flex justify-end space-x-2">
                <button @click="confirm" class="bg-primary text-white py-2 px-4 rounded">OK</button>
                <button @click="cancel"
                    class="bg-neutral-700 hover:bg-neutral-600 text-white py-2 px-4 rounded">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        message: {
            type: String,
            required: true,
        },
        visible: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        confirm() {
            this.$emit('confirm');
        },
        cancel() {
            this.$emit('cancel');
        },
    },
};
</script>

<style scoped>

</style>
