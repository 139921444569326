<template>
    <!-- No template needed since this is a self-contained component with no UI -->
</template>

<script>
import { mapState } from 'vuex';
import { EventBus } from '@/event-bus';

export default {
    name: 'Download',
    props: ['bucket'],
    computed: {
        ...mapState({
            cell: state => state.cell,
            logs: state => state.logs,
            logIndex: state => state.log_index,
            boxes: state => state.boxes
        })
    },
    mounted() {
        EventBus.$on('download', this.handleDownload);
    },
    beforeDestroy() {
        EventBus.$off('download', this.handleDownload);
    },
    methods: {
        handleDownload(type) {
            switch (type) {
                case 'model':
                    this.downloadModel();
                    break;
                case 'pointCloud':
                    this.downloadPointCloud();
                    break;
                case 'annotations':
                    this.downloadAnnotations();
                    break;
                default:
                    console.error('Unknown download type:', type);
            }
        },
        async downloadModel() {
            const modelPath = this.bucket + this.logs[this.logIndex].photogrammetryPath + '/model_medium.obj';
            const texturePath = this.bucket + this.logs[this.logIndex].photogrammetryPath + '/model_medium.jpg';
            await this.downloadFile(modelPath, 'model_medium.obj');
            await this.downloadFile(texturePath, 'model_medium.jpg');
        },
        async downloadPointCloud() {
            const pointCloudPath = this.bucket + this.logs[this.logIndex].photogrammetryPath + '/model_medium_point_cloud.pcd';
            await this.downloadFile(pointCloudPath, 'model_medium_point_cloud.pcd');
        },
        downloadAnnotations() {
            const annotations = this.boxes;
            const data = {
                org: this.cell.org,
                branch: this.cell.branch,
                site: this.cell.site,
                cell: this.cell.cell,
                name: this.cell.name,
                type: this.cell.type,
                created: this.cell.createdAt,
                geoLocation: this.logs[this.logIndex].geoLocation,
                annotations: {
                    userID: this.logs[this.logIndex].userID,
                    timestamp: this.logs[this.logIndex].timestamp,
                    objects: annotations,
                    modelTransform: this.logs[this.logIndex].modelTransform
                }
            };
            const json = JSON.stringify(data, null, 4);
            this.downloadBlob(json, 'annotations.json', 'application/json');
        },
        async downloadFile(url, filename) {
            const response = await fetch(url);
            const blob = await response.blob();
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);
        },
        downloadBlob(data, filename, type) {
            const blob = new Blob([data], { type });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        }
    }
};
</script>

<style scoped>
/* No styles needed */
</style>
