<template>
    <div class="relative inline-block text-left w-full">
        <div @click="toggleDropdown"
            class="cursor-pointer p-2 bg-neutral-900 text-white rounded border border-gray-600 flex items-center justify-between">
            <span>{{ selected }}</span>
            <span :class="`mdi ${icon} text-white`"></span>
        </div>
        <div v-if="isOpen"
            class="z-50 origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-black ring-1 ring-black ring-opacity-5 dropdown-width"
            style="max-height: 400px; overflow-y: auto;">
            <input type="text" v-model="searchTerm" @input="filterOptions" placeholder="Search..."
                class="dropdown-input px-4 py-2 w-full text-sm text-white bg-neutral-900 border-b border-gray-600"
                ref="searchInput" />
            <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <div v-for="item in filteredOptions" :key="item" @click="selectItem(item)"
                    class="block px-4 py-2 text-sm text-white hover:bg-neutral-700 cursor-pointer z-10">
                    {{ item }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: [Number, String],
            default: ''
        },
        options: {
            type: Array,
            default: () => []
        },
        icon: {
            type: String,
            default: 'mdi-chevron-down' // default dropdown icon
        }
    },
    data() {
        return {
            isOpen: false,
            selected: this.value,
            searchTerm: '',
            filteredOptions: this.options
        };
    },
    methods: {
        toggleDropdown() {
            this.isOpen = !this.isOpen;
            if (this.isOpen) {
                this.$nextTick(() => {
                    this.$refs.searchInput.focus();
                });
            } else {
                this.searchTerm = '';
                this.filteredOptions = this.options;
            }
        },
        selectItem(item) {
            this.selected = item;
            this.$emit('input', item); // Use 'input' to be consistent with v-model
            this.isOpen = false;
            this.searchTerm = '';
            this.filteredOptions = this.options;
        },
        filterOptions() {
            const term = this.searchTerm.toLowerCase();
            this.filteredOptions = this.options.filter(option =>
                option.toLowerCase().startsWith(term)
            );
        }
    },
    watch: {
        value(value) {
            this.selected = value;
        },
        options(newOptions) {
            this.filteredOptions = newOptions;
        }
    }
};
</script>

<style>
.dropdown-input {
    box-sizing: border-box;
}

</style>
