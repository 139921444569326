var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-container"},[_c('ToastNotification'),(_vm.isLoading)?_c('div',{staticClass:"fixed top-0 left-0 w-full"},[_c('div',{staticClass:"w-full h-1 bg-neutral-700"},[_c('div',{staticClass:"h-full bg-primary transition-all ease-out duration-150",style:({ width: _vm.loadingProgress + '%' })})])]):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-75"},[_c('RadialProgressBar',{attrs:{"progress":Number(_vm.loadingProgress),"size":100}})],1):_vm._e(),(_vm.isMobile)?_c('HeaderMobile',{staticClass:"h-12",attrs:{"branch-id":_vm.branchId,"site-id":_vm.siteId,"cell-id":_vm.cellId,"log-index":_vm.logIndex}}):_c('Header',{staticClass:"h-12",attrs:{"branch-id":_vm.branchId,"site-type":_vm.siteType,"site-id":_vm.siteId,"cell-id":_vm.cellId,"log-index":_vm.logIndex}}),(_vm.isMobile)?_c('div',{staticClass:"flex flex-col",style:({ height: 'calc(100vh - 3rem)' })},[_c('div',{staticClass:"flex-grow relative flex",staticStyle:{"overflow":"hidden"}},[_c('ViewportMobile',{staticClass:"h-full flex-grow",attrs:{"viewName":"Perspective","scene":_vm.scene}})],1)]):_c('div',{staticClass:"flex",style:({ height: 'calc(100vh - 3rem)' })},[_c('LeftSidebar',{attrs:{"scene":_vm.scene}}),(_vm.$store.state.modelType == 'mesh')?_c('div',{staticClass:"flex flex-col flex-grow flex-shrink min-w-0"},[_c('div',{staticClass:"flex-grow",staticStyle:{"overflow":"hidden"}},[_c('Viewport',{staticClass:"border border-gray-700 h-full flex-grow",class:{
					'hover:border-gray-500': _vm.$store.state.activeView !== 'Perspective',
					'border-primary': _vm.$store.state.activeView === 'Perspective'
				},attrs:{"viewName":"Perspective","scene":_vm.scene}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.mode != 'compare'),expression:"$store.state.mode != 'compare'"}],staticClass:"grid grid-cols-3 h-1/3",staticStyle:{"overflow":"hidden"}},_vm._l((_vm.views),function(viewDetails,viewName){return _c('div',{key:viewName},[_c('Viewport',{staticClass:"border border-gray-700",class:{
					'hover:border-gray-500': _vm.$store.state.activeView !== viewName,
					'border-primary': _vm.$store.state.activeView === viewName
				},attrs:{"viewName":viewName,"viewColor":viewDetails.color,"position":viewDetails.position,"scene":_vm.scene}})],1)}),0),(_vm.$store.state.mode == 'compare')?_c('Compare',{staticClass:"h-1/3 border border-gray-700 overflow-hidden",attrs:{"scene":_vm.scene,"bucket":_vm.bucket}}):_vm._e(),_c('div',{staticClass:"h-14"},[_c('Timeline',{staticClass:"border-l border-r border-gray-700",attrs:{"scene":_vm.scene,"bucket":_vm.bucket}})],1)],1):(_vm.$store.state.modelType == 'ortho')?_c('div',{staticClass:"flex flex-col flex-grow flex-shrink min-w-0"},[_c('div',{staticClass:"flex-grow",staticStyle:{"overflow":"hidden"}},[_c('ViewportOrtho',{staticClass:"border border-gray-700 h-full flex-grow",attrs:{"scene":_vm.scene}})],1)]):_vm._e(),_c('ContextBar'),_c('RightSidebar',{attrs:{"scene":_vm.scene}}),_c('Download',{attrs:{"bucket":_vm.bucket}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }