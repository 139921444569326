<script>
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
    extends: Line,
    mixins: [reactiveProp],
    props: ['options'],
    mounted() {
        this.renderChart(this.chartData, this.options || {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        max: 6,
                        min: 1,
                        stepSize: 1,
                        display: false,
                        // callback: function (value, index, values) {
                        //     // Show only the min and max values
                        //     if (value === 1 || value === 6) {
                        //         return value;
                        //     }
                        // }
                    },
                    gridLines: {
                        // color: 'rgba(75, 75, 75, 0.7)' // Dark gray grid lines
                        display: false,
                    }
                }],
                xAxes: [{
                    ticks: {
                        maxTicksLimit: 2,
                        maxRotation: 0,
                        minRotation: 0,
                        callback: function (value, index, values) {
                            // Show only the first and last date
                            if (index === 0 || index === values.length - 1) {
                                return value;
                            }
                        }
                    },
                    gridLines: {
                        display: false,
                        drawOnChartArea: false,
                    }
                }]
            },
            legend: {
                display: false
            },
            // animation: {
            //     onComplete: function () {
            //         var ctx = this.chart.ctx;
            //         ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, 'normal', Chart.defaults.global.defaultFontFamily);
            //         ctx.textAlign = 'center';
            //         ctx.textBaseline = 'bottom';
            //         ctx.fillStyle = this.chart.config.options.defaultFontColor;

            //         var previousValue = null; // Variable to hold the last value

            //         this.data.datasets.forEach(function (dataset) {
            //             dataset.data.forEach(function (value, index) {
            //                 var model = dataset._meta[Object.keys(dataset._meta)[0]].data[index]._model;
            //                 // Check if the current value is different from the previous value
            //                 if (previousValue !== value || index === 0) {
            //                     ctx.fillText(value, model.x, model.y - 5);
            //                 }

            //                 previousValue = value; // Update the last value
            //             });
            //         });
            //     }
            // },
        })
    }
}
</script>