import { render, staticRenderFns } from "./DropdownUserLogs.vue?vue&type=template&id=01c60093&scoped=true"
import script from "./DropdownUserLogs.vue?vue&type=script&lang=js"
export * from "./DropdownUserLogs.vue?vue&type=script&lang=js"
import style0 from "./DropdownUserLogs.vue?vue&type=style&index=0&id=01c60093&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01c60093",
  null
  
)

export default component.exports