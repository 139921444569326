class RendererService {
    constructor() {
        this.renderer = null;
        this.camera = null;
    }

    setRenderer(renderer) {
        this.renderer = renderer;
    }

    getRenderer() {
        return this.renderer;
    }

    setCamera(camera) {
        this.camera = camera;
    }

    getCamera() {
        return this.camera;
    }
}

export default new RendererService();
