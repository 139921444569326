<template>
    <div class="w-16 flex-none flex flex-col items-center bg-black mt-1">
        <!-- Main Buttons -->
        <button v-if="$store.state.modelType == 'mesh'" @click="toggleMode('model')" :class="{
                'bg-[#27bdf4]': $store.state.mode == 'model',
                'bg-neutral-700 hover:bg-neutral-500': $store.state.mode != 'model'
            }" class="relative flex justify-center items-center rounded m-1 transition-colors button-square">
            <span class="tooltip">Edit Model</span>
            <span class="mdi mdi-rotate-orbit text-white text-lg"></span>
        </button>

        <button @click="toggleMode('measure')" :class="{
            'bg-[#27bdf4]': $store.state.mode == 'measure',
            'bg-neutral-700 hover:bg-neutral-500': $store.state.mode != 'measure'
        }"
            class="relative flex justify-center items-center rounded m-1 hover:bg-neutral-300 transition-colors button-square">
            <span class="tooltip">Measure</span>
            <span class="mdi mdi-ruler text-white text-lg"></span>
        </button>

        <!-- <button v-if="$store.state.modelType == 'mesh'" @click="toggleMode('crop')" :class="{
            'bg-[#27bdf4]': $store.state.mode == 'crop',
            'bg-neutral-700 hover:bg-neutral-500': $store.state.mode != 'crop'
        }"
            class="relative flex justify-center items-center rounded m-1 hover:bg-neutral-300 transition-colors button-square">
            <span class="tooltip">Crop</span>
            <span class="mdi mdi-crop-free text-white text-lg"></span>
        </button> -->


        <button v-if="$store.state.modelType == 'mesh'" @click="toggleMode('annotate')" :class="{
                'bg-[#27bdf4]': $store.state.mode == 'annotate',
                'bg-neutral-700 hover:bg-neutral-500': $store.state.mode != 'annotate'
            }" class="relative flex justify-center items-center rounded m-1 transition-colors button-square">
            <span class="tooltip">Annotate</span>
            <span class="mdi mdi-cube-outline text-white text-lg"></span>
        </button>

        <button v-if="$store.state.modelType == 'ortho'" @click="toggleMode('survey')" :class="{
            'bg-[#27bdf4]': $store.state.mode == 'survey',
            'bg-neutral-700 hover:bg-neutral-500': $store.state.mode != 'survey'
        }" class="relative flex justify-center items-center rounded m-1 transition-colors button-square">
            <span class="tooltip">Survey</span>
            <span class="mdi mdi-map-search text-white text-lg"></span>
        </button>

        <button v-if="$store.state.modelType == 'mesh'" @click=" toggleMode('compare')"
            :disabled="$store.state.logs.length <= 1" :class="{
                'bg-[#27bdf4]': $store.state.mode == 'compare',
                'bg-neutral-700 hover:bg-neutral-500': $store.state.mode != 'compare' && $store.state.logs.length > 1,
                'bg-neutral-800': $store.state.logs.length <= 1,
            }" class="relative flex justify-center items-center rounded m-1 transition-colors button-square">
            <span class="tooltip">Compare</span>
            <span class="mdi mdi-select-compare text-white text-lg"></span>
        </button>

        <!-- Annotation Tools -->
        <div v-if="$store.state.mode == 'annotate'">
            <!-- Three Horizontal Dots -->
            <div class="flex justify-center items-center">
                <span class="text-gray-500 text">…</span>
            </div>
            <button @click="toggleAddBoxMode($event)"
                class="relative flex justify-center items-center rounded m-1 bg-neutral-700 hover:bg-neutral-500 transition-colors button-square">
                <span class="tooltip">Add</span>
                <span class="mdi mdi-plus text-white text-lg"></span>
            </button>
            <button @click="deleteBox" :disabled="$store.state.selectedBox === null"
                class="relative flex justify-center items-center rounded m-1 transition-colors button-square"
                :class="{ 'bg-neutral-700 hover:bg-neutral-500': $store.state.selectedBox !== null, 'bg-neutral-800': $store.state.selectedBox === null }">
                <span class="tooltip">Delete</span>
                <span
                    :class="{ 'mdi mdi-delete text-lg': true, 'text-gray-600': $store.state.selectedBox === null, 'text-white': $store.state.selectedBox !== null }"></span>
            </button>
            <button @click="$store.dispatch('selectBox', null)" :disabled="$store.state.selectedBox === null"
                class="relative flex justify-center items-center rounded m-1 transition-colors button-square"
                :class="{ 'bg-neutral-700 hover:bg-neutral-500': $store.state.selectedBox !== null, 'bg-neutral-800': $store.state.selectedBox === null }">
                <span class="tooltip">Deselect</span>
                <span
                    :class="{ 'mdi mdi-selection-off text-lg': true, 'text-gray-600': $store.state.selectedBox === null, 'text-white': $store.state.selectedBox !== null }"></span>
            </button>
            <button @click="analyzeCoral" :disabled="$store.state.selectedBox === null"
                class="relative flex justify-center items-center rounded m-1 transition-colors button-square"
                :class="{ 'bg-neutral-700 hover:bg-neutral-500': $store.state.selectedBox !== null, 'bg-neutral-800': $store.state.selectedBox === null }">
                <span class="tooltip">Analyze Coral</span>
                <span
                    :class="{ 'mdi mdi-creation text-lg': true, 'text-gray-600': $store.state.selectedBox === null, 'text-white': $store.state.selectedBox !== null }"></span>
            </button>
        </div>

        <!-- Measure Tools -->
        <div v-if="$store.state.mode == 'measure'">
            <!-- Three Horizontal Dots -->
            <div class="flex justify-center items-center">
                <span class="text-gray-500 text">…</span>
            </div>
            <button @click="addRuler($event)"
                class="relative flex justify-center items-center rounded m-1 bg-neutral-700 hover:bg-neutral-500 transition-colors button-square">
                <span class="tooltip">Add</span>
                <span class="mdi mdi-plus text-white text-lg"></span>
            </button>
        </div>

        <!-- Survey Tools -->
        <div v-if="$store.state.mode == 'survey'">
            <!-- Three Horizontal Dots -->
            <div class="flex justify-center items-center">
                <span class="text-gray-500 text">…</span>
            </div>
            <button @click="createTransect($event)" :disabled="$store.state.boxes.length > 0"
                class="relative flex justify-center items-center rounded m-1 transition-colors button-square"
                :class="{'bg-neutral-700 hover:bg-neutral-500': $store.state.boxes.length <= 0, 'bg-neutral-800': $store.state.boxes.length > 0}">
                <span class="tooltip">Create Transect</span>
                <span class="mdi mdi-plus text-white text-lg"></span>
            </button>
            <button @click="clearTransect($event)" :disabled="$store.state.boxes.length <= 0"
                class="relative flex justify-center items-center rounded m-1 transition-colors button-square"
                :class="{ 'bg-neutral-700 hover:bg-neutral-500': $store.state.boxes.length > 0, 'bg-neutral-800': $store.state.boxes.length <= 0 }">
                <span class="tooltip">Clear Transect</span>
                <span class="mdi mdi-restart text-white text-lg"></span>
            </button>
        </div>


        <!-- Transform Mode -->
        <div v-if="$store.state.transformActive">
            <!-- Three Horizontal Dots -->
            <div class="flex justify-center items-center">
                <span class="text-gray-500 text">…</span>
            </div>

            <button @click="setTransformMode('translate')" :class="{
                    'bg-neutral-400': $store.state.transformMode === 'translate',
                    'bg-neutral-700 hover:bg-neutral-500': $store.state.transformMode !== 'translate'
                }"
                class="relative flex justify-center items-center rounded m-1 hover:bg-neutral-500 transition-colors button-square">
                <span class="tooltip">Move</span>
                <span class="mdi mdi-cursor-move text-white text-lg"></span>
            </button>

            <button v-if="$store.state.mode == 'model'" @click="setTransformMode('rotate')" :class="{
                    'bg-neutral-400': $store.state.transformMode === 'rotate',
                    'bg-neutral-700 hover:bg-neutral-500': $store.state.transformMode !== 'rotate',
                }"
                class="relative flex justify-center items-center rounded m-1 hover:bg-neutral-500 transition-colors button-square"
                :disabled="$store.state.mode == 'crop'">
                <span class="tooltip">Rotate</span>
                <span class="mdi mdi-rotate-360 text-lg text-white"></span>
            </button>

            <button @click="setTransformMode('scale')" :class="{
                    'bg-neutral-400': $store.state.transformMode === 'scale',
                    'bg-neutral-700 hover:bg-neutral-500': $store.state.transformMode !== 'scale'
                }"
                class="relative flex justify-center items-center rounded m-1 hover:bg-neutral-500 transition-colors button-square">
                <span class="tooltip">Scale</span>
                <span class="mdi mdi-resize text-white text-lg"></span>
            </button>
        </div>

        <!-- User Profile & Logout at the bottom -->
        <div class="mt-auto mb-4 flex flex-col items-center w-full" v-if="$store.state.user">
            <div v-if="$store.state.user.photoURL" class="flex items-center flex-col p-4 w-full">
                <img :src="$store.state.user.photoURL" alt="Profile" class="rounded-full object-cover"
                    style="width: 50%; aspect-ratio: 1/1;">
                <span class="text-white text-sm mt-2">{{ $store.state.user.displayName.split(' ')[0] }}</span>
            </div>
            <div v-else class="flex items-center flex-col p-4 w-full">
                <span class="mdi mdi-account-circle text-white text-4xl"></span>
                <span class="text-white text-xs mt-2">{{ $store.state.user.displayName.split(' ')[0] }}</span>
            </div>
            <button @click="logout"
                class=" bg-neutral-700 hover:bg-neutral-500 relative flex justify-center items-center rounded m-1 transition-colors button-square">
                <span class="mdi mdi-logout text-white text-lg"></span>
            </button>
        </div>
    </div>
</template>

<script>
import { EventBus } from '@/event-bus';
import { getAuth, signOut } from 'firebase/auth';

export default {
    name: 'Sidebar',
    props: ['scene'],
    methods: {
        toggleAddBoxMode(event) {
            event.stopPropagation();
            EventBus.$emit('toggle-addbox-mode');
        },
        deleteBox() {
            EventBus.$emit('delete-box');
        },
        analyzeCoral() {
            EventBus.$emit('analyze-coral');
        },
        setTransformMode(mode) {
            EventBus.$emit('transform-mode', mode);
        },
        toggleMode(mode) {
            this.$store.dispatch('toggleMode', mode);
        },
        addRuler() {
            EventBus.$emit('add-ruler');
        },
        createTransect() {
            EventBus.$emit('create-transect')
        },
        clearTransect() {
            EventBus.$emit('clear-transect')
        },
        async logout() {
            const auth = getAuth();
            try {
                await signOut(auth);
                this.$store.dispatch('setUser', null);
            } catch (error) {
                console.error("Logout failed: ", error.message);
            }
        }
    }
}

</script>

<style scoped>
.button-square {
  width: 2.5rem; 
  height: 2.5rem;
}

.button-square:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
.tooltip {
  position: absolute;
  top: 50%; 
  left: 110%; 
  transform: translateY(-50%); 
  background-color: rgba(57, 65, 80, 1.0); 
  color: white;
  text-align: center;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 0.75rem;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0s linear 1s;
  z-index: 10;
}
</style>