import Vue from 'vue'
import App from './App.vue'
import store from './store/index.js';
import router from './router/index.js'
import './assets/styles/index.css'
import '@mdi/font/css/materialdesignicons.min.css';
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, setPersistence, browserSessionPersistence } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyBc3QUNddqjuuDhu6CHqV6kIq2ALQWaHMk",
  authDomain: "restoration-ios.firebaseapp.com",
  projectId: "restoration-ios",
  storageBucket: "restoration-ios.appspot.com",
  messagingSenderId: "96749053095",
  appId: "1:96749053095:web:28794d74f3339c1585e782",
  measurementId: "G-XZRLKG8M8X"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
setPersistence(auth, browserSessionPersistence);

export const db = getFirestore(app);

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
