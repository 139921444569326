// loaders.js
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { PCDLoader } from 'three/examples/jsm/loaders/PCDLoader';


/**
 * Load an OBJ model with optional texture.
 * 
 * @param {string} modelUrl - URL to the OBJ model file.
 * @param {string|null} textureUrl - URL to the texture file, null if no texture.
 * @param {Function} onLoad - Callback function when loading is successfully completed.
 * @param {Function} onProgress - Callback function for loading progress.
 * @param {Function} onError - Callback function for handling errors.
 */

export const loadObjModel = (model_url, texture_url, onProgress) => {
    return new Promise((resolve, reject) => {
        const loader = new OBJLoader();
        const textureLoader = new THREE.TextureLoader();
        textureLoader.crossOrigin = 'anonymous';

        textureLoader.load(
            texture_url,
            (texture) => {
                texture.colorSpace = THREE.SRGBColorSpace;
                const material = new THREE.MeshStandardMaterial({ map: texture });
                material.clippingPlanes = [];

                loader.load(
                    model_url,
                    (object) => {
                        let metadata = {};
                        object.traverse((child) => {
                            if (child.isMesh) {
                                // Recenter the geometry
                                const geometry = child.geometry;
                                geometry.computeBoundingBox();
                                geometry.center();

                                // Add material
                                child.material = material;
                                child.rotation.x = -Math.PI / 2;

                                // Shadows
                                child.castShadow = true;
                                child.receiveShadow = true;

                                // Collect metadata
                                metadata = {
                                    vertexCount: geometry.attributes.position.count,
                                    faceCount: geometry.index ? geometry.index.count / 3 : geometry.attributes.position.count / 3,
                                    hasVertexNormals: geometry.attributes.normal !== undefined,
                                    hasUV: geometry.attributes.uv !== undefined,
                                    boundingBoxSize: geometry.boundingBox.getSize(new THREE.Vector3()),
                                };

                                if (child.material.map) {
                                    metadata.textureSize = {
                                        width: texture.image.width,
                                        height: texture.image.height,
                                    };
                                }
                                object = child;
                            }
                        });
                        resolve({ object, metadata });
                    },
                    onProgress,
                    (error) => {
                        reject(new Error(`Failed to load model from ${model_url}: ${error.message}`));
                    }
                );
            },
            undefined,
            (error) => {
                reject(new Error(`Failed to load texture from ${texture_url}: ${error.message}`));
            }
        );
    });
};


export const loadPcdModel = (model_url, onLoad, onProgress, onError) => {
    const loader = new PCDLoader();
    loader.load(model_url, (object) => {
    	// Position the point cloud (optional)
    	object.position.set(0, 0, 0);
    	object.rotation.set(-Math.PI / 2, 0, 0);
    	object.geometry.computeBoundingBox();
        const boundingBox = object.geometry.boundingBox;
        const center = boundingBox.getCenter(new THREE.Vector3()).negate();
    	object.geometry.translate(center.x, center.y, center.z);
    	object.material.clippingPlanes = [];
        object.material.size = 0.5;
        object.material.sizeAttenuation = false;

        // Collect metadata
        const metadata = {
            pointCount: object.geometry.attributes.position.count,
            boundingBoxSize: boundingBox.getSize(new THREE.Vector3())
        };

        onLoad(object, metadata)
    }, onProgress, onError);
};


// export const loadGltfModel = (url, onLoad, onProgress, onError) => {
//     const loader = new GLTFLoader();
//     loader.setDRACOLoader(dracoLoader);
//     loader.load(`/models/${this.model}.glb`, (gltf) => {
//     	gltf.scene.traverse((child) => {
//     		if (child.isMesh) {
//     			child.rotation.x = -Math.PI / 2;
//     			child.position.y += 0.5;
//     			const scaleFactor = 0.1;
//     			child.scale.set(scaleFactor, scaleFactor, scaleFactor);
//     		}
//     	});
//     	// Add object to scene
//     	this.object = gltf.scene;
//     	this.scene.add(this.object);
//     	this.createBoxWidget();
//     }, (xhr) => {
//     	// On Progress
//     	console.log((xhr.loaded / xhr.total * 100) + '% loaded');
//     }, (error) => {
//     	// On Error
//     	console.log('An error happened: ', error);
//     });
// };


