<template>
	<div class="main-container">
		<ToastNotification />
		<!-- Loading Bar -->
		<div v-if="isLoading" class="fixed top-0 left-0 w-full">
			<div class="w-full h-1 bg-neutral-700">
				<div :style="{ width: loadingProgress + '%' }"
					class="h-full bg-primary transition-all ease-out duration-150"></div>
			</div>
		</div>

		<!-- Loading Overlay -->
		<div v-if="isLoading" class="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-75">
			<RadialProgressBar :progress="Number(loadingProgress)" :size="100" />
		</div>

		<!-- Header -->
		<HeaderMobile v-if="isMobile" class="h-12" :branch-id="branchId" :site-id="siteId" :cell-id="cellId"
			:log-index="logIndex" />
		<Header v-else class="h-12" :branch-id="branchId" :site-type="siteType" :site-id="siteId" :cell-id="cellId" :log-index="logIndex" />

		<!-- Main View -->
		<div v-if="isMobile" class="flex flex-col" :style="{ height: 'calc(100vh - 3rem)' }">
			<!-- Camera Views -->
			<div class="flex-grow relative flex" style="overflow: hidden">
				<ViewportMobile class="h-full flex-grow" viewName="Perspective" :scene="scene" />
			</div>
		</div>

		<div v-else class="flex" :style="{ height: 'calc(100vh - 3rem)' }">
			<!-- Left Sidebar -->
			<LeftSidebar :scene="scene" />

			<!-- Camera Views -->
			<div v-if="$store.state.modelType == 'mesh'" class="flex flex-col flex-grow flex-shrink min-w-0">
				<div class="flex-grow" style="overflow: hidden">
					<Viewport class="border border-gray-700 h-full flex-grow" :class="{
						'hover:border-gray-500': $store.state.activeView !== 'Perspective',
						'border-primary': $store.state.activeView === 'Perspective'
					}" viewName="Perspective" :scene="scene" />
				</div>
				<div class="grid grid-cols-3 h-1/3" style="overflow: hidden" v-show="$store.state.mode != 'compare'">
					<div v-for="(viewDetails, viewName) in views" :key="viewName">
						<Viewport class="border border-gray-700" :class="{
						'hover:border-gray-500': $store.state.activeView !== viewName,
						'border-primary': $store.state.activeView === viewName
					}" :viewName="viewName" :viewColor="viewDetails.color" :position="viewDetails.position" :scene="scene" />
					</div>
				</div>

				<!-- Compare -->
				<Compare v-if="$store.state.mode == 'compare'" :scene="scene"
					class="h-1/3 border border-gray-700 overflow-hidden" :bucket="bucket" />

				<!-- Timeline -->
				<div class="h-14">
					<Timeline :scene="scene" :bucket="bucket" class="border-l border-r border-gray-700" />
				</div>
			</div>

			<div v-else-if="$store.state.modelType == 'ortho'" class="flex flex-col flex-grow flex-shrink min-w-0">
				<div class="flex-grow" style="overflow: hidden">
					<ViewportOrtho class="border border-gray-700 h-full flex-grow" :scene="scene" />
				</div>
			</div>

			<!-- Context -->
			<ContextBar />

			<!-- Right Sidebar -->
			<RightSidebar :scene="scene"/>

			<Download :bucket="bucket"/>
		</div>
	</div>
</template>


<script>
import * as THREE from 'three'
import Viewport from './Viewport.vue'
import ViewportMobile from './ViewportMobile.vue'
import ViewportOrtho from './ViewportOrtho.vue'
import Header from './Header.vue'
import HeaderMobile from './HeaderMobile.vue'
import LeftSidebar from './LeftSidebar.vue'
import RightSidebar from './RightSidebar.vue'
import Timeline from './Timeline.vue'
import ContextBar from './ContextBar.vue'
import ToastNotification from './utils/ToastNotification.vue'
import RadialProgressBar from './utils/RadialProgressBar.vue'
import Compare from './Compare.vue'
import Download from './utils/Download.vue'
import { loadObjModel } from '@/assets/js/loaders';
import { EventBus } from '@/event-bus';
import { db } from '@/main.js'
import { doc, getDoc, getDocs, updateDoc, collection } from "firebase/firestore";
import Tiff from 'tiff.js'


export default {
	name: 'Main',
	components: {
		Viewport,
		ViewportMobile,
		ViewportOrtho,
		Header,
		HeaderMobile,
		LeftSidebar,
		RightSidebar,
		Timeline,
		ContextBar,
		RadialProgressBar,
		Compare,
		ToastNotification,
		Download
	},
	data() {
		return {
			scene: null,
			views: {
				'Top': { position: [0, 5, 0], color: '#99D93E' },  
				'Side': { position: [5, 0, 0], color: '#EC4C56' }, 
				'Front': { position: [0, 0, 5], color: '#508DF8' } 
			},
			size: '',
			model: null,
			isLoading: false,
			loadingProgress: 0,
			metadata: null,
			compareMode: false,
			bucket: 'https://storage.googleapis.com/restoration-ios.appspot.com/coral-gardeners/',
			// url parameters
			branchId: this.$route.params.branchId || '',
			siteType: this.$route.params.siteType || '',
			siteId: this.$route.params.siteId || '',
			cellId: this.$route.params.cellId || '',
			logIndex: this.$route.params.logIndex || '',
		}
	},
	mounted() {
		this.initScene()
		EventBus.$on('model-selected', this.loadModelFromSelection);
		EventBus.$on('reset-model', this.cleanUp);
		EventBus.$on('save-annotations', this.saveAnnotations);
	},
	computed: {
		isMobile() {
			return this.checkIfMobile();
		}
	},
	watch: {
		'$route'(to) {
			this.branchId = to.params.branchId || '';
			this.siteId = to.params.siteId || '';
			this.cellId = to.params.cellId || '';
			this.logIndex = to.params.logIndex || '';
		}
	},
	methods: {
		async saveAnnotations() {
			// Write all Vuex global data to Firestore
			const siteType = this.$store.state.cell.siteType;
			const siteCollection = siteType === 'survey' ? 'SurveySites' : 'OutplantSites';
			const cellCollection = siteType === 'survey' ? 'SurveyCells' : 'OutplantCells';
			const docRef = doc(db,
				"Orgs", 'coral-gardeners',
				"Branches", this.$store.state.cell.branch,
				siteCollection, this.$store.state.cell.site,
				cellCollection, this.$store.state.cell.cell
			);
			const docSnapshot = await getDoc(docRef);
			if (docSnapshot.exists()) {
				EventBus.$emit('show-toast', { message: 'Saving...', type: '', loading: true });
				const healths = docSnapshot.data().healths
				const index = this.$store.state.log_index;

				// Function to check if any field in state.boxes is null or undefined
				const checkAnnotations = (arr) => {
					return arr.some(item => Object.values(item).some(value => value === null || value === undefined));
				};

				if (checkAnnotations(this.$store.state.boxes)) {
					EventBus.$emit('show-toast', { message: 'You have incomplete annotation data.', type: 'error', loading: false });
					return;
				}

				const updateField = (field, value) => {
					if (value !== undefined && value !== null) {
						healths[index][field] = value;
					}
				};

				updateField('annotations', this.$store.state.boxes);
				updateField('cellMonitoring', this.$store.state.cellMonitoring);
				updateField('modelAnnotated', this.$store.state.logs[this.$store.state.log_index].modelAnnotated);
				updateField('modelTransform', this.$store.state.logs[this.$store.state.log_index].modelTransform);
				updateField('rulers', this.$store.state.logs[this.$store.state.log_index].rulers);
				updateField('cropBox', this.$store.state.cropBox);

				try {
					await updateDoc(docRef, { 'healths': healths });
					this.$store.dispatch('setModified', false);
					this.$store.dispatch('updateAnnotations'); // write store.state.boxes to logs[index].annotations
					EventBus.$emit('show-toast', { message: 'Save successful!', type: 'success', loading: false });
				} catch (error) {
					console.log("Error updating document: ", error);
					EventBus.$emit('show-toast', { message: error, type: 'error', loading: false });
				}
			} else {
				console.log("Document does not exist!");
			}
		},
		checkIfMobile() {
			const userAgent = navigator.userAgent || navigator.vendor || window.opera;
			const isMobileSize = window.innerWidth < 768;
			const isIpad = /iPad|Macintosh/.test(userAgent) && 'ontouchend' in document;
			const isMobileAgent = /iPhone|iPod|Android|BlackBerry|IEMobile|Opera Mini/.test(userAgent);
			return isMobileSize || isIpad || isMobileAgent;
		},
		addListeners() {
			window.addEventListener('resize', this.onWindowResize);
		},
		initScene() {
			this.scene = new THREE.Scene()
			const ambient = new THREE.AmbientLight(0xffffff, 2); 
			this.scene.add(ambient);
			const directional = new THREE.DirectionalLight(0xffffff, 1);
			directional.position.set(10, 10, 10);
			directional.castShadow = true;
			directional.shadow.mapSize.width = 2048;
			directional.shadow.mapSize.height = 2048;
			directional.shadow.camera.near = 0.5;
			directional.shadow.camera.far = 500;
			this.scene.add(directional);
		},
		loadModelFromSelection() {
			this.cleanUp();
			const url = this.bucket + this.$store.state.logs[this.$store.state.log_index].photogrammetryPath;
			if (this.$store.state.modelType === 'ortho') {
				const ortho_url = url + '/model_medium_orthomosaic.tif';
				this.loadOrtho(ortho_url);
			} else if (this.$store.state.modelType === 'mesh') {
				const model_url = url + '/model_medium.obj';
				const texture_url = url + '/model_medium.jpg';
				this.loadModel(model_url, texture_url);
			} else {
				console.error('Model type not recognized');
			}
			// TODO: Remove this when we have actual data
			// this.$store.state.modelType = 'ortho';
			// const ortho_url = '/models/test.tif';
			// this.loadOrtho(ortho_url);

		},
		loadModel(model_url, texture_url) {
			this.isLoading = true;
			this.loadingProgress = 0;

			loadObjModel(model_url, texture_url, (xhr, isTexture) => {
				if (!isTexture) {
					this.loadingProgress = (xhr.loaded / xhr.total * 100).toFixed(0);
				}
			}).then(({ object, metadata }) => {
				object.userData.type = 'model';
				object.userData.format = 'obj';
				object.userData.metadata = metadata;
				object.name = 'model';
				const transform = this.$store.state.logs[this.$store.state.log_index].modelTransform;
				if (transform) {
					object.position.set(transform.translation.x, transform.translation.y, transform.translation.z);
					object.rotation.set(transform.rotation.x, transform.rotation.y, transform.rotation.z);
					object.scale.set(transform.scale.x, transform.scale.y, transform.scale.z);
				}
				this.scene.add(object);
				this.model = object;

				this.isLoading = false;
				this.loadingProgress = 0;
				EventBus.$emit('model-loaded');
			}).catch(error => {
				console.error('An error occurred while loading obj model:', error);
				EventBus.$emit('show-toast', { message: 'Model not found.', type: 'error', loading: false });

				this.isLoading = false;
				this.loadingProgress = 0;
				EventBus.$emit('reset-model');
			});
		},
		loadOrtho(url) {
			this.isLoading = true;
			this.loadingProgress = 0;
			fetch(url)
				.then(response => {
					if (!response.body) throw new Error('ReadableStream not yet supported in this browser.');
					const contentLength = response.headers.get('content-length');
					if (!contentLength) throw new Error('Content-Length response header unavailable.');
					const total = parseInt(contentLength, 10);
					let loaded = 0;
					return new Response(
						new ReadableStream({
							start: (controller) => {
								const reader = response.body.getReader();
								const read = () => {
									reader.read().then(({ done, value }) => {
										if (done) {
											controller.close();
											return;
										}
										loaded += value.byteLength;
										this.loadingProgress = ((loaded / total) * 100).toFixed(0);
										controller.enqueue(value);
										read();
									});
								};
								read();
							}
						})
					).arrayBuffer();
				})
				.then(buffer => {
					try {
						Tiff.initialize({ TOTAL_MEMORY: 262144000 }); // 250MB
						const tiff = new Tiff({ buffer: buffer });
						const canvas = tiff.toCanvas();
						const texture = new THREE.CanvasTexture(canvas);
						texture.colorSpace = THREE.SRGBColorSpace;
						const geometry = new THREE.PlaneGeometry(1, 1);
						const material = new THREE.MeshBasicMaterial({ map: texture });
						const aspect = canvas.width / canvas.height;
						this.model = new THREE.Mesh(geometry, material);
						this.model.scale.set(aspect, 1, 1);
						this.model.name = 'model';
						this.model.userData.type = 'ortho';
						const transform = this.$store.state.logs[this.$store.state.log_index].modelTransform;
						if (transform) {
							this.model.position.set(transform.translation.x, transform.translation.y, transform.translation.z);
							this.model.rotation.set(transform.rotation.x, transform.rotation.y, transform.rotation.z);
							this.model.scale.set(transform.scale.x, transform.scale.y, transform.scale.z);
						}
						this.scene.add(this.model);
						EventBus.$emit('model-loaded');
						this.loadingProgress = 0;
						this.isLoading = false;
					} catch (error) {
						if (error instanceof RangeError) {
							EventBus.$emit('show-toast', { message: 'TIFF file size exceeds memory limit.', type: 'error', loading: false });
						} else {
							throw error; // re-throw if it's not a RangeError
						}
						this.isLoading = false;
						this.loadingProgress = 0;
					}
				})
				.catch(error => {
					console.error('Error loading TIFF file:', error);
					EventBus.$emit('show-toast', { message: 'Model not found.', type: 'error', loading: false });

					this.isLoading = false;
					this.loadingProgress = 0;
				});
		},
		cleanUp() {
			if (this.model) {
				this.scene.remove(this.model);
				if (this.model.geometry) {
					this.model.geometry.dispose();
				}
				if (this.model.material) {
					if (Array.isArray(this.model.material)) {
						this.model.material.forEach(material => {
							if (material.map) material.map.dispose(); // Dispose of any textures
							material.dispose();
						});
					} else {
						if (this.model.material.map) this.model.material.map.dispose();
						this.model.material.dispose();
					}
				}
				this.model = null;
			}
			const cropBox = this.scene.children.find(child => child.userData.type === 'crop');
			if (cropBox) {
				this.scene.remove(cropBox);
			}
			this.$store.dispatch('resetModel');
			if (window.gc) {
				window.gc();
			}
		},
	},
}
</script>

<style scoped>
.main-container {
	height: 100vh;
	overflow: hidden;
}

</style>
