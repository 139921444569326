<template>
    <div class="relative">
        <img src="@/assets/images/cg.png" alt="Logo" class="logo" />
        <svg class="progress" :width="size" :height="size" viewBox="0 0 36 36">
            <path class="circle-bg" d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831" />
            <path class="circle" :stroke-dasharray="progress + ', 100'" d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831" />
        </svg>
    </div>
</template>

<script>
export default {
    props: {
        progress: {
            type: Number,
            required: true
        },
        size: {
            type: Number,
            default: 100
        }
    }
}
</script>

<style scoped>
.relative {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    width: 50%;
    height: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.progress {
    transform: rotate(-90deg);
}

.circle-bg {
    fill: none;
    stroke: #eee;
    stroke-width: 3.8;
}

.circle {
    fill: none;
    stroke: #27BDF4;
    stroke-width: 2.8;
    stroke-linecap: round;
    transition: stroke-dasharray 0.3s ease;
}
</style>
