<template>
</template>

<script>
import * as THREE from 'three';
import { EventBus } from '@/event-bus.js';

export default {
    name: 'Crop',
    props: ['scene'],
    data() {
        return {
            cropBox: null,
            firstLoad: false
        }
    },
    mounted() {
        EventBus.$on('crop', this.crop);
        this.loadCropBox();
    },
    beforeDestroy() {
        EventBus.$emit('crop-toggle');
        EventBus.$off('crop', this.crop);
        this.cleanUp();
    },
    methods: {
        loadCropBox() {
            const cropBoxData = this.$store.state.logs[this.$store.state.log_index].cropBox;
            if (cropBoxData) {
                const size = new THREE.Vector3().fromArray(cropBoxData.size);
                const center = new THREE.Vector3().fromArray(cropBoxData.position);
                this.cropBox = this.createCropBox(size, center);
                this.firstLoad = true;
                this.crop();
            } else {
                this.initCropBox();
            }
            EventBus.$emit('crop-toggle');
        },
        initCropBox() {
            const model = this.scene.getObjectByName('model');
            const boundingBox = new THREE.Box3().setFromObject(model);

            // Get the size of the bounding box
            const size = new THREE.Vector3();
            boundingBox.getSize(size);

            // Get the center of the bounding box
            const center = new THREE.Vector3();
            boundingBox.getCenter(center);

            // Create the crop box
            this.cropBox = this.createCropBox(size, center);

            // Save the crop box to Vuex
            this.$store.dispatch('setCropBox', {
                size: size.toArray(),
                position: center.toArray()
            });
        },
        createCropBox(size, position) {
            // Create a box widget with the size of the bounding box
            const boxGeometry = new THREE.BoxGeometry(size.x, size.y, size.z);
            const boxMaterial = new THREE.MeshBasicMaterial({
                color: 0x27BDF4,
                transparent: true,
                opacity: 0.2 // Adjust opacity as needed
            });
            const cropBox = new THREE.Mesh(boxGeometry, boxMaterial);

            // Set the position of the box widget to the center of the bounding box
            cropBox.position.copy(position);

            // Add the box widget to the scene
            cropBox.name = 'crop';
            cropBox.userData.type = 'crop';
            this.scene.add(cropBox);


            // Bolder edges
            const edges = new THREE.EdgesGeometry(boxGeometry);
            const lineMaterial = new THREE.LineBasicMaterial({
                color: 0x27BDF4,
                linewidth: 5 // Adjust linewidth for bolder edges
            });
            const lineSegments = new THREE.LineSegments(edges, lineMaterial);
            cropBox.add(lineSegments);

            // Less transparent back face
            const zOffset = 0.001;
            const backFaceGeometry = new THREE.PlaneGeometry(size.x, size.y);
            const backFaceMaterial = new THREE.MeshBasicMaterial({
                color: 0x27BDF4,
                side: THREE.DoubleSide,
                transparent: true,
                opacity: 0.4 // More transparent than the box
            });
            const backFace = new THREE.Mesh(backFaceGeometry, backFaceMaterial);
            backFace.position.z = -size.z / 2 - zOffset; // Position it at the center of the -Z face
            backFace.rotateY(Math.PI);
            cropBox.add(backFace);

            return cropBox;
        },
        crop() {
            const box = new THREE.Box3().setFromObject(this.cropBox);
            const size = new THREE.Vector3();
            box.getSize(size);
            const center = new THREE.Vector3();
            box.getCenter(center);

            // Create and position the clipping planes
            const clipPlanes = [
                new THREE.Plane(new THREE.Vector3(-1, 0, 0), box.max.x),
                new THREE.Plane(new THREE.Vector3(1, 0, 0), -box.min.x),
                new THREE.Plane(new THREE.Vector3(0, -1, 0), box.max.y),
                new THREE.Plane(new THREE.Vector3(0, 1, 0), -box.min.y),
                new THREE.Plane(new THREE.Vector3(0, 0, -1), box.max.z),
                new THREE.Plane(new THREE.Vector3(0, 0, 1), -box.min.z)
            ];

            const model = this.scene.getObjectByName('model');  
            model.material.clippingPlanes = clipPlanes;
            model.material.clipIntersection = false;
            model.material.needsUpdate = true;
            this.$store.dispatch('setCropBox', {
                size: size.toArray(),
                position: center.toArray()
            });
            if (!this.firstLoad) {
                this.$store.dispatch('setModified', true);
            }
            this.firstLoad = false;
        },
        cleanUp() {
            this.cropBox.material.dispose();
            this.cropBox.geometry.dispose();
            this.scene.remove(this.cropBox);
            this.cropBox = null;
        }
    },
}
</script>

<style scoped></style>
