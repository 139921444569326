<template>
    <div class="bg-neutral-900 text-white text-sm p-3 m-1 rounded-sm">
        <div class="flex justify-between items-center pb-2 mb-2 border-b border-gray-600">
            <div class="font-bold uppercase">Rulers</div>
        </div>
        <div v-if="this.rulers.length > 0">
            <div v-for="ruler in rulers" :key="ruler.id"
                class="p-2 rounded hover:bg-neutral-800 cursor-pointer">
                <div class="flex justify-between items-center">
                    <div>
                        <span class="text-xs text-gray-400 mr-2">ID</span>
                        <span class="font-semibold">{{ ruler.id }}</span>
                        <span class="ml-2">{{ ruler.distance.toFixed(0) }}cm</span>
                    </div>
                    <div class="flex items-center">
                        <input class="bg-gray-700 text-white ml-2 px-2 py-1 rounded w-24"
                            v-model.number="ruler.knownDistance" placeholder="Length" />
                        <button class="text-lg cursor-pointer hover:bg-neutral-600 px-1 rounded ml-2"
                            @click="deleteRuler(ruler.id)">
                            <span class="mdi mdi-delete text-white text-lg"></span>
                        </button>
                    </div>
                </div>
            </div>
            <!-- Save and Apply Buttons -->
            <div class="flex justify-end space-x-2 mt-4">
                <button @click="scaleModelOverall" :disabled="!canApplyScale" class="mt-3 py-2 px-4 rounded"
                    :class="{ 'bg-primary text-white': canApplyScale, 'bg-neutral-600 text-gray-400': !canApplyScale }">
                    Scale
                </button>
                <button @click="saveRulers" :disabled="!canSave" class="mt-3 py-2 px-4 rounded"
                    :class="{ 'bg-primary text-white': canSave, 'bg-neutral-600 text-gray-400': !canSave }">
                    Save
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import * as THREE from 'three';
import { EventBus } from '@/event-bus.js';
import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { LineSegmentsGeometry } from 'three/examples/jsm/lines/LineSegmentsGeometry.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';
import { LineSegments2 } from 'three/examples/jsm/lines/LineSegments2.js';
import RendererService from '@/assets/js/RendererService';


export default {
    name: 'Measure',
    props: ['scene'],
    data() {
        return {
            camera: null,
            isRulerModeEnabled: false,
            rulersVisible: true,
            raycaster: new THREE.Raycaster(),
            mouse: new THREE.Vector2(),
            renderer: null,
            labelRenderer: null,
            renderContainer: null,
            startPoint: null,
            endPoint: null,
            measurementLabels: {},
            rulers: [],
            animationFrameId: null,
            canSave: false,
        };
    },
    mounted() {
        EventBus.$on('add-ruler', this.createRuler);
        this.initMeasure();
    },
    beforeDestroy() {
        EventBus.$off('add-ruler', this.createRuler);
        // EventBus.$off('clear-rulers', this.clearAllRulers);
        // EventBus.$off('reset-model', this.clearAllRulers);
        window.removeEventListener('resize', this.onWindowResize);
        this.disableRulerMode();
        if (this.animationFrameId) {
            cancelAnimationFrame(this.animationFrameId);
            this.animationFrameId = null;
        }
        this.renderer.dispose();
        this.renderContainer.removeChild(this.labelRenderer.domElement);
        this.labelRenderer = null;
        this.cleanUp();
    },
    computed: {
        canApplyScale() {
            return this.rulers.length > 0 && this.rulers.some(ruler => ruler.knownDistance);
        },
    },
    methods: {
        initMeasure() {
            this.renderer = RendererService.getRenderer();
            this.camera = RendererService.getCamera();
            this.renderContainer = this.renderer.domElement.parentNode;
            this.labelRenderer = new CSS2DRenderer();
            this.labelRenderer.setSize(this.renderContainer.clientWidth, this.renderContainer.clientHeight);
            this.labelRenderer.domElement.style.position = 'absolute';
            this.labelRenderer.domElement.style.top = '0px';
            this.labelRenderer.domElement.style.pointerEvents = 'none';
            this.renderContainer.appendChild(this.labelRenderer.domElement);

            const animate = () => {
                this.animationFrameId = requestAnimationFrame(animate);
                this.labelRenderer.render(this.scene, this.camera);
                this.renderer.render(this.scene, this.camera);
            };
            animate();

            window.addEventListener('resize', this.onWindowResize);

            this.loadRulers();
        },
        loadRulers() {
            const rulers = this.$store.state.logs[this.$store.state.log_index].rulers;
            if (!rulers) return;
            rulers.forEach(ruler => {
                if (ruler.type != this.$store.state.modelType) return;
                const startPoint = new THREE.Vector3().fromArray(ruler.startPoint);
                const endPoint = new THREE.Vector3().fromArray(ruler.endPoint);
                const line = this.drawLine(startPoint, endPoint, ruler.id);
                const startSphere = this.addSphere(startPoint);
                const endSphere = this.addSphere(endPoint);
                this.rulers.push({
                    id: ruler.id,
                    type: ruler.type,
                    startPoint,
                    endPoint,
                    distance: ruler.distance,
                    knownDistance: ruler.knownDistance,
                    line,
                    startSphere,
                    endSphere
                });
            });
        },
        saveRulers() {
            this.$store.dispatch('saveRulers', this.rulers);
            this.canSave = false;
        },
        createRuler() {
            if (!this.renderer || !this.renderContainer) return;
            this.isRulerModeEnabled = true;
            this.renderContainer.addEventListener('click', this.onClick);
            this.renderer.domElement.style.cursor = 'crosshair';
        },
        disableRulerMode() {
            if (!this.renderer || !this.renderContainer) return;
            this.isRulerModeEnabled = false;
            this.renderContainer.removeEventListener('click', this.onClick);
            this.renderer.domElement.style.cursor = 'default';
        },
        onClick(event) {
            if (!this.isRulerModeEnabled) return;
            if (event.button !== 0) return;

            const rect = this.renderContainer.getBoundingClientRect();
            this.mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
            this.mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
            const camera = this.scene.getObjectByName('main');
            const model = this.scene.children.filter(child => child.userData.type === 'model' || child.userData.type === 'ortho');
            this.raycaster.setFromCamera(this.mouse, camera);
            const intersects = this.raycaster.intersectObjects(model, false);

            if (intersects.length > 0) {
                const point = intersects[0].point;

                if (!this.startPoint) {
                    this.startPoint = point;
                    this.currentStartSphere = this.addSphere(point); // Track the current start sphere
                } else {
                    // Generate unique ID
                    let maxId = Math.max(...this.rulers.map(ruler => ruler.id), 0);
                    const newId = maxId + 1;

                    this.endPoint = point;
                    const startSphere = this.currentStartSphere;
                    const endSphere = this.addSphere(this.endPoint);
                    const line = this.drawLine(this.startPoint, this.endPoint, newId);

                    // Save the ruler object
                    const distance = this.startPoint.distanceTo(this.endPoint) * 100;
                    this.rulers.push({
                        id: newId,
                        type: this.$store.state.modelType,
                        startPoint: this.startPoint.clone(),
                        endPoint: this.endPoint.clone(),
                        distance,
                        knownDistance: null,
                        line,
                        startSphere,
                        endSphere
                    });
                    this.startPoint = null;
                    this.endPoint = null;
                    this.lineId++;
                    this.disableRulerMode();
                    this.currentStartSphere = null; 
                    this.canSave = true;
                }
            }
        },
        addSphere(point) {
            const sphereGeometry = new THREE.SphereGeometry(0.01, 32, 32);
            const sphereMaterial = new THREE.MeshBasicMaterial({ color: '#27bdf4' });
            const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
            sphere.position.copy(point);
            this.scene.add(sphere);
            return sphere;
        },
        drawLine(start, end, id) {
            const positions = new Float32Array([
                start.x, start.y, start.z,
                end.x, end.y, end.z
            ]);

            const geometry = new LineSegmentsGeometry();
            geometry.setPositions(positions);

            const material = new LineMaterial({
                color: 0x27bdf4,
                linewidth: 5, // Line thickness in pixels
                resolution: new THREE.Vector2(window.innerWidth, window.innerHeight) // To handle thick lines in perspective
            });

            const line = new LineSegments2(geometry, material);
            line.computeLineDistances();
            this.scene.add(line);

            const distance = start.distanceTo(end) * 100 // convert to cm;
            this.addLabel(start, end, distance, id);
            return line;
        },
        addLabel(start, end, distance, id) {
            const div = document.createElement('div');
            div.className = 'measurementLabel';
            div.innerText = distance.toFixed(0) + 'cm';
            const label = new CSS2DObject(div);

            const midPoint = new THREE.Vector3();
            midPoint.lerpVectors(start, end, 0.5);
            label.position.copy(midPoint);

            this.scene.add(label);
            this.measurementLabels[id] = label;
        },
        cleanUp() {
            this.rulers.forEach(ruler => {
                this.scene.remove(ruler.line);
                this.scene.remove(ruler.startSphere);
                this.scene.remove(ruler.endSphere);
                this.scene.remove(this.measurementLabels[ruler.id]);
            });
            this.rulers = [];
            this.measurementLabels = {};
            this.startPoint = null;
            this.endPoint = null;
            this.currentStartSphere = null;
            this.lineId = 0;
        },
        deleteRuler(id) {
            const rulerIndex = this.rulers.findIndex(ruler => ruler.id === id);
            if (rulerIndex !== -1) {
                const ruler = this.rulers[rulerIndex];
                this.scene.remove(ruler.line);
                this.scene.remove(ruler.startSphere);
                this.scene.remove(ruler.endSphere);
                this.scene.remove(this.measurementLabels[ruler.id]);
                this.rulers.splice(rulerIndex, 1)
            }
            this.canSave = true;
        },
        async scaleModelOverall() {
            EventBus.$emit('show-toast', { message: 'Applying scale...', type: '', loading: true });
            await new Promise(resolve => setTimeout(resolve, 100)); // wait for the toast to show
            const scaleFactors = this.rulers
                .filter(ruler => ruler.knownDistance)
                .map(ruler => ruler.knownDistance / ruler.distance);

            const averageScaleFactor = scaleFactors.reduce((a, b) => a + b, 0) / scaleFactors.length;
            const model = this.scene.getObjectByName('model');

            if (model) {
                model.scale.multiplyScalar(averageScaleFactor);
                this.resetModelCenter(model);
                const translation = {
                    x: model.position.x,
                    y: model.position.y,
                    z: model.position.z
                };
                const rotation = {
                    x: model.rotation.x,
                    y: model.rotation.y,
                    z: model.rotation.z
                };
                const scale = {
                    x: model.scale.x,
                    y: model.scale.y,
                    z: model.scale.z
                };
                this.$store.dispatch('setModelTransform', { translation, rotation, scale });
                this.cleanUp();
                // TODO: delete rulers from logs

                EventBus.$emit('show-toast', { message: 'Scale applied successfully!', type: 'success', loading: false });
            } else {
                EventBus.$emit('show-toast', { message: 'Error applying scale.', type: 'error', loading: false });
            }
        },
        resetModelCenter(model) {
            model.traverse((child) => {
                if (child.isMesh) {
                    const geometry = child.geometry;
                    geometry.computeBoundingBox();
                    geometry.center();
                    model.position.set(0, 0, 0);
                    EventBus.$emit('reset-viewport')
                }
            });
        },
        onWindowResize() {
            if (this.renderer && this.labelRenderer) {
                const containerWidth = this.renderContainer.clientWidth;
                const containerHeight = this.renderContainer.clientHeight;
                this.renderer.setSize(containerWidth, containerHeight);
                this.labelRenderer.setSize(containerWidth, containerHeight);
            }
        }
    },
};
</script>

<style>
.measurementLabel {
    padding: 4px 8px;
    border-radius: 5px;
    background-color: black;
    color: white;
    font-size: 12px;
    font-family: Arial, sans-serif;
    text-align: center;
    white-space: nowrap;
}
</style>
