<template>
    <div v-if="cell" class="bg-neutral-900 text-white text-sm p-3 m-1 rounded-sm">
        <div class="flex justify-between items-center pb-2 mb-4 pt-1 border-b border-gray-600">
            <div class="font-bold uppercase">Cell Information</div>
        </div>
        <div>
            <div class="flex justify-between">
                <span>Latitude:</span><span>{{ log.geoLocation.latitude }}</span>
            </div>
            <div class="flex justify-between">
                <span>Longitude:</span><span>{{ log.geoLocation.longitude }}</span>
            </div>
            <div class="flex justify-between">
                <span>Created At:</span><span>{{ formatDate(log.timestamp) }}</span>
            </div>
            <div class="flex justify-between">
                <span>Site Type:</span><span>{{ cell.siteType }}</span>
            </div>
        </div>
        <div id="map" ref="mapContainer" class="map-container"></div>
        <div v-if="creator">
            <div class="flex justify-between">
                <span>Created by:</span><span>{{ creator.firstName }} {{  creator.lastName }}</span>
            </div>
            <div class="flex justify-between">
                <span>Email:</span><span>{{ creator.email }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import mapboxgl from 'mapbox-gl';
import "mapbox-gl/dist/mapbox-gl.css";
import { db } from '@/main.js';
import { doc, getDoc } from "firebase/firestore";
import { EventBus } from '@/event-bus';

export default {
    name: 'Info',
    props: ['activeTab'],
    data() {
        return {
            map: null,
            creator: null,
        }
    },
    mounted() {
        this.initializeMap();
        this.getCellCreator();
        EventBus.$on('reset-model', this.cleanUp)
    },
    beforeDestroy() {
        EventBus.$off('reset-model', this.cleanUp)
    },
    computed: {
        cell() {
            return this.$store.state.cell;
        },
        log() {
            return this.$store.state.logs[this.$store.state.log_index];
        }
    },
    methods: {
        formatDate(timestamp) {
            if (!timestamp) return '';
            const date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
            return date.toLocaleString();
        },
        initializeMap() {
            setTimeout(() => {
                mapboxgl.accessToken = 'pk.eyJ1IjoiZHJld2pncmF5M2QiLCJhIjoiY2w5YXBvMnlkMHphOTNubnQ0Zm56dDhpMSJ9.JUfaBM9_w3f9fU4qyZZ74A';
                this.map = new mapboxgl.Map({
                    container: 'map',
                    style: 'mapbox://styles/drewjgray3d/cl9kdwr6y000715muk49hq3z8',
                    center: [this.log.geoLocation.longitude, this.log.geoLocation.latitude],
                    zoom: 9,
                    attributionControl: false
                });
                new mapboxgl.Marker()
                    .setLngLat([this.log.geoLocation.longitude, this.log.geoLocation.latitude])
                    .addTo(this.map);

            }, 300);
        },
        async getCellCreator() {
            const userID = this.$store.state.logs[this.$store.state.log_index].userID;
            const usersRef = doc(db, "Users", userID);
            const docSnapshot = await getDoc(usersRef);
            if (docSnapshot.exists()) {
                this.creator = docSnapshot.data();
            }
        },
        cleanUp() {
            if (this.map) {
                this.map.remove();
                this.map = null;
            }
            this.creator = null;
        }
    },
};
</script>

<style>
#map {
    width: 100%;
    height: 240px;
}
</style>
