<template>
    <div class="text-white max-h-screen overflow-y-auto container">
        <div class="text-white overflow-y-auto p-4">
            <div v-for="(fragment, index) in fragments" :key="fragment.id" class="border-b border-gray-300 py-4">
                <h3 class="text-sm font-bold">Fragment: {{ index }}</h3>
                <!-- <p class="text-sm mt-1"><strong>ID:</strong> {{ fragment.id }}</p> -->
                <p class="text-sm mt-1"><strong>Genus:</strong> {{ fragment.coral.genus }}</p>
                <p class="text-sm mt-1"><strong>Species:</strong> {{ fragment.coral.species }}</p>
                <p class="text-sm mt-1"><strong>Size:</strong> {{ fragment.outplantInfo.size.length }} x {{
                    fragment.outplantInfo.size.width }} x {{ fragment.outplantInfo.size.height }} cm</p>
                <div v-if="fragment.healths.length > 0" class="mt-4">
                    <h3 class="text-sm font-semibold">Health Timeline:</h3>
                    <div style="height: 60px; width: 100%; position: relative;">
                        <sparkline-chart style="height: 100%; width: 100%; position: absolute;"
                            :chart-data="prepareChartDataHealth(fragment) "></sparkline-chart>
                    </div>
                </div>
                <div v-if="fragment.healths.length > 0" class="mt-4">
                    <h3 class="text-sm font-semibold">Bleach Timeline:</h3>
                    <div style="height: 60px; width: 100%; position: relative;">
                        <sparkline-chart style="height: 100%; width: 100%; position: absolute;"
                            :chart-data="prepareChartDataBleach(fragment) "></sparkline-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SparklineChart from '@/components/utils/SparklineChart.vue';

export default {
    name: 'Fragments',
    components: {
        SparklineChart
    },
    props: ['fragments'],
    methods: {
        formatDate(timestamp) {
            const date = new Date(timestamp.seconds * 1000);
            return date.toLocaleDateString('en-US');
        },
        prepareChartDataHealth(fragment) {
            return {
                labels: fragment.healths.map(entry => this.formatDate(entry.created)),
                datasets: [{
                    label: 'Health',
                    borderColor: '#27BDF4',
                    data: fragment.healths.map(h => 0.4 + h.health),
                    lineTension: 0,
                    borderJoinStyle: 'miter',
                    pointRadius: 2,
                    pointBackgroundColor: '#27BDF4',
                }]
            }
        },
        prepareChartDataBleach(fragment) {
            return {
                labels: fragment.healths.map(entry => this.formatDate(entry.created)),
                datasets: [{
                    label: 'Bleach',
                    borderColor: 'white',
                    data: fragment.healths.map(h => 0.4 + h.bleach),
                    lineTension: 0,
                    borderJoinStyle: 'miter',
                    pointRadius: 2,
                    pointBackgroundColor: 'white',
                }]
            }
        },
    },
};
</script>

<style scoped>
.container::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}

.container {
    -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;
    /* for Firefox */
}
</style>