<template>
    <div ref="renderContainer" id="ortho" class="relative h-full">
        <div style="border-top: 2px solid #27bdf4"
            class="absolute top-2 right-2 bg-neutral-700 text-white text-xs px-3 py-1 rounded">
            Orthomosaic
        </div>
    </div>
</template>

<script>
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import { EventBus } from '@/event-bus.js';
import RendererService from '@/assets/js/RendererService';

export default {
    name: 'ViewportOrtho',
    props: ['scene'],
    data() {
        return {
            renderer: null,
            camera: null,
            controls: null,
            cropControls: null,
            animationFrameId: null,
        };
    },
    mounted() {
        EventBus.$on('model-loaded', this.init);
        EventBus.$on('center-on-quadrat', this.centerOnQuadrat);
        window.addEventListener('resize', this.onWindowResize);
    },
    beforeDestroy() {
        EventBus.$off('model-loaded', this.init);
        window.removeEventListener('resize', this.onWindowResize);
        if (this.animationFrameId) {
            cancelAnimationFrame(this.animationFrameId);
            this.animationFrameId = null;
        }
        this.renderer.dispose();
        this.controls.dispose();
        this.scene.remove(this.camera);
        this.cleanUp();
    },
    computed: {
        cropMode() {
            return this.$store.state.mode === 'crop';
        },
    },
    watch: {
        cropMode() {
            this.$nextTick(() => {
                if (this.cropMode) {
                    const cropBox = this.scene.getObjectByName('crop');
                    this.cropControls.attach(cropBox);
                    this.cropControls.visible = true;
                    this.$store.state.transformActive = true;
                } else {
                    this.cropControls.detach();
                    this.cropControls.visible = false;
                    this.$store.state.transformActive = false;
                }
            });
        },
    },
    methods: {
        init() {
            this.initRenderer();
            this.initScene();
        },
        initRenderer() {
            const aspect = this.$refs.renderContainer.clientWidth / this.$refs.renderContainer.clientHeight;
            this.camera = new THREE.OrthographicCamera(-aspect, aspect, 1, -1, 0.1, 1000);
            this.camera.name = 'main';
            this.renderer = new THREE.WebGLRenderer({ antialias: true });
            this.renderer.setSize(this.$refs.renderContainer.clientWidth, this.$refs.renderContainer.clientHeight);
            this.renderer.setPixelRatio(window.devicePixelRatio);
            this.renderer.autoClear = false;
            this.renderer.shadowMap.enabled = true;
            this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
            this.$refs.renderContainer.appendChild(this.renderer.domElement);
            RendererService.setRenderer(this.renderer);
            RendererService.setCamera(this.camera);
        },
        initScene() {
            this.camera.position.set(0, 0, 5);
            this.camera.lookAt(0, 0, 0);
            this.scene.add(this.camera);
            this.controls = new OrbitControls(this.camera, this.renderer.domElement);
            this.controls.zoomSpeed = 7;
            this.controls.enableRotate = false;
            this.controls.enablePan = true;

            // add cropControls to the scene
            this.cropControls = new TransformControls(this.camera, this.renderer.domElement);
            this.cropControls.setSpace('local');
            this.cropControls.setSize(1);
            this.cropControls.addEventListener('dragging-changed', this.onDraggingChanged);
            this.scene.add(this.cropControls);
            this.cropControls.visible = false;
            this.cropControls.addEventListener('objectChange', this.onCropObjectChange);

            const animate = () => {
                this.animationFrameId = requestAnimationFrame(animate);
                this.renderer.render(this.scene, this.camera);
            };
            animate();
        },
        centerOnQuadrat(quadrat) {
            const boundingBox = new THREE.Box3().setFromObject(quadrat.outline);
            const size = boundingBox.getSize(new THREE.Vector3());
            const center = boundingBox.getCenter(new THREE.Vector3());

            // Calculate the aspect ratio
            const aspect = this.$refs.renderContainer.clientWidth / this.$refs.renderContainer.clientHeight;

            // Adjust the camera's frustum to fit the quadrat
            const maxDim = Math.max(size.x, size.y);
            const frustumHeight = maxDim * 1.5; // Add some padding
            const frustumWidth = frustumHeight * aspect;

            this.camera.left = -frustumWidth / 2;
            this.camera.right = frustumWidth / 2;
            this.camera.top = frustumHeight / 2;
            this.camera.bottom = -frustumHeight / 2;
            this.camera.zoom = 1 / maxDim;


            // Center the camera on the quadrat
            this.camera.position.set(center.x, center.y, 10); // The z-position can be adjusted as needed
            this.camera.lookAt(center.x, center.y, 0);
            this.camera.updateProjectionMatrix();

            this.controls.target.copy(center);
            this.controls.update();
        },
        onDraggingChanged(event) {
            this.controls.enabled = !event.value;
        },
        onCropObjectChange() {
            EventBus.$emit('crop');
        },
        onWindowResize() {
            const aspect = this.$refs.renderContainer.clientWidth / this.$refs.renderContainer.clientHeight;
            this.camera.left = -aspect/2;
            this.camera.right = aspect/2;
            this.camera.top = 1/2;
            this.camera.bottom = -1/2;
            this.camera.updateProjectionMatrix();
            this.renderer.setSize(this.$refs.renderContainer.clientWidth, this.$refs.renderContainer.clientHeight);
        },
        cleanUp() {
            this.renderer = null;
            this.camera = null;
            this.controls = null;
        },
    },
};
</script>

<style scoped>
#ortho {
    width: 100%;
    height: 100vh;
}
</style>
```