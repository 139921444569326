<template>
    <div ref="renderContainer" :id="viewName" class="relative h-full">
        <div v-if="log && log.annotations && $store.state.modelType === 'mesh'"
            class="absolute top-2 left-2 bg-neutral-700 text-white text-xs px-3 py-1 rounded">
            <button @click="toggleAnnotations"
                class="flex-1 flex justify-center items-center rounded-r bg-neutral-700 transition-colors ml-1">
                <span v-if="!annotationsLoaded" class="mdi mdi-label text-lg"></span>
                <span v-else class="mdi mdi-label-off text-lg"></span>
            </button>
        </div>
        <div v-if="viewName === 'Perspective'"
            class="absolute top-2 left-2 bg-neutral-700 text-white text-xs px-1 rounded">
            <div v-if="model" class="button-group relative flex justify-center items-center m-1">
                <!-- Mesh Button -->
                <button @click="model.material.wireframe = false"
                    class="flex-1 flex justify-center items-center rounded-l bg-neutral-700 transition-colors mr-1">
                    <span
                        :class="{ 'mdi mdi-cube': true, 'text-white': !model.material.wireframe, 'text-gray-500': model.material.wireframe }"></span>
                </button>
                <!-- Wireframe Button -->
                <button @click="model.material.wireframe = true"
                    class="flex-1 flex justify-center items-center rounded-r bg-neutral-700 transition-colors ml-1">
                    <span
                        :class="{ 'mdi mdi-artboard': true, 'text-white': model.material.wireframe, 'text-gray-500': !model.material.wireframe }"></span>
                </button>
            </div>
        </div>
        <div
            class="absolute bottom-20 left-1/2 transform -translate-x-1/2 bg-black bg-opacity-50 text-white text-xs px-3 py-1 rounded backdrop-blur-sm flex items-center space-x-1">
            <span class="mdi mdi-eye text-lg"></span>
            <span>View-only</span>
        </div>
    </div>
</template>

<script>
import * as THREE from 'three'
import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { EventBus } from '@/event-bus.js';

export default {
    name: 'ViewportMobile',
    props: ['scene', 'viewName'],
    data() {
        return {
            model: null,
            renderer: null,
            labelRenderer: null,
            camera: null,
            controls: null,
            resizeObserver: null,
            resizeTimout: null,
            boxes: [],
            annotationLabels: {},
            annotationsLoaded: false,
        }
    },
    mounted() {
        EventBus.$on('model-loaded', this.initScene);
        EventBus.$on('reset-model', this.resetModel);
        this.initRenderer();
    },
    beforeDestroy() {
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    },
    computed: {
        log() {
            if (this.$store.state.logs.length !== 0) {
                return this.$store.state.logs[this.$store.state.log_index]
            } else {
                return null
            }
        },
    },
    // watch: {
    //     scene() {
    //         this.init();
    //     }
    // },
    methods: {
        initRenderer() {
            this.renderer = new THREE.WebGLRenderer({ antialias: true })
            this.renderer.setSize(this.$refs.renderContainer.clientWidth, this.$refs.renderContainer.clientHeight)
            this.renderer.setPixelRatio(window.devicePixelRatio);
            this.renderer.autoClear = false;
            this.renderer.localClippingEnabled = true;
            this.$refs.renderContainer.appendChild(this.renderer.domElement)
            this.renderer.domElement.addEventListener('click', this.onClick, false);

            // Initialize the label renderer
            this.labelRenderer = new CSS2DRenderer();
            this.labelRenderer.setSize(this.$refs.renderContainer.clientWidth, this.$refs.renderContainer.clientHeight);
            this.labelRenderer.domElement.style.position = 'absolute';
            this.labelRenderer.domElement.style.top = '0px';
            this.labelRenderer.domElement.style.pointerEvents = 'none';
            this.$refs.renderContainer.appendChild(this.labelRenderer.domElement);
            
        },
        render() {
            this.renderer.render(this.scene, this.camera);
            this.labelRenderer.render(this.scene, this.camera);
        },
        initScene() {
            if (this.$store.state.modelType === 'mesh') {
                this.camera = new THREE.PerspectiveCamera(50, this.$refs.renderContainer.clientWidth / this.$refs.renderContainer.clientHeight, 0.1, 1000)
                const radius = 5; 
                const angle = Math.PI / 4
                this.camera.position.x = radius * Math.cos(angle); 
                this.camera.position.y = radius * Math.sin(angle);
                this.camera.position.z = radius * Math.cos(angle);
                this.camera.lookAt(this.scene.position);
            } else {
                console.log('Orthographic Camera')
                const aspect = this.$refs.renderContainer.clientWidth / this.$refs.renderContainer.clientHeight
                this.camera = new THREE.OrthographicCamera(-aspect, aspect, 1, -1, 0.1, 1000)
                this.camera.position.set(0, 0, 5);
                this.camera.lookAt(0, 0, 0);
            }
            this.camera.name = 'main'
            this.scene.add(this.camera)
            this.controls = new OrbitControls(this.camera, this.renderer.domElement)
            this.controls.enablePan = true;
            this.$store.state.modelType === 'mesh' ? this.controls.enableRotate = true : this.controls.enableRotate = false

            const animate = () => {
                requestAnimationFrame(animate)
                this.renderer.clear();
                this.renderer.render(this.scene, this.camera)
                this.labelRenderer.render(this.scene, this.camera);
            }
            animate()

            this.setUpResizeObserver();
        },
        toggleAnnotations() {
            if (this.annotationsLoaded) {
                this.boxes.forEach(box => {
                    const label = box.children.find(child => child instanceof CSS2DObject);
                    if (label) {
                        box.remove(label);
                    }
                    this.scene.remove(box);
                });
                this.boxes = [];
                this.annotationLabels = {};
                this.annotationsLoaded = false;
            } else {
                this.loadAnnotations();
                this.annotationsLoaded = true;
            }
        },
        loadAnnotations() {
            const annotations = this.log.annotations;
            if (annotations) {
                annotations.forEach(annotation => {
                    this.addBox(annotation, false);
                });
            }
        },
        addBox(annotation) {
            const box = this.createBoxMesh(annotation);
            this.boxes.push(box);
            this.scene.add(box);
            this.addLabel(box, annotation.id);
        },
        createBoxMesh(annotation) {
            const boxGeometry = new THREE.BoxGeometry(
                annotation.size.width / 100,
                annotation.size.height / 100,
                annotation.size.length / 100
            );
            const boxMaterial = new THREE.MeshBasicMaterial({
                color: 0x27BDF4,
                transparent: true,
                opacity: 0.3,
            });
            const box = new THREE.Mesh(boxGeometry, boxMaterial);
            box.position.set(annotation.position.x, annotation.position.y, annotation.position.z);

            // Add edges to the box for visibility
            const edges = new THREE.EdgesGeometry(boxGeometry);
            const lineMaterial = new THREE.LineBasicMaterial({
                color: 0x27BDF4,
                linewidth: 7,
            });
            const lineSegments = new THREE.LineSegments(edges, lineMaterial);
            box.add(lineSegments);

            // Less transparent back face
            const zOffset = 0.001;
            const backFaceGeometry = new THREE.PlaneGeometry(annotation.size.width / 100, annotation.size.height / 100);
            const backFaceMaterial = new THREE.MeshBasicMaterial({
                color: 0x27BDF4,
                side: THREE.DoubleSide,
                transparent: true,
                opacity: 0.5 // More transparent than the box
            });
            const backFace = new THREE.Mesh(backFaceGeometry, backFaceMaterial);
            backFace.position.z = -annotation.size.length / 100 / 2 - zOffset;; // Position it at the center of the -Z face
            backFace.rotateY(Math.PI);
            box.add(backFace);

            return box;
        },
        addLabel(box, id) {
            const div = document.createElement('div');
            div.className = 'annotationLabel';
            div.innerText = `${id}`;
            const label = new CSS2DObject(div);

            // Position the label at the center of the box
            const center = new THREE.Vector3();
            box.geometry.computeBoundingBox();
            box.geometry.boundingBox.getCenter(center);
            label.position.copy(center);

            box.add(label);
            this.annotationLabels[id] = label;
        },
        onWindowResize() {
            if (this.resizeTimeout) clearTimeout(this.resizeTimeout);
            this.resizeTimeout = setTimeout(() => {
                const container = this.$refs.renderContainer;
                const width = container.clientWidth;
                const height = container.clientHeight;
                this.camera.aspect = width / height;
                this.camera.updateProjectionMatrix();
                this.renderer.setSize(width, height);
                this.labelRenderer.setSize(width, height);
            }, 10);
        },
        setUpResizeObserver() {
            if ('ResizeObserver' in window) {
                this.resizeObserver = new ResizeObserver(entries => {
                    for (let entry of entries) {
                        this.onWindowResize();
                    }
                });
                this.resizeObserver.observe(this.$refs.renderContainer);
            }
        },
        resetModel() {
            const objects = []
            this.scene.children.forEach(child => {
                if (child.userData && (child.userData.type === 'annotation' || child.userData.type === 'hull' || child.userData.type === 'coral')) {
                    objects.push(child);
                }
            });
            objects.forEach(object => this.scene.remove(object));

            this.boxes.forEach(box => {
                const label = box.children.find(child => child instanceof CSS2DObject);
                if (label) {
                    box.remove(label);
                }
                this.scene.remove(box);
            });
            this.boxes = [];
            this.annotationLabels = {};
            this.annotationsLoaded = false;
        },
    }
}
</script>